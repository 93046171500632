import "../General.css";
import "./Products.css";
import { useState } from "react";
import ProductsList from "./componentsProducts/ProductsList/ProductsList";
import ProductsSearchBar from "./componentsProducts/ProductsSearchBar/ProductsSearchBar";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

const ProductsMainContainer = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const { t } = useTranslation("common");

  return (
    <div>
      <div className="header-container">
        <div className="choose-category-button">
          <Button
            label={t("common:agribusiness")}
            className="p-button-raised p-button-text btn-margin-right"
            onClick={() => setSearchValue("Agroalimentare")}
          />
          <Button
            label={t("common:agricolo")}
            className="p-button-raised p-button-text btn-margin-right"
            onClick={() => setSearchValue("Agricolo")}
          />
          <Button
            label={t("common:handCrafted")}
            className="p-button-raised p-button-text btn-margin-right"
            onClick={() => setSearchValue("Artigianale")}
          />
        </div>
        <div className="search-bar-position">
          <ProductsSearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
      </div>
      <div className="display-flex flex-direction-row justify-content-center product-list-container">
        <ProductsList searchValue={searchValue} />
      </div>
    </div>
  );
};

export default ProductsMainContainer;
