import { Checkbox } from "primereact/checkbox";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { product } from "../../../../models/Product/product";

const ProductsButton = ({
  availableProduct,
  choosedProducts,
  setChoosedProducts,
}: {
  availableProduct: product;
  choosedProducts: product[];
  setChoosedProducts: Dispatch<SetStateAction<product[]>>;
}) => {
  const [checked, setChecked] = useState<boolean>();
  useEffect(() => {
    const response = choosedProducts.filter(
      (choosedProduct) => choosedProduct.id === availableProduct.id
    );
    response.length > 0 ? setChecked(true) : setChecked(false);
  }, []);

  const onChecked = (value: boolean) => {
    setChecked(value);
    if (value) {
      choosedProducts.push(availableProduct);
    } else {
      setChoosedProducts(
        choosedProducts.filter(
          (choosedProduct: product) => choosedProduct.id !== availableProduct.id
        )
      );
    }
  };

  return (
    <div
      className="flex flex-row justify-content-space-between w-full overflow-y-auto p-3 "
      style={{
        boxShadow: "0 0 1px #000",
        borderRadius: "10px",
        margin: "0.3rem",
      }}
    >
      <span>{availableProduct.name}</span>
      <Checkbox checked={checked} onChange={() => onChecked(!checked)} />
    </div>
  );
};

export default ProductsButton;
