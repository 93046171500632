import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { primaryColor } from "../../../../environment";
import { ProductListType } from "../../ProductsList/ProductsList";
import ProductCardImagesNavigator from "../ProductCardCarousel/ProductCardCarousel";

interface DialogInfoProps {
  showInfoDialog: boolean;
  setShowInfoDialog: Dispatch<SetStateAction<boolean>>;
  product: ProductListType;
}

const DialogInfo = (props: DialogInfoProps) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Dialog
        visible={props.showInfoDialog}
        footer={
          <div className="w-full flex flex-row justify-content-space-evenly">
            {props.product.link ? (
              <a
                style={{ textDecoration: "none" }}
                className="yellow-button"
                href={props.product.link}
                target="blank"
              >
                {t("common:discover")}
              </a>
            ) : (
              <></>
            )}
            <Button
              onClick={() => props.setShowInfoDialog(false)}
              className="general-button"
            >
              {t("common:close")}
            </Button>
          </div>
        }
        onHide={() => props.setShowInfoDialog(false)}
        closable={false}
        className="dialog-info-product"
      >
        <div style={{ width: "100%" }}>
          <ProductCardImagesNavigator images={props.product.images} />
        </div>
        <p
          style={{
            color: primaryColor,
            textAlign: "center",
          }}
          className="medium-font"
        >
          {props.product.productType}
        </p>
        <p
          style={{
            textAlign: "center",
          }}
          className="small-font"
        >
          {props.product.productCategory}
        </p>
        <p>{props.product.productDescription}</p>
      </Dialog>
    </>
  );
};

export default DialogInfo;
