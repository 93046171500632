import { InputText } from "primereact/inputtext";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface iProps {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}

const ProductsSearchBar = ({ searchValue, setSearchValue }: iProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <span className="p-input-icon-right">
      <i className="pi pi-search" />
      <InputText
        style={{ borderRadius: "50px" }}
        value={searchValue}
        placeholder={t("common:searchProduct")}
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </span>
  );
};

export default ProductsSearchBar;
