import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { ProgressSpinner } from "primereact/progressspinner";

export const LoadingImage = ({ visible }: { visible: boolean }) => {
  const { t } = useTranslation(["imagesSentence"]);

  return (
    <Dialog
      header={t("imagesSentence:genericUploadFoto")}
      closable={false}
      onHide={() => null}
      visible={visible}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ProgressSpinner />
      </div>
    </Dialog>
  );
};
