import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  getUserCognito,
  loginCognito,
} from "../../services/Cognito/CognitoServices";
import { searchUserServiceEmail } from "../../services/User/UsersServices";
import { primaryColor, thirdColor } from "../environment";
import LoadingComponent from "../LoadingPage/LoadingComponent";
import "../General.css";
import "./SignIn.css";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { showError } from "../Toast/Toast.functions";

interface InitialLoginValues {
  [index: string]: string;
  email: string;
  password: string;
}

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["signin", "common", "error"]);
  const toast = useRef<Toast>(null);

  let errors;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    } as InitialLoginValues,

    validate: (data: InitialLoginValues) => {
      errors = {} as Record<string, string>;

      if (!data.email) {
        errors.email = t("error:emailRequired");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = t("error:emailInvalid");
      }
      if (!data.password) {
        errors.password = t("error:passwordRequired");
      }

      return errors;
    },
    onSubmit: (data: InitialLoginValues) => {
      setLoading(!loading);
      const signInObject = {
        email: data.email.toLowerCase(),
        password: data.password,
      };

      loginCognito(signInObject)
        .then(() => {
          getUserCognito(signInObject).then((getUserResult: any) => {
            if (getUserResult.status === 201) {
              searchUserServiceEmail({ email: signInObject.email }).then(
                (searchResult) => {
                  if (searchResult.data === "") {
                    showError(toast, t("signin:genericError"));
                    sessionStorage.clear();
                  } else {
                    /** salva in sessionStorage l'id dell'utente, il nome
                     * se di tipo 2 o 3 salva anche l'id della compagnia */
                    window.sessionStorage.setItem(
                      "userId",
                      searchResult.data._id
                    );
                    window.sessionStorage.setItem(
                      "user",
                      searchResult.data.name
                    );
                    if (
                      searchResult.data.userType === 1 ||
                      searchResult.data.userType === 2
                    )
                      window.sessionStorage.setItem(
                        "company",
                        searchResult.data.companies[0]._id
                      );

                    if (searchResult.data.userType === 3)
                      window.sessionStorage.setItem("superUser", "true");
                    /**reindirizzamento alla homepage */
                    window.open("/", "_self");
                  }
                }
              );
            } else {
              sessionStorage.clear();
              showError(toast, t("signin:genericError"));
            }
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code === "NotAuthorizedException")
              showError(toast, t("signin:NotAuthorized"));
            else showError(toast, t("signin:genericError"));
          } else showError(toast, t("signin:genericError"));
          setLoading(false);
        });
      formik.resetForm();
    },
  });

  const isFormFieldValid = (name: string) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: string) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <div
      className="display-flex flex-direction-column justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: primaryColor }}
    >
      {loading ? (
        <LoadingComponent text={t("signin:loadingUser")} />
      ) : (
        <div className="display-flex flex-direction-column justify-content-center align-items-center signin-modal form-card">
          <div className="big-font margin-bottom">BIZalp</div>
          <div className="medium-font margin-bottom">{t("signin:signIn")}</div>
          <div className="small-font" style={{ marginBottom: "2rem" }}>
            {t("signin:InsertEmailPassword")}
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="p-fluid"
            style={{ width: "100%" }}
          >
            {/* Field Email */}
            <div className="field">
              <span className="p-float-label p-input-icon-right">
                <InputText
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("email"),
                    "p-inputtext-left input-text-signin": true,
                  })}
                />
                <label
                  htmlFor="email"
                  className={classNames({
                    "p-error": isFormFieldValid("email"),
                  })}
                >
                  Email*
                </label>
              </span>
              {getFormErrorMessage("email")}
            </div>

            {/* Field Password */}
            <div className="field">
              <span className="p-float-label">
                <Password
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  feedback={false}
                  toggleMask
                  inputClassName={classNames({
                    "p-invalid": isFormFieldValid("password"),
                    "p-inputtext-left input-text-signin": true,
                  })}
                />
                <label
                  htmlFor="password"
                  className={classNames({
                    "p-error": isFormFieldValid("password"),
                  })}
                >
                  Password*
                </label>
              </span>
              {getFormErrorMessage("password")}
            </div>

            <div
              className="flex justify-content-center"
              style={{ width: "100%" }}
            >
              <Button
                type="submit"
                className="mt-2 signup-button"
                style={{ width: "30%" }}
                label={t("signin:signIn")}
              ></Button>
            </div>
          </form>

          <div
            className="display-flex justify-content-space-between mt-3"
            style={{ width: "100%" }}
          >
            <Link
              className="no-decoration"
              style={{
                color: thirdColor,
              }}
              to={"recoverPassword/recovery"}
            >
              {t("signin:forgotPassword")}
            </Link>
            <Link
              className="no-decoration"
              style={{
                color: thirdColor,
              }}
              to={"/signup"}
            >
              {t("signin:signUp")}
            </Link>
          </div>
        </div>
      )}
      <div style={{ position: "absolute", top: "2rem", left: "2rem" }}>
        <Button
          icon="pi pi-arrow-left"
          className="yellow-button"
          style={{ height: "56px" }}
          onClick={() => {
            window.open("/#/", "_self");
          }}
        ></Button>
      </div>
      <Toast ref={toast} />
    </div>
  );
};

export default SignIn;
