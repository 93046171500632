import { useEffect, useRef, useState } from "react";
import ProductCard from "../ProductCard/ProductCard";
import { primaryColor } from "../../../environment";
import { getAllproductsService } from "../../../../services/Product/ProductServices";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import LoadingComponent from "../../../LoadingPage/LoadingComponent";
import "primeicons/primeicons.css";
import "./ProductsList.css";
import { ImageWithURL } from "../../../../types/generic.types";
import { getMultipleImagesFromProduct } from "../../../../services/File/File";
import { useTranslation } from "react-i18next";
import { product } from "../../../../models/Product/product";

export type ProductListType = {
  id?: string;
  productType: string;
  productDescription: string;
  images: ImageWithURL[];
  productCategory: string;
  link?: string;
};

export const checkProduct = (prod: product, searchValue: string) => {
  return (
    prod.type.name.toLowerCase().includes(searchValue.toLowerCase()) ||
    prod.category.name.toLowerCase().includes(searchValue.toLowerCase()) ||
    prod.description.toLowerCase().includes(searchValue.toLowerCase())
  );
};

const ProductList = ({ searchValue }: { searchValue: string }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<ProductListType[]>([]);
  const [totalCards, setTotalCards] = useState<number>(4);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const maxCards = 8;
  const menu = useRef<Menu>(null);
  const { t } = useTranslation(["common"]);

  const getMenuSettings = (maxTotalCards: number) => {
    let menuSettings: MenuItem[] = [];
    for (let x = 1; x < maxTotalCards + 1; x++) {
      menuSettings.push({
        label: x.toString(),
        command: () => setTotalCards(x),
      });
    }
    return menuSettings;
  };

  useEffect(() => {
    setCurrentPage(0);
    getAllproductsService().then(async (data) => {
      let filtered: any = [];
      data.data.forEach((prod: any) => {
        if (!filtered.find((element: any) => prod.name === element.name)) {
          filtered.push(prod);
        }
      });

      const res = await getMultipleImagesFromProduct(filtered, searchValue);

      setTotalPage(Math.ceil(res.length / totalCards));
      setProducts(res);
      setLoading(false);
    });
  }, [searchValue, totalCards]);

  const dataViewProductsPagination = (prods: ProductListType[]) => {
    let offSet = currentPage * totalCards;
    let res = prods.filter((prod, x) => x >= offSet && x < totalCards + offSet);
    return res;
  };

  if (loading) return <LoadingComponent />;
  else
    return (
      <>
        <div>
          {products.length ? (
            <>
              <div className="display-flex flex-direction-row justify-content-center align-items-center flex-wrap">
                {dataViewProductsPagination(products).map((product, x) => {
                  return (
                    <div key={x} className="margin-top-bottom">
                      <ProductCard key={x} product={product} shop />
                    </div>
                  );
                })}
              </div>
              <div className="display-flex justify-content-center align-items-center">
                <i
                  style={{ color: primaryColor }}
                  onClick={() =>
                    currentPage - 1 >= 0
                      ? setCurrentPage(currentPage - 1)
                      : null
                  }
                  className="pi pi-angle-left"
                ></i>
                <div className="margin-right-left">
                  <span className="color-page-number">
                    {currentPage + 1} di {totalPage ? totalPage : 1}
                  </span>
                </div>
                <i
                  style={{ color: primaryColor }}
                  onClick={() =>
                    currentPage + 1 < totalPage
                      ? setCurrentPage(currentPage + 1)
                      : null
                  }
                  className="pi pi-angle-right"
                ></i>
              </div>
            </>
          ) : (
            <h4>{t("common:noProducts")}</h4>
          )}
        </div>

        <div className="btn-products-settings-container">
          <Menu
            model={getMenuSettings(maxCards)}
            popup
            ref={menu}
            id="popup_menu"
          />
          <i
            aria-controls="popup_menu"
            aria-haspopup
            className="pi pi-th-large btn-products-settings"
            onClick={(event) =>
              menu && menu.current && menu.current.toggle(event)
            }
          ></i>
        </div>
      </>
    );
};

export default ProductList;
