import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../General.css";
import "./Home.css";

const MountainCulture = () => {
  const { t } = useTranslation("common");

  return (
    <div className="display-flex flex-direction-row justify-content-space-around mountain-culture-position">
      <div className="big-font w-30vw">{t("mountainCulture")}</div>
      <div className="w-30vw display-flex flex-direction-column sentence-button-position">
        <div
          className="margin-bottom small-font mobile-padding"
          style={{ fontWeight: "400" }}
        >
          {t("mcSentence")}
        </div>
        <Link
          to="/philosophy"
          className="small-font no-decoration general-button"
        >
          {t("discover")}
        </Link>
      </div>
    </div>
  );
};

export default MountainCulture;
