import Footer from "../../Footer/Footer";
import { UseProductContext } from "../../Context/ProductContext";
import { SelectItemOptionsType } from "primereact/selectitem";
import { secondColor } from "../../environment";
import { ListBox } from "primereact/listbox";
import { useEffect, useState } from "react";
import { getAllProductTypesService } from "../../../services/Product/ProductType/ProductServices";
import { product_type } from "../../../models/Product/product_type";

const SelectListTemplate = () => {
  const { productType, setProductType } = UseProductContext();

  const [productTypesData, setProductTypesData] =
    useState<SelectItemOptionsType>([]);

  const parseData = (data: any) => {
    let catArr: SelectItemOptionsType = [];
    if (data && data.data) {
      data.data.map((cat: product_type) =>
        catArr.push({
          value: cat.id,
          label: cat.name,
        })
      );
      setProductTypesData(catArr);
    }
  };

  useEffect(() => {
    getAllProductTypesService()
      .then((data) => {
        parseData(data);
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <div className="flex flex-column justify-content-space-between align-items-center w-11 h-full">
      <div className="wrapper-list-box">
        {productTypesData.length ? (
          <ListBox
            optionLabel="label"
            value={productType.value}
            options={productTypesData}
            onChange={(e) =>
              setProductType({
                value: e.value,
                label: productTypesData
                  ? productTypesData.find((opt) => opt.value === e.value).label
                  : "",
              })
            }
            style={{ width: "80%" }}
            listStyle={{ height: "300px" }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={30}
          enableNextButton={productType.value !== ""}
          generalColor={secondColor}
        ></Footer>
      </div>
    </div>
  );
};

export default SelectListTemplate;
