import { useEffect, useState } from "react";
import {
  createImagesURLs,
  dropEvent,
  onDragOverFunction,
} from "../Functions/ImageFunctions";
import { UploadImages } from "../Producers/ImageTemplate/UploadImages";
import deleteImage from "../Assets/delete.png";
import { ShowImages } from "../Producers/ImageTemplate/ShowImages";
import { deleteFile, getUrlAndUploadFile } from "../../services/File/File";
import { showError, showSuccess } from "../Toast/Toast.functions";
import { company } from "../../models/Company/company";
import { updateCompanyService } from "../../services/Company/CompanyServices";
import { DialogUploadLogoInterface } from "./DialogUploadFotoProps";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { LoadingImage } from "../LoadingImage/LoadingImage";

const DialogUploadLogo = ({
  toast,
  header,
  visible,
  setVisible,
  logoImage,
  setLogoImage,
  companyProfile,
}: DialogUploadLogoInterface) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [imagesUrls, setImagesUrls] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [tmpLogo, setTmpLogo] = useState(logoImage);
  const [numOfFiles, setNumOfFiles] = useState(0);
  const [hide, setHide] = useState(true);
  const { t } = useTranslation(["common", "imagesSentence"]);

  useEffect(() => {
    createImagesURLs(images, setImagesUrls);
  }, [images]);

  const saveNewLogo = async () => {
    setShowLoading(true);
    if (!companyProfile)
      return showError(toast, t("common:uploadCompanyDataError"));

    getUrlAndUploadFile(images[0].name, images[0])
      .then((image) => {
        const companyModified: company = {
          _id: companyProfile.id,
          name: companyProfile.name,
          description: companyProfile.description,
          address: companyProfile.producerPosition,
          pictures: companyProfile.producerImages,
          logo: { key: image.key, filename: images[0].name },
          email: companyProfile.producerEmail,
          payment: companyProfile.producerPayment,
          phone: companyProfile.producerPhone,
          vat: companyProfile.producerVat,
          website: companyProfile.producerWebSite,
          values: companyProfile.producerValues,
          users: companyProfile.producerUsers,
          companyType: companyProfile.producerType,
          products: companyProfile.producerProducts,
        };
        updateCompanyService(companyModified)
          .then((res: any) => {
            if (res.status === 200) {
              setTmpLogo({
                key: image.key,
                filename: images[0].name,
                url: image.url,
              });
              setLogoImage({
                key: image.key,
                filename: images[0].name,
                url: image.url,
              });
              setImagesUrls([]);
              setImages([]);
              setHide(true);
              setShowLoading(false);
              showSuccess(toast, t("imagesSentence:logoModifiedSuccess"));
            } else showError(toast, t("imagesSentence:logoModifiedError"));
          })
          .catch(() => showError(toast, t("imagesSentence:logoModifiedError")));
      })
      .catch(() => showError(toast, t("imagesSentence:uploadImagesError")));
  };

  const deleteImageOnAmazon = () => {
    setShowLoading(true);
    const body = {
      key: logoImage.key,
    };
    deleteFile(body).then((res) => {
      if (res.status === 200) {
        setNumOfFiles(0);
        showSuccess(toast, t("imagesSentence:deleteLogoSuccess"));
        setTmpLogo({ key: "", filename: "", url: "" });
        setShowLoading(false);
        setHide(false);
      } else showError(toast, t("imagesSentence:deleteLogoError"));
    });
  };

  return (
    <Dialog
      visible={visible}
      header={header}
      onHide={() =>
        hide
          ? setVisible(false)
          : showError(toast, t("imagesSentence:onHideLogoError"))
      }
      footer={
        <>
          {images.length > 0 ? (
            <Button
              className="general-button"
              onClick={saveNewLogo}
              disabled={!companyProfile}
            >
              {t("common:confirm")}
            </Button>
          ) : (
            <></>
          )}
        </>
      }
    >
      <div
        id="div-card-image"
        className="card-image"
        onDrop={(e) =>
          dropEvent(
            e,
            toast,
            numOfFiles,
            setNumOfFiles,
            images,
            setImages,
            1,
            t
          )
        }
        onDragOver={onDragOverFunction}
        draggable={true}
      >
        {tmpLogo.url !== "" ? (
          <div className="image-template-card display-flex justify-content-space-between align-items-center">
            <Image
              src={tmpLogo.url}
              alt="immagine"
              className="dialog-images"
              preview
              onError={() => setTmpLogo({ url: "", key: "", filename: "" })}
            />
            <img
              src={deleteImage}
              alt="delete"
              style={{ width: "2rem", height: "2rem" }}
              onClick={deleteImageOnAmazon}
            />
          </div>
        ) : images.length > 0 ? (
          <ShowImages
            images={images}
            setImages={setImages}
            imagesUrls={imagesUrls}
            setImagesUrls={setImagesUrls}
            numOfFiles={numOfFiles}
            setNumOfFiles={setNumOfFiles}
          />
        ) : (
          <UploadImages
            toastError={toast}
            numberFile={1}
            numOfFiles={numOfFiles}
            setNumOfFiles={setNumOfFiles}
            images={images}
            setImages={setImages}
            firstSentence={t("imagesSentence:firstSentenceLogo")}
            secondSentence={""}
            thirdSentence={""}
            showImage={true}
          />
        )}
      </div>
      <LoadingImage visible={showLoading} />
    </Dialog>
  );
};

export default DialogUploadLogo;
