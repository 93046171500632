import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { getUrlAndUploadProductFileMultiple } from "../../../services/File/File";
import { createProductImage } from "../../../services/ProductsImages/ProductsImages";
import { ShowImages } from "../../Producers/ImageTemplate/ShowImages";
import { UploadImages } from "../../Producers/ImageTemplate/UploadImages";
import { showError, showSuccess } from "../../Toast/Toast.functions";

interface PhotoDialogProps {
  images: any;
  setImages: Dispatch<SetStateAction<any>>;
  imagesUrls: any;
  setImagesUrls: Dispatch<SetStateAction<any>>;
  numOfFiles: number;
  setNumOfFiles: Dispatch<SetStateAction<number>>;
  showAddPhoto: boolean;
  setShowAddPhoto: Dispatch<SetStateAction<boolean>>;
  toast: any;
}

const PhotoDialog = ({
  images,
  setImages,
  imagesUrls,
  setImagesUrls,
  numOfFiles,
  setNumOfFiles,
  showAddPhoto,
  setShowAddPhoto,
  toast,
}: PhotoDialogProps) => {
  const { t } = useTranslation(["common", "signin"]);

  const uploadFoto = () => {
    getUrlAndUploadProductFileMultiple(images).then((res) => {
      const imageObject = res.map((result) => {
        return {
          key: result.key,
          filename: result.filename,
          category: 0,
        };
      });

      imageObject.forEach((image) => {
        createProductImage(image)
          .then((resultCreate: any) => {
            if (resultCreate.status === 201) {
              showSuccess(
                toast,
                `immagine salvata sul db, id: ${resultCreate.data._id}`
              );
              setImages([]);
              setImagesUrls([]);
            } else {
              console.log(res);
            }
          })
          .catch((error) => {
            console.log(error);
            showError(toast, `immagine non salvata sul database}`);
          });
      });
    });
  };

  return (
    <Dialog visible={showAddPhoto} onHide={() => setShowAddPhoto(false)}>
      <div className="display-flex flex-direction-column align-items-center">
        <div className="text-center medium-font margin-bottom">
          {t("common:productPhoto")}
        </div>
        <div
          style={{
            boxShadow: "0 0 3px #000",
            padding: "1rem",
            borderRadius: "20px",
            marginBottom: "1rem",
            width: "30vw",
            height: "60vh",
            overflowY: "auto",
          }}
        >
          {imagesUrls.length > 0 ? (
            <>
              <ShowImages
                images={images}
                setImages={setImages}
                imagesUrls={imagesUrls}
                setImagesUrls={setImagesUrls}
                numOfFiles={numOfFiles}
                setNumOfFiles={setNumOfFiles}
              />
              {imagesUrls.length < 100 ? (
                <>
                  <UploadImages
                    toastError={toast}
                    numberFile={100}
                    numOfFiles={numOfFiles}
                    setNumOfFiles={setNumOfFiles}
                    images={images}
                    setImages={setImages}
                    firstSentence={""}
                    secondSentence={""}
                    thirdSentence={t("common:imageUpload")}
                    showImage={false}
                    setButtonText={undefined}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <UploadImages
              toastError={toast}
              numberFile={100}
              numOfFiles={numOfFiles}
              setNumOfFiles={setNumOfFiles}
              images={images}
              setImages={setImages}
              firstSentence={t("common:imageUpload")}
              secondSentence={""}
              thirdSentence={""}
              showImage={true}
            />
          )}
        </div>
        <Button onClick={uploadFoto}>{t("common:upload")}</Button>
      </div>
    </Dialog>
  );
};
export default PhotoDialog;
