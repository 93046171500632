import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Cities, ImageInterface } from "../../types/generic.types";

/*
  0: nulla
  1: agricoltore che offre prodotti
  2: agricoltore che offre servizi
  3: agricoltore che offre prodotti e servizi
*/
export enum CompanyType {
  NONE,
  PRODUCTS,
  SERVICES,
  PRODUCTSSERVICES,
}

interface contextInterface {
  producerType: number;
  setProducerType: Dispatch<SetStateAction<number>>;
  producerPosition: Cities;
  setProducerPosition: Dispatch<SetStateAction<Cities>>;
  producerName: string;
  setProducerName: Dispatch<SetStateAction<string>>;
  producerDescription: string;
  setProducerDescription: Dispatch<SetStateAction<string>>;
  producerValues: string;
  setProducerValues: Dispatch<SetStateAction<string>>;
  producerVAT: string;
  setProducerVAT: Dispatch<SetStateAction<string>>;
  producerEmail: string;
  setProducerEmail: Dispatch<SetStateAction<string>>;
  producerPhone: string;
  setProducerPhone: Dispatch<SetStateAction<string>>;
  producerWebSite: string;
  setProducerWebsite: Dispatch<SetStateAction<string>>;
  producerLogo: ImageInterface;
  setProducerLogo: Dispatch<SetStateAction<ImageInterface>>;
  producerImages: ImageInterface[];
  setProducerImages: Dispatch<SetStateAction<ImageInterface[]>>;
  producerPassword: string;
  setProducerPassword: Dispatch<SetStateAction<string>>;
}

const ProducerContext = createContext<any>({});

const ProducerProvider = ({ children }: { children: JSX.Element }) => {
  const [producerType, setProducerType] = useState<number>(0);
  const [producerPosition, setProducerPosition] = useState<Cities>({
    label: "",
    lat: "",
    lon: "",
  });
  const [producerName, setProducerName] = useState<string>("");
  const [producerDescription, setProducerDescription] = useState<string>("");
  const [producerValues, setProducerValues] = useState<string>("");
  const [producerImages, setProducerImages] = useState<ImageInterface[]>([]);
  const [producerLogo, setProducerLogo] = useState<ImageInterface>({
    key: "",
    filename: "",
  });
  const [producerVAT, setProducerVAT] = useState<string>("");
  const [producerEmail, setProducerEmail] = useState<string>("");
  const [producerPhone, setProducerPhone] = useState<string>("");
  const [producerWebSite, setProducerWebsite] = useState<string>("");
  const [producerPassword, setProducerPassword] = useState<string>("");

  // console.log({
  //   producerType,
  //   setProducerType,
  //   producerPosition,
  //   setProducerPosition,
  //   producerName,
  //   setProducerName,
  //   producerDescription,
  //   setProducerDescription,
  //   producerVAT,
  //   setProducerVAT,
  //   producerEmail,
  //   setProducerEmail,
  //   producerPhone,
  //   setProducerPhone,
  //   producerWebSite,
  //   setProducerWebsite,
  //   producerImages,
  //   setProducerImages,
  //   producerLogo,
  //   setProducerLogo,
  //   producerValues,
  //   setProducerValues,
  //   producerPassword,
  //   setProducerPassword,
  // });

  return (
    <ProducerContext.Provider
      value={{
        producerType,
        setProducerType,
        producerPosition,
        setProducerPosition,
        producerName,
        setProducerName,
        producerDescription,
        setProducerDescription,
        producerValues,
        setProducerValues,
        producerVAT,
        setProducerVAT,
        producerEmail,
        setProducerEmail,
        producerPhone,
        setProducerPhone,
        producerWebSite,
        setProducerWebsite,
        producerImages,
        setProducerImages,
        producerLogo,
        setProducerLogo,
        producerPassword,
        setProducerPassword,
      }}
    >
      {children}
    </ProducerContext.Provider>
  );
};

export default ProducerProvider;
export const UseProducerContext = () =>
  useContext(ProducerContext) as contextInterface;
