import "./AddProduct.css";
import "../General.css";
import CardTemplate from "./CardTemplate/CardTemplate";
import DescriptionTemplate from "./DescriptionTemplate/DescriptionTemplate";
import {
  contextPageInterface,
  UsePaginatorContext,
} from "../Context/PagesContext";
import SelectListTemplate from "./SelectListTemplate/SelectListTemplate";
import { secondColor } from "../environment";
import CardSummary from "./CardSummary/CardSummary";
import {
  contextProductInterface,
  UseProductContext,
} from "../Context/ProductContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ImageSelectionTemplate from "./ImageSelectionTemplate/ImageSelectionTemplate";
import LinkTemplate from "./LinkTemplate/LinkTemplate";
import { useTranslation } from "react-i18next";

const SwitchComponent = () => {
  const { setPageTitle, currentPage } = UsePaginatorContext();
  const { t } = useTranslation("common");

  switch (currentPage) {
    case 0:
      setPageTitle(t("common:whichCat"));
      return <CardTemplate />;
    case 1:
      setPageTitle(t("common:whichProd"));
      return <SelectListTemplate />;
    case 2:
      setPageTitle(t("common:descrProd"));
      return <DescriptionTemplate />;
    case 3:
      setPageTitle(t("common:linkProd"));
      return <LinkTemplate />;
    case 4:
      setPageTitle(t("common:imageProd"));
      return <ImageSelectionTemplate />;
    case 5:
      setPageTitle(t("common:recap"));
      return <CardSummary />;
    default:
      return <></>;
  }
};

const AddProduct = () => {
  const { t } = useTranslation("common");
  const pagContex = UsePaginatorContext();
  const prodContex = UseProductContext();
  let nav = useNavigate();

  const saveAndExit = () => {
    sessionStorage.setItem(
      "insert_product-pagContex",
      JSON.stringify(pagContex)
    );
    sessionStorage.setItem(
      "insert_product-prodContex",
      JSON.stringify(prodContex)
    );
    nav("/profile/producer");
  };

  const checkSession = () => {
    let session_pagContex = sessionStorage.getItem("insert_product-pagContex");
    let session_prodContex = sessionStorage.getItem(
      "insert_product-prodContex"
    );
    if (
      session_pagContex &&
      session_prodContex &&
      session_pagContex !== "" &&
      session_prodContex !== ""
    ) {
      let old_prodContex: contextProductInterface =
        JSON.parse(session_prodContex);
      let old_pagContex: contextPageInterface = JSON.parse(session_pagContex);
      pagContex.setCurrentPage(old_pagContex.currentPage);
      prodContex.setImages(old_prodContex.images);
      prodContex.setProductCategory(old_prodContex.productCategory);
      prodContex.setProductDescription(old_prodContex.productDescription);
      prodContex.setProductType(old_prodContex.productType);
      sessionStorage.removeItem("insert_product-pagContex");
      sessionStorage.removeItem("insert_product-prodContex");
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <div className="add-product-wrapper">
      <>
        <div
          style={{ backgroundColor: secondColor }}
          className="display-flex flex-direction-column align-items-center wrap title-wrapper"
        >
          <p className="medium-font text-color-producers mobile-title">
            BIZalp
          </p>
          <div className="big-font text-color-producers title-position">
            {pagContex.pageTitle}
          </div>
        </div>
        <div className="wrapper-add-product-switch">
          <div className="header-switcher" style={{ display: "none" }}>
            <div
              className={"save-exit-page-button"}
              style={{ backgroundColor: secondColor }}
              onClick={() => saveAndExit()}
            >
              {t("common:saveExit")}
            </div>
          </div>
          <div className="display-flex flex-direction-column align-items-center h-full add-product-position">
            <SwitchComponent />
          </div>
        </div>
      </>
    </div>
  );
};

export default AddProduct;
