import { Image } from "primereact/image";
import { Dispatch, SetStateAction } from "react";
import { deleteImageURLS } from "../../Functions/ImageFunctions";
import deleteImage from "../../Assets/delete.png";
import docs from "../../Assets/document_copy.png";
export interface ShowImagesProps {
  images: any[];
  setImages: Dispatch<SetStateAction<any[]>>;
  imagesUrls: any[];
  setImagesUrls: Dispatch<SetStateAction<any[]>>;
  numOfFiles: number;
  setNumOfFiles: Dispatch<SetStateAction<number>>;
}

export const ShowImages = ({
  images,
  setImages,
  imagesUrls,
  setImagesUrls,
  numOfFiles,
  setNumOfFiles,
}: ShowImagesProps) => {
  return (
    <div
      className="display-flex flex-direction-column "
      style={{ overflowY: "auto", width: "100%" }}
    >
      {imagesUrls.map((imageSrc, index) => (
        <div
          className="image-template-card display-flex justify-content-space-between align-items-center"
          key={index}
        >
          <Image
            src={imageSrc.url}
            alt={imageSrc.name}
            onError={(e: any) => (e.target.src = docs)}
            preview
            className="dialog-images"
          />
          <img
            src={deleteImage}
            alt="delete"
            style={{ width: "2rem", height: "2rem" }}
            onClick={() =>
              deleteImageURLS(
                imageSrc,
                images,
                setImages,
                setImagesUrls,
                imagesUrls,
                numOfFiles,
                setNumOfFiles
              )
            }
          />
        </div>
      ))}
    </div>
  );
};
