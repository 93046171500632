import "./ProductCardCarousel.css";
import { Galleria } from "primereact/galleria";
import { ImageWithURL } from "../../../../../types/generic.types";
import scheda from "../../../../Assets/Scheda_produttore.png";

const ProductCardImagesNavigator = ({ images }: { images: ImageWithURL[] }) => {
  const productTemplate = (product: ImageWithURL) => {
    return (
      <div className="product-item">
        <div className="product-item-content">
          <img
            src={product ? product.url : scheda}
            onError={(e: any) => (e.target.src = scheda)}
            alt={product ? product.filename : ""}
            className="product-image"
          />
        </div>
      </div>
    );
  };
  return (
    <Galleria
      value={images}
      numVisible={5}
      circular
      showItemNavigators={images.length > 1 ? true : false}
      showThumbnails={false}
      item={productTemplate}
      className="custom-image"
    />
  );
};

export default ProductCardImagesNavigator;
