import { useTranslation } from "react-i18next";
import { primaryColor } from "../environment";

const PhilosphyDescription = () => {
  const { t } = useTranslation(["common", "philosophy"]);

  return (
    <div className="flex flex-column align-items-center">
      <div
        className="margin-bottom small-font mobile-padding w-full lg:w-10"
        style={{ fontWeight: "400" }}
      >
        <div
          className="big-font"
          style={{ color: primaryColor, textAlign: "center" }}
        >
          {t("common:philosophy")}
        </div>
        <div className="mt-5">{t("philosophy:firstSentence")}</div>
        <div className="mt-2">{t("philosophy:secondSentence")}</div>
        <div className="mt-2">{t("philosophy:thirdSentence")}</div>
        <div className="mt-2">{t("philosophy:fourthSentence")}</div>
        <div className="mt-2">{t("philosophy:fifthSentence")}</div>
      </div>
    </div>
  );
};

export default PhilosphyDescription;
