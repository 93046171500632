import { Toast } from "primereact/toast";
import { Dispatch, DragEvent, RefObject, SetStateAction } from "react";
import { showError } from "../Toast/Toast.functions";

export const onImageChange = (
  e: any,
  toast: RefObject<Toast>,
  max: number,
  numOfFiles: number,
  setNumOfFiles: Dispatch<SetStateAction<number>>,
  images: any,
  setImages: any,
  t: any
) => {
  if (e.target.files.length > max)
    return showError(toast, `${t("common:maxNum")} ${max} file`);
  if (numOfFiles + e.target.files.length > max)
    return showError(toast, `${t("common:maxNum")} ${max} file`);
  numOfFiles > 0
    ? setNumOfFiles(numOfFiles + e.target.files.length)
    : setNumOfFiles(e.target.files.length);
  setImages([...images, ...e.target.files]);
};

export const deleteImageURLS = (
  imageProps: any,
  images: any,
  setImages: any,
  setImagesUrls: any,
  imagesUrls: any,
  numOfFiles: number,
  setNumOfFiles: Dispatch<SetStateAction<number>>
) => {
  setImages(images.filter((image: any) => image.name !== imageProps.name));
  setImagesUrls(
    imagesUrls.filter((imageUrl: any) => imageProps.name !== imageUrl.name)
  );
  setNumOfFiles(numOfFiles - 1);
};

export const onDragOverFunction = (e: DragEvent<HTMLDivElement>) => {
  e.preventDefault();
};

export const dropEvent = (
  event: any,
  toastError: RefObject<Toast>,
  numOfFiles: number,
  setNumOfFiles: Dispatch<SetStateAction<number>>,
  images: any,
  setImages: any,
  maxFiles: number,
  t:any
) => {
  event.preventDefault();
  event.stopPropagation();
  if (event.dataTransfer.files.length > maxFiles)
    return showError(toastError, `${t("common:maxNum")} ${maxFiles} file`);
  if (numOfFiles + event.dataTransfer.files.length > maxFiles)
    return showError(toastError, `${t("common:maxNum")} ${maxFiles} file`);
  numOfFiles > 0
    ? setNumOfFiles(numOfFiles + event.dataTransfer.files.length)
    : setNumOfFiles(event.dataTransfer.files.length);
  setImages([...images, ...event.dataTransfer.files]);
};

export const createImagesURLs = (images: any, setImagesUrls: any) => {
  if (images.length < 1) return;
  else {
    const newImagesURL: any[] = [];
    images.forEach((image: any) =>
      newImagesURL.push({ url: URL.createObjectURL(image), name: image.name })
    );
    setImagesUrls(newImagesURL);
  }
};
