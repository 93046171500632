import { JWK, JWKObject, JWT } from "ts-jose";
import { searchUserServiceId } from "../User/UsersServices";

export const generateJwt = async () => {

    const userData = await searchUserServiceId({ _id: sessionStorage.getItem("userId") }).then(
      (res) => {
        console.log("got user data, ", res);
        return res.data;
      }
    );

    const now = new Date();
    const expirationTime = now.getTime() + 10 * 60 * 1000;
    const expJwt = Math.floor(expirationTime / 1000);

    const payload = {
      'id_affiliato': 257633,
      'email': userData.email, 
      'nome': userData.name, 
      'cognome': userData.surname,
      'expiration': expJwt
    };

    const jwkObj: JWKObject = {
      kty: 'oct', // Key type -- simmetric
      use: 'sig', // Use case ( "sig" for signing),
      // kid: "0afee142-a0af-4410-abcc-9f2d44ff45b5",
      alg: 'HS256',
      noEncode: true,
      k: 'BizalpContributi2023aVeryLongKey'
    }
  
    const privateKey:JWK = await JWK.fromObject(jwkObj);
  
    const token = await JWT.sign(payload, privateKey);
    
    return token;
}
  