import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { company } from "../../../models/Company/company";
import { updateCompanyService } from "../../../services/Company/CompanyServices";
import { updateUserService } from "../../../services/User/UsersServices";
import { EMAIL_REGEX } from "../../../types/Regex";
import { showError, showSuccess } from "../../Toast/Toast.functions";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../environment";
import { Cities } from "../../../types/generic.types";
import { Link } from "react-router-dom";

interface DialogPropfileProps {
  showDialogProfile: boolean;
  setShowDialogProfile: Dispatch<SetStateAction<boolean>>;
  company: company;
  updateUser: boolean;
  setUpdateUser: Dispatch<SetStateAction<boolean>>;
}

const DialogCompany = ({
  company,
  showDialogProfile,
  setShowDialogProfile,
  setUpdateUser,
  updateUser,
}: DialogPropfileProps) => {
  const [nameModified, setNameModified] = useState<string>(company.name);
  const [emailModified, setEmailModified] = useState<string>(company.email);
  const [valueModified, setValueModified] = useState<string>(company.values);
  const [descriptionModified, setDescriptionModified] = useState<string>(
    company.description
  );
  const [phoneModified, setPhoneModified] = useState<string>(company.phone);
  const [addressModified, setAddressModified] = useState(company.address.label);
  const [fullAddressModified, setFullAddressModified] = useState<Cities>(
    company.address
  );
  const toast = useRef<Toast>(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation(["common", "error"]);

  const [searchBox, setSearchBox] = useState<any>(null);

  const onPlacesChanged = () => {
    const place = searchBox?.getPlaces();
    setAddressModified(place.name);

    var modifiedAddr = {
      label: place[0].formatted_address,
      lat: place[0].geometry.location.lat(),
      lon: place[0].geometry.location.lng(),
    };

    setFullAddressModified(modifiedAddr);
  };
  const onSBLoad = (ref: any) => {
    setSearchBox(ref);
  };

  const updateUserCompany = () => {
    if (!EMAIL_REGEX.test(emailModified))
      return showError(toast, t("error:cannotEditEmail"));

    if (
      sessionStorage.getItem("userId") == null ||
      sessionStorage.getItem("idToken") == null
    )
      return showError(toast, t("error:relog"));

    const updateObject = {
      _id: company._id,
      name: nameModified,
      description: descriptionModified,
      vat: company.vat,
      address: fullAddressModified,
      logo: company.logo,
      pictures: company.pictures,
      email: company.email,
      phone: phoneModified,
      payment: company.payment,
      values: valueModified,
      companyType: company.companyType,
      products: company.products,
    };
    updateUserService({
      _id: sessionStorage.getItem("userId"),
      name: nameModified,
      surname: nameModified,
    })
      .then((res: any) => {
        if (res.status === 200) {
          updateCompanyService(updateObject)
            .then((res: any) => {
              if (res.status === 200) {
                sessionStorage.setItem("user", nameModified);
                return showSuccess(toast, t("common:editSuccess"));
              } else showError(toast, t("error:somethingWrong"));
            })
            .catch((err) => console.log(err));
        } else showError(toast, t("error:somethingWrong"));
      })
      .catch((err) => console.log(err));
  };

  return (
    <Dialog
      header={t("common:editProfile")}
      visible={showDialogProfile}
      className="dialog-new-comment"
      footer={
        <div className="w-full flex justify-content-space-between">
          <Link
            className="general-button"
            style={{ textDecoration: "none" }}
            to="/signin/recoverPassword/change"
          >
            {t("common:ModifyPassword")}
          </Link>
          <Button className="general-button" onClick={updateUserCompany}>
            {t("common:Save")}
          </Button>
        </div>
      }
      onHide={() => {
        setUpdateUser(!updateUser);
        setShowDialogProfile(false);
      }}
    >
      <div
        className="display-flex"
        style={{ flexDirection: "column", marginBottom: "1rem" }}
      >
        <div className="card-profile-small-title">{t("common:Name")}</div>
        <InputText
          value={nameModified}
          maxLength={50}
          style={{ width: "100%" }}
          onChange={(res) => {
            setNameModified(res.target.value);
          }}
        />
        <span style={{ fontSize: "12px", marginLeft: "0.5rem" }}>
          {nameModified.length}/50
        </span>
      </div>
      <div
        className="display-flex"
        style={{ flexDirection: "column", marginBottom: "1rem" }}
      >
        <div className="card-profile-small-title">
          {t("common:Description")}
        </div>
        <InputTextarea
          value={descriptionModified}
          maxLength={500}
          style={{ width: "100%" }}
          onChange={(res) => {
            setDescriptionModified(res.target.value);
          }}
        />
        <span style={{ fontSize: "12px", marginLeft: "0.5rem" }}>
          {descriptionModified.length}/500
        </span>
      </div>
      <div
        className="display-flex"
        style={{ flexDirection: "column", marginBottom: "1rem" }}
      >
        <div className="card-profile-small-title">{t("common:value")}</div>
        <InputText
          value={valueModified}
          style={{ width: "100%" }}
          onChange={(res) => {
            setValueModified(res.target.value);
          }}
        />
      </div>
      <div
        className="display-flex"
        style={{ flexDirection: "column", marginBottom: "1rem" }}
      >
        <div className="card-profile-small-title">Email</div>
        <InputText
          disabled={true}
          value={emailModified}
          style={{ width: "100%" }}
          onChange={(res) => {
            setEmailModified(res.target.value);
          }}
        />
      </div>
      <div
        className="display-flex"
        style={{ flexDirection: "column", marginBottom: "1rem" }}
      >
        <div className="card-profile-small-title">{t("common:Phone")}</div>
        <InputMask
          value={phoneModified}
          id="phone"
          mask={
            i18n.language === "it" ? "+39 999 999 9999" : "+33-9-99-99-99-99"
          }
          placeholder={
            i18n.language === "it" ? "+39 999 999 9999" : "+33-9-99-99-99-99"
          }
          onChange={(res) => {
            setPhoneModified(res.target.value);
          }}
        ></InputMask>
      </div>

      <div
        className="display-flex"
        style={{
          flexDirection: "column",
          marginBottom: "1rem",
        }}
      >
        <div className="card-profile-small-title">{t("common:Address")}</div>

        <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={["places"]}>
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={onSBLoad}
          >
            <InputText
              placeholder={t("common:typeAddress")}
              value={addressModified}
              onChange={(event) => {
                setAddressModified(event.target.value);
              }}
              className="search-bar-map"
              style={{ width: "100%" }}
            ></InputText>
          </StandaloneSearchBox>
        </LoadScript>
      </div>
      <Toast ref={toast} />
    </Dialog>
  );
};

export default DialogCompany;
