import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ImageWithURL } from "../../../../../types/generic.types";
import GalleryNav from "./GalleryNav";

const DialogChangePhoto = ({
  productImages,
  showDialogChangePhoto,
  setShowDialogChangePhoto,
  setProductImages,
}: {
  productImages: ImageWithURL[];
  showDialogChangePhoto: boolean;
  setShowDialogChangePhoto: Dispatch<SetStateAction<boolean>>;
  setProductImages: Dispatch<SetStateAction<ImageWithURL[]>>;
}) => {
  const { t } = useTranslation("common");

  return (
    <Dialog
      header={t("common:modifyPhotoProduct")}
      visible={showDialogChangePhoto}
      closable={false}
      onHide={() => setShowDialogChangePhoto(false)}
      className="dialog-modify-product"
      footer={
        <Button
          className="general-button mt-2"
          onClick={() => setShowDialogChangePhoto(false)}
        >
          {t("common:confirm")}
        </Button>
      }
    >
      <GalleryNav
        productImages={productImages}
        setProductImages={setProductImages}
      ></GalleryNav>
    </Dialog>
  );
};

export default DialogChangePhoto;
