import { useEffect, useState } from "react";
import { getAllProductImage } from "../../../services/ProductsImages/ProductsImages";
import { getMultipleProductsUrl } from "../../../services/File/File";
import { ImageWithURL } from "../../../types/generic.types";
import ImageCard from "./ImageCard";
import "./GalleryNav.css";

const GalleryNav = () => {
  const [images, setImages] = useState<ImageWithURL[]>([]);

  useEffect(() => {
    getAllProductImage()
      .then(async (res: any) => {
        if (res.status === 200) {
          getMultipleProductsUrl(res.data)
            .then((resMultiple) => setImages(resMultiple))
            .catch((error) => console.log(error));
        } else console.log(res);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="gallery-nav">
      {images.map((image) => (
        <ImageCard
          key={image.key}
          filename={image.filename}
          publicKey={image.key}
          url={image.url}
        />
      ))}
    </div>
  );
};
export default GalleryNav;
