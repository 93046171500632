import { useTranslation } from "react-i18next";
import { primaryColor } from "../environment";

const MountainCulturePhilosophy = () => {
  const { t } = useTranslation(["common", "philosophy"]);

  return (
    <div className="display-flex flex-direction-row justify-content-space-around mountain-culture-position">
      <div className="big-font w-30vw " style={{ color: primaryColor }}>
        {t("common:mountainCulture")}
      </div>
      <div className="w-30vw display-flex flex-direction-column sentence-button-position">
        <div
          className="margin-bottom small-font mobile-padding"
          style={{ fontWeight: "400" }}
        >
          {t("philosophy:mcSentencePhilosophy")}
        </div>
      </div>
    </div>
  );
};

export default MountainCulturePhilosophy;
