import { useTranslation } from "react-i18next";
import { CompanyProfile } from "../../../../models/Company/company";
import "./ProfileImagesInfo.css";

const ProfileImagesInfo = (profile: CompanyProfile) => {
  const { t } = useTranslation(["common"]);
  const infos = [
    {
      title: t("common:call"),
      backgroundColor: "#e37545",
      text: profile.producerPhone,
    },
    {
      title: t("common:wwAre"),
      backgroundColor: "#c4d37d",
      text: profile.producerPosition.label,
    },
    {
      title: "Mail",
      backgroundColor: "#4eb1ac",
      text: profile.producerEmail,
    },
    {
      title: t("common:website"),
      backgroundColor: "#f0b660",
      text: profile.producerWebSite,
    },
  ];

  return (
    <>
      <div
        className="display-flex flex-direction-row justify-content-center align-items-center flex-wrap wrapper-card-info"
        style={{ marginBottom: "2rem", marginTop: "2rem" }}
      >
        {infos.map((card, x) => (
          <div
            key={x}
            style={{ backgroundColor: card.backgroundColor }}
            className="card-style"
          >
            <div className="card-title">{card.title}</div>
            <div className="card-text">{card.text}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProfileImagesInfo;
