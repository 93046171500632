import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, Routes } from "react-router-dom";
import { iphone } from "../../types/Regex";
import NotFound from "../404/NotFound";
import Admin from "../AdminPage/Admin";
import BulletinBoard from "../BulletinBoard/BulletinBoard";
import CookiePolicy from "../CookiePolicy/CookiePolicy";
import ForumRules from "../ForumRules/ForumRules";
import Footer from "../Home/Footer";
import Home from "../Home/Home";
import Navbar from "../Navbar/Navbar";
import Philosophy from "../Philosophy/Philosophy";
import PrivacyPolicy from "../Privacy Policy/PrivacyPolicy";
import ProducerProfile from "../ProducerProfile/ProducerProfile";
import Products from "../ProductsPage/Products";
import ProfilePage from "../ProfilePage/ProfilePage";
import ProfileProducerPage from "../ProfilePage/ProfileProducerPage/ProfileProducerPage";

const Layout = () => {
  const [visible, setVisible] = useState(true);
  const [visiblePrivacy, setVisiblePrivacy] = useState(true);
  const { t } = useTranslation("common");

  const saveConfirm = () => {
    window.localStorage.setItem("userConfirmCookies", "true");
    setVisible(false);
  };

  const saveConfirmPrivacy = () => {
    window.localStorage.setItem("privacy_policy_accepted", "true");
    window.localStorage.setItem("forum_rules_accepted", "true");
    setVisiblePrivacy(false);
  };

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <div
      className={`flex flex-column div-content-layout ${
        iphone ? "safari" : ""
      } `}
    >
      <Navbar />
      <div
        className={`flex flex-column justify-content-between div-routes-layout ${
          iphone ? "safari" : ""
        }`}
      >
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="admin" element={<Admin />} />
          <Route path="products" element={<Products />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="profile/producer" element={<ProfileProducerPage />} />
          <Route path="philosophy" element={<Philosophy />} />
          <Route path="bulletinboard" element={<BulletinBoard />} />
          <Route path="producerProfile/:id" element={<ProducerProfile />} />
          <Route path="cookiePolicy" element={<CookiePolicy />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="forumrules" element={<ForumRules />} />
        </Routes>
        <Footer />
      </div>
      {window.localStorage.getItem("userConfirmCookies") === "true" ? (
        <></>
      ) : (
        <Dialog
          header={t("common:cookieConfirm")}
          visible={visible}
          onHide={saveConfirm}
          style={{ width: "50vw" }}
          position="bottom"
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div style={{ marginBottom: "1rem" }}>
              {t("common:firstSentenceCookieDialog")}
              <Link to={"/cookiePolicy"}>cookie policy</Link>.
              {t("common:secondSentenceCookieDialog")}
            </div>
            <Button className="general-button" onClick={saveConfirm}>
              {t("common:confirm")}
            </Button>
          </div>
        </Dialog>
      )}
      {window.localStorage.getItem("privacy_policy_accepted") === "true" &&
      window.localStorage.getItem("forum_rules_accepted") === "true" ? (
        <></>
      ) : (
        <Dialog
          header={t("common:privacyConfirm")}
          visible={visiblePrivacy}
          onHide={saveConfirmPrivacy}
          style={{ width: "50vw" }}
          position="bottom"
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div style={{ marginBottom: "1rem" }}>
              {t("common:firstSentencePrivacyDialog")}
              <Link to={"/privacyPolicy"}>privacy policy</Link>
              {t("common:secondSentencePrivacyDialog")}
              <Link to={"/forumRules"}>{t("common:forumRules")}</Link>
            </div>
            <Button className="general-button" onClick={saveConfirmPrivacy}>
              {t("common:confirm")}
            </Button>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default Layout;
