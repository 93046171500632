import "./RegisterProducers.css";
import "../General.css";
import ProducerName from "./ProducerName/ProducerName";
import DescriptionTemplate from "./DescriptionTemplate/DescriptionTemplate";
import ImageTemplate from "./ImageTemplate/ImageTemplate";
import OtherData from "./OtherData/OtherData";
import { UsePaginatorContext } from "../Context/PagesContext";
import FirstPage from "./FirstPage/FirstPage";
import LogoTemplate from "./ImageTemplate/LogoTemplate";
import AddressTemplate from "./AddressTemplate/AddressTemplate";
import Values from "./Values/Values";
import EmailCompany from "./EmailCompany/EmailCompany";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

let colorBackground = "#ee7050";

const SwitchComponent = () => {
  const { setPageTitle, currentPage } = UsePaginatorContext();
  const { t } = useTranslation("common");

  switch (currentPage) {
    case 1:
      setPageTitle(t("common:name"));
      return <ProducerName />;
    case 2:
      setPageTitle(t("common:description"));
      return <DescriptionTemplate />;
    case 3:
      setPageTitle(t("common:address"));
      return <AddressTemplate />;
    case 4:
      setPageTitle(t("common:credentials"));
      return <EmailCompany />;
    case 5:
      setPageTitle(t("common:logo"));
      return <LogoTemplate />;
    case 6:
      setPageTitle(t("common:photos"));
      return <ImageTemplate />;
    case 7:
      setPageTitle(t("common:infos"));
      return <OtherData />;
    case 8:
      setPageTitle(t("common:values"));
      return <Values />;
    default:
      return <></>;
  }
};

const RegisterCorporation = () => {
  const { pageTitle, currentPage } = UsePaginatorContext();
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  return (
    <div className="registerProducersWrapper">
      {currentPage === 0 ? (
        <FirstPage />
      ) : (
        <>
          <div
            style={{ backgroundColor: colorBackground }}
            className="display-flex flex-direction-column align-items-center wrap title-wrapper"
          >
            <p className="medium-font text-color-producers mobile-title">
              BIZalp
            </p>
            <div className="big-font text-color-producers title-position">
              {pageTitle}
            </div>
          </div>
          <div className="display-flex flex-direction-column align-items-center producer-content-position">
            <SwitchComponent />
          </div>
        </>
      )}
    </div>
  );
};

export default RegisterCorporation;
