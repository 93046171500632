import { primaryColor } from "../environment";
import lettuce_field from "../Assets/lettuce_field_philosophy.png";
import mappa from "../Assets/2019_carte_hautes_vallees02.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import "./BizalpArea.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";

const BizalpArea = () => {
  const { t } = useTranslation(["common", "philosophy"]);
  const [visible, setVisible] = useState(false);
  return (
    <>
      <img
        src={lettuce_field}
        alt="campo di lattuga"
        style={{ width: "100%" }}
      ></img>

      <div className="display-flex flex-direction-column justify-content-center align-items-center">
        <div
          className="big-font margin-title"
          style={{
            color: primaryColor,
          }}
        >
          {t("philosophy:BizalpAreaTitle")}
        </div>
        <div
          className="w-50 mobile-padding small-font"
          style={{ textAlign: "center", fontWeight: "400" }}
        >
          {t("philosophy:baSentencePhilosophy")}
        </div>
      </div>
      <div className="flex flex-row justify-content-center w-full">
        <Button
          className="general-button"
          label={t("common:discover")}
          onClick={() => setVisible(true)}
        />
        <Dialog
          header={t("philosophy:BizalpAreaTitle")}
          visible={visible}
          onHide={() => setVisible(false)}
          style={{ width: "25rem" }}
        >
          <Image src={mappa} alt="mappa" preview className="area-image"></Image>
        </Dialog>
      </div>
    </>
  );
};

export default BizalpArea;
