import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import {
  createImagesURLs,
  dropEvent,
  onDragOverFunction,
} from "../Functions/ImageFunctions";
import { ShowImages } from "../Producers/ImageTemplate/ShowImages";
import { UploadImages } from "../Producers/ImageTemplate/UploadImages";
import { showError, showSuccess } from "../Toast/Toast.functions";
import { DialogUploadFotoInterface } from "./DialogUploadFotoProps";
import AmazonImages from "../Producers/ImageTemplate/AmazonImages";
import { company, CompanyProfile } from "../../models/Company/company";
import { updateCompanyService } from "../../services/Company/CompanyServices";
import {
  getMultipleUrl,
  getUrlAndUploadFileMultiple,
} from "../../services/File/File";
import { useTranslation } from "react-i18next";
import { ImageInterface } from "../../types/generic.types";
import { LoadingImage } from "../LoadingImage/LoadingImage";

const DialogUploadImages = ({
  header,
  visible,
  toast,
  setVisible,
  amazonImages,
  setAmazonImages,
  companyProfile,
}: DialogUploadFotoInterface) => {
  const [numOfFiles, setNumOfFiles] = useState<number>(amazonImages.length);
  const [images, setImages] = useState<any[]>([]);
  const [loadingVisible, setLoadingVisible] = useState<boolean>(false);
  const [imagesUrls, setImagesUrls] = useState<any[]>([]);
  const { t } = useTranslation(["common", "imagesSentence", "common"]);
  const [companyTmp, setCompanyTmp] = useState<CompanyProfile | null>(
    companyProfile
  );
  const [buttonText, setButtonText] = useState(
    t("common:upload") ? t("common:upload") : "upload"
  );
  const [imagesToUpload, setImagesToUpload] = useState<ImageInterface[]>([]);

  useEffect(() => {
    createImagesURLs(images, setImagesUrls);
  }, [images]);

  const saveOnDB = (params: ImageInterface[]) => {
    if (!companyTmp)
      return showError(toast, t("common:uploadCompanyDataError"));

    const companyModificata: company = {
      _id: companyTmp.id,
      name: companyTmp.name,
      description: companyTmp.description,
      address: companyTmp.producerPosition,
      pictures: params,
      logo: companyTmp.producerLogo,
      email: companyTmp.producerEmail,
      payment: companyTmp.producerPayment,
      phone: companyTmp.producerPhone,
      vat: companyTmp.producerVat,
      website: companyTmp.producerWebSite,
      values: companyTmp.producerValues,
      companyType: companyTmp.producerType,
      users: companyTmp.producerUsers,
      products: companyTmp.producerProducts,
    };

    const companyProfileModified: CompanyProfile = {
      id: companyTmp.id,
      name: companyTmp.name,
      description: companyTmp.description,
      producerPosition: companyTmp.producerPosition,
      producerImages: params,
      producerLogo: companyTmp.producerLogo,
      producerEmail: companyTmp.producerEmail,
      producerPayment: companyTmp.producerPayment,
      producerPhone: companyTmp.producerPhone,
      producerVat: companyTmp.producerVat,
      producerWebSite: companyTmp.producerWebSite,
      producerValues: companyTmp.producerValues,
      producerType: companyTmp.producerType,
      producerUsers: companyTmp.producerUsers,
      producerProducts: companyTmp.producerProducts,
    };

    updateCompanyService(companyModificata)
      .then((res: any) => {
        if (res.status === 200) {
          showSuccess(toast, t("imagesSentence:imagesModifiedSuccess"));
          getMultipleUrl(companyModificata.pictures)
            .then((result) => {
              setCompanyTmp(companyProfileModified);
              setAmazonImages(result);
              setLoadingVisible(false);
              setImagesToUpload([]);
            })
            .catch(() =>
              showError(toast, t("imagesSentence:imagesModifiedError"))
            );
        } else showError(toast, t("imagesSentence:imagesModifiedError"));
      })
      .catch(() => showError(toast, t("imagesSentence:imagesModifiedError")));
  };

  const saveOnAmazon = async () => {
    setLoadingVisible(true);
    getUrlAndUploadFileMultiple(images).then((imageUploaded) => {
      if (companyTmp) {
        setImages([]);
        setImagesUrls([]);
        companyTmp.producerImages.forEach((image) =>
          imagesToUpload.push(image)
        );
        imageUploaded.forEach((img) => {
          imagesToUpload.push({ key: img.key, filename: img.filename });
        });

        saveOnDB(imagesToUpload);
      } else showError(toast, t("common:uploadCompanyDataError"));
    });
  };

  return (
    <Dialog
      visible={visible}
      header={header}
      closable={amazonImages.length > 0}
      onHide={() => setVisible(false)}
      footer={
        images.length > 0 ? (
          <Button className="general-button" onClick={saveOnAmazon}>
            {buttonText}
          </Button>
        ) : (
          <></>
        )
      }
    >
      <div
        id="div-card-image"
        className="card-image"
        onDrop={(e) =>
          dropEvent(
            e,
            toast,
            numOfFiles,
            setNumOfFiles,
            images,
            setImages,
            5,
            t
          )
        }
        onDragOver={onDragOverFunction}
        draggable={true}
      >
        {amazonImages.length > 0 && numOfFiles > 0 ? (
          <AmazonImages
            amazonUrls={amazonImages}
            setAmazonUrls={setAmazonImages}
            producerImages={undefined}
            setProducerImages={undefined}
            numOfFiles={numOfFiles}
            setNumOfFiles={setNumOfFiles}
            buttonText={buttonText}
            setButtonText={setButtonText}
            toastError={toast}
            saveOnDB={saveOnDB}
            modifyImage={true}
          />
        ) : (
          <></>
        )}
        {images.length > 0 ? (
          <ShowImages
            images={images}
            setImages={setImages}
            imagesUrls={imagesUrls}
            setImagesUrls={setImagesUrls}
            numOfFiles={numOfFiles}
            setNumOfFiles={setNumOfFiles}
          />
        ) : (
          <></>
        )}
        {(amazonImages.length > 0 && amazonImages.length < 5) ||
        (images.length > 0 && images.length < 5) ? (
          <UploadImages
            toastError={toast}
            numberFile={5}
            numOfFiles={numOfFiles}
            setNumOfFiles={setNumOfFiles}
            images={images}
            setImages={setImages}
            firstSentence={""}
            secondSentence={""}
            thirdSentence={
              5 - numOfFiles > 0
                ? `${t("imagesSentence:uploadOtherImages")} ${
                    5 - numOfFiles
                  } ${t("imagesSentence:images")}`
                : ""
            }
            showImage={false}
          />
        ) : images.length === 0 && amazonImages.length === 0 ? (
          <UploadImages
            toastError={toast}
            numberFile={5}
            numOfFiles={numOfFiles}
            setNumOfFiles={setNumOfFiles}
            images={images}
            setImages={setImages}
            firstSentence={`${t("imagesSentence:firstSentence")}`}
            secondSentence={`${t("imagesSentence:secondSentence")} 5 ${t(
              "imagesSentence:images"
            )}`}
            thirdSentence={`${t("imagesSentence:thirdSentence")}`}
            showImage={true}
          />
        ) : (
          <></>
        )}
      </div>
      <LoadingImage visible={loadingVisible} />
    </Dialog>
  );
};

export default DialogUploadImages;
