import { Card } from "primereact/card";
import ProductCardImagesNavigator from "./ProductCardCarousel/ProductCardCarousel";
import { ProductListType } from "../ProductsList/ProductsList";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { company } from "../../../../models/Company/company";
import "./ProductCardCarousel/ProductCardCarousel.css";
import "./ProductCard.css";
import { Button } from "primereact/button";
import DialogInfo from "./DialogProductCard/DialogInfo";
import { DialogBuyProduct } from "./DialogProductCard/DialogBuyProduct";
import { showInfo } from "../../../Toast/Toast.functions";
import DialogModifyProduct from "./DialogModifyProduct/DialogModifyProduct";
import { useTranslation } from "react-i18next";

interface IProps {
  product: ProductListType;
  company?: company;
  shop?: boolean;
}

const ProductCard = (props: IProps) => {
  const toast = useRef<Toast>(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [showBuyDialog, setShowBuyDialog] = useState(false);
  const [showModifyDialog, setShowModifyDialog] = useState(false);
  const isSuperUser = window.sessionStorage.getItem("superUser") ? true : false;
  const { t } = useTranslation("common");

  useEffect(() => {
    if (props.shop) {
      const classBody: HTMLCollection =
        document.getElementsByClassName("p-card-body");
      if (classBody && classBody.length > 0)
        for (let x = 0; x < classBody.length; x++)
          classBody[x].className = "p-card-body-custom";
    }
  }, []);

  return (
    <>
      <Toast ref={toast} position="top-right"></Toast>
      <Card
        title={props.product.productType}
        className="product-card-style"
        header={<ProductCardImagesNavigator images={props.product.images} />}
      >
        {props.shop ? (
          <div
            className="display-flex flex-direction-column justify-content-center align-items-center"
            style={{ width: "100%" }}
          >
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
              {props.product.productCategory}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Button
                className="general-button"
                onClick={() => setShowInfoDialog(true)}
              >
                {t("common:information")}
              </Button>
              <Button
                className="yellow-button"
                onClick={() => {
                  if (!window.sessionStorage.getItem("idToken"))
                    showInfo(toast, t("common:loginBeforeBuy"));
                  else setShowBuyDialog(true);
                }}
              >
                {t("common:buy")}
              </Button>
            </div>
          </div>
        ) : (
          <p className="card-description">{props.product.productDescription}</p>
        )}
        {isSuperUser ? (
          <Button
            className="general-button mt-2"
            onClick={() => setShowModifyDialog(true)}
          >
            {t("common:modifyProduct")}
          </Button>
        ) : (
          <></>
        )}
      </Card>
      {props.product && showInfoDialog ? (
        <DialogInfo
          showInfoDialog={showInfoDialog}
          setShowInfoDialog={setShowInfoDialog}
          product={props.product}
        />
      ) : (
        <></>
      )}
      {props.product && showBuyDialog ? (
        <DialogBuyProduct
          showBuyDialog={showBuyDialog}
          setShowBuyDialog={setShowBuyDialog}
          product={props.product}
        />
      ) : (
        <></>
      )}
      {isSuperUser && showModifyDialog ? (
        <DialogModifyProduct
          setShowModifyDialog={setShowModifyDialog}
          showModifyDialog={showModifyDialog}
          product={props.product}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProductCard;
