import { Link } from "react-router-dom";
import { primaryColor } from "../environment";
import signup_image from "../Assets/contadino_filosofia.png";
import { useTranslation } from "react-i18next";

const SignUpPhilosphy = () => {
  const { t } = useTranslation(["common", "philosophy"]);

  return (
    <>
      <div className="display-flex flex-direction-column justify-content-center align-items-center">
        <div
          className="big-font margin-title mobile-padding"
          style={{
            color: primaryColor,
            textAlign: "center",
          }}
        >
          {t("philosophy:signupPhilosophy")}
        </div>
        <div
          className="w-50 small-font mobile-padding"
          style={{ textAlign: "center", fontWeight: 400 }}
        >
          {t("philosophy:PhilosophySignupFirstSentence")}
          <br />
          {t("philosophy:PhilosophySignupSecondSentence")}
        </div>
        <Link
          to={"/company"}
          className="small-font no-decoration general-button"
          style={{ marginTop: "2rem", color: "#fff" }}
        >
          {t("common:discoverProducers")}
        </Link>
        <Link
          to={"/products"}
          className="small-font no-decoration general-button"
          style={{ marginTop: "2rem", color: "#fff" }}
        >
          {t("common:discoverProducts")}
        </Link>
        <div
          className="display-flex justify-content-center"
          style={{ marginTop: "1rem" }}
        >
          <img
            src={signup_image}
            alt="campo di lattuga"
            className="sell-veg-image-size"
            style={{ width: "50%" }}
          ></img>
        </div>
      </div>
    </>
  );
};

export default SignUpPhilosphy;
