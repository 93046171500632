import { Image } from "primereact/image";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import deleteImage from "../../Assets/delete.png";
import { showError, showSuccess } from "../../Toast/Toast.functions";
import { ImageInterface, ImageWithURL } from "../../../types/generic.types";
import { deleteFile } from "../../../services/File/File";
import { useTranslation } from "react-i18next";

export interface AmazonLogoInterface {
  amazonUrls: ImageWithURL;
  setAmazonUrls: Dispatch<SetStateAction<ImageWithURL>>;
  setAmazonData: Dispatch<SetStateAction<ImageInterface>>;
  producerImages?: ImageInterface;
  setProducerImages?: Dispatch<SetStateAction<ImageInterface>>;
  numOfFiles: number;
  setNumOfFiles: Dispatch<SetStateAction<number>>;
  buttonText: string;
  setButtonText: Dispatch<SetStateAction<string>>;
  toastError: MutableRefObject<any>;
  saveOnDB?: any;
}

const AmazonLogo = ({
  amazonUrls,
  setAmazonUrls,
  setAmazonData,
  setProducerImages,
  setNumOfFiles,
  setButtonText,
  buttonText,
  toastError,
}: AmazonLogoInterface) => {
  const { t } = useTranslation(["imagesSentence"]);

  const deleteLogoOnAmazon = () => {
    const body = {
      key: amazonUrls.key,
    };
    deleteFile(body).then((res) => {
      if (res.status === 200) {
        if (buttonText === "next") setButtonText("upload");
        if (setProducerImages) setProducerImages({ filename: "", key: "" });
        setAmazonUrls({ url: "", filename: "", key: "" });
        setAmazonData({ key: "", filename: "" });
        setNumOfFiles(0);
        showSuccess(toastError, t("imagesSentence:deleteLogoSuccess"));
      } else showError(toastError, t("imagesSentence:deleteLogoError"));
    });
  };

  return (
    <div className="image-template-card display-flex justify-content-space-between align-items-center">
      <Image
        src={amazonUrls.url}
        alt="immagine"
        preview
        className="dialog-images"
      />
      <img
        src={deleteImage}
        alt="delete"
        style={{ width: "2rem", height: "2rem" }}
        onClick={deleteLogoOnAmazon}
      />
    </div>
  );
};

export default AmazonLogo;
