import "./MapView.css";
import { MarkerCompany } from "./MarkerCompany";
import { company } from "../../../../../models/Company/company";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../../../environment";

const Markers = ({ company }: { company: company[] }) => {
  return (
    <>
      {company.map((company, index) => {
        return <MarkerCompany company={company} key={index} />;
      })}
    </>
  );
};

const MapView = ({ company }: { company: company[] }) => {
  const { currentLocation, zoom } = {
    currentLocation: { lat: 45.0, lng: 7 },
    zoom: 10,
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={["places"]}>
      <GoogleMap
        center={currentLocation}
        zoom={zoom}
        mapContainerStyle={{ height: "93vh", zIndex: "1" }}
      >
        <Markers company={company} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapView;
