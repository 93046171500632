import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { primaryColor } from "../environment";

import "./CookiePolicy.css";

const CookiePolicy = () => {
  const { t } = useTranslation(["cookie"]);

  useEffect(() => {
    document.getElementById("top-page")?.scrollIntoView();
  }, []);

  return (
    <div className="flex flex-column align-items-center gap-6 pb-6">
      <div className="cookie-policy-page">
        <div
          id="top-page"
          className="cookie-policy-title"
          style={{
            color: primaryColor,
          }}
        >
          {t("cookie:title")}
        </div>
        <div>
          {t("cookie:firstSentence")}
          <br />
          {t("cookie:secondSentence")}
        </div>
        {/* Primo capitoletto */}
        <>
          <div
            className="cookie-policy-title"
            style={{
              color: primaryColor,
            }}
          >
            1. {t("cookie:titleFirstChapter")}
          </div>
          <div>
            <span className="subtitle">
              1.1 {t("cookie:firstSubtitleFirstChapter")}
            </span>
            <p>
              <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
                www.bizalp.org
              </a>{" "}
              {t("cookie:firstSentenceFirstChapter")}
              <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
                www.bizalp.org
              </a>
              {t("cookie:secondSentenceFirstChapter")}
            </p>
            <span className="subtitle">
              1.2 {t("cookie:secondSubtitleFirstChapter")}
            </span>
            <p>
              <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
                www.bizalp.org
              </a>{" "}
              {t("cookie:thirdSentenceFirstChapter")}
            </p>
          </div>
        </>
        {/* Secondo capitolo */}
        <>
          <div
            className="cookie-policy-title"
            style={{
              color: primaryColor,
            }}
          >
            2. {t("cookie:titleSecondChapter")}
          </div>
          <span>
            {t("cookie:firstSentenceSecondChapter")}
            <br />
            {t("cookie:secondSentenceSecondChapter")}
            <ul>
              <li style={{ marginBottom: "0.5rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  {t("cookie:secondChapterList1")}
                </div>
                <p>
                  <div>
                    {t("cookie:secondChapterList1sub1")}
                    <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
                      www.bizalp.org
                    </a>
                    {t("cookie:secondChapterList1sub2")}
                  </div>
                  <br />
                  <div style={{ fontWeight: "bold" }}>
                    {t("cookie:secondChapterList1sub3")}
                  </div>
                  <br />
                  <div>{t("cookie:secondChapterList1sub4")}</div>
                  <br />
                  <div>{t("cookie:secondChapterList1sub5")}</div>
                  <br />
                  <div>{t("cookie:secondChapterList1sub6")}</div>
                  <br />
                  <div>{t("cookie:secondChapterList1sub7")}</div>
                </p>
              </li>
              <li style={{ marginBottom: "0.5rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  {t("cookie:secondChapterList2")}
                </div>
                <p>
                  <div>{t("cookie:secondChapterList2sub1")}</div>
                  <br />
                  <div style={{ fontWeight: "bold" }}>
                    {t("cookie:secondChapterList2sub2")}
                  </div>
                  <br />
                  <div>
                    {t("cookie:secondChapterList2sub3")}
                    <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
                      www.bizalp.org
                    </a>
                    {t("cookie:secondChapterList2sub4")}
                  </div>
                  <br />
                  <div>{t("cookie:secondChapterList2sub5")}</div>
                  <br />
                  <div>{t("cookie:secondChapterList2sub6")}</div>
                  <br />
                  <div>{t("cookie:secondChapterList2sub7")}</div>
                </p>
              </li>
              <li style={{ marginBottom: "0.5rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  {t("cookie:secondChapterList3")}
                </div>
                <p>
                  <div>{t("cookie:secondChapterList3sub1")}
                  <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
                    www.bizalp.org
                  </a>
                  {t("cookie:secondChapterList3sub2")}</div>
                  <br />
                  <div>{t("cookie:secondChapterList3sub3")}</div>
                  <br />
                  <div style={{ fontWeight: "bold" }}>
                    {t("cookie:secondChapterList3sub4")}
                  </div>
                  <br />
                  <div>{t("cookie:secondChapterList3sub5")}
                  <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
                    www.bizalp.org
                  </a>
                  {t("cookie:secondChapterList3sub6")}</div>
                  <br />
                  <div>{t("cookie:secondChapterList3sub7")}</div>
                  <br />
                  <div>{t("cookie:secondChapterList3sub8")}</div>
                </p>
              </li>
            </ul>
          </span>
        </>
        {/* Terzo capitolo */}
        <>
          <div
            className="cookie-policy-title"
            style={{
              color: primaryColor,
            }}
          >
            3. {t("cookie:titleThirdChapter")}
          </div>
          <span>
            <div>{t("cookie:firstSentenceThirdChapter")}</div>
            <br />
            <div>{t("cookie:secondSentenceThirdChapter")}</div>
            <br />
            <div>{t("cookie:thirdSentenceThirdChapter")}</div>
            <br />
            <div>{t("cookie:fourthSentenceThirdChapter")}</div>
            <br />
          </span>
        </>
        {/* Quarto capitolo */}
        <>
          <div
            className="cookie-policy-title"
            style={{
              color: primaryColor,
            }}
          >
            4. {t("cookie:titleFourthChapter")}
          </div>
          <span>
            <div>{t("cookie:firstSentenceFourthChapter")}</div>
            <br />
            <div>{t("cookie:secondSentenceFourthCapter")}</div>
            <br />
            <div>{t("cookie:data1")}</div>
            <br />
            <div>{t("cookie:data2")}</div>
            <br />
            <div>{t("cookie:data3")}</div>
            <br />
            <div>{t("cookie:data4")}</div>
            <br />
            <div>{t("cookie:data5")}</div>
            <br />
            <div>{t("cookie:data6")}</div>
            <br />
            <div>{t("cookie:data7")}</div>
            <br />
            <div>{t("cookie:thirdSentenceFourthChapter")}
            <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
              www.bizalp.org
            </a>
            {t("cookie:fourthSentenceFourthChapter")}</div>
            <br />
            <div>{t("cookie:fifthSentenceFourthChapter")}
            <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
              www.bizalp.org
            </a>
            </div>
          </span>
        </>
        {/* Quinto capitolo */}
        <>
          <div
            className="cookie-policy-title"
            style={{
              color: primaryColor,
            }}
          >
            5. {t("cookie:titleFifthChapter")}
          </div>
          <span>
            <div style={{ fontWeight: "bold" }}>
              {t("cookie:fifthChapterList1")}
            </div>
            <br />
            <div>{t("cookie:fifthChapterList1sub1")}</div>
            <br />
            <div style={{ fontWeight: "bold" }}>
              {t("cookie:fifthChapterList2")}
            </div>
            <br />
            <div>{t("cookie:fifthChapterList2sub1")}
            <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
              www.bizalp.org
            </a>
            {t("cookie:fifthChapterList2sub2")}
            <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
              www.bizalp.org
            </a>
            </div>
            <div>{t("cookie:fifthChapterList2sub3")}
            <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
              www.bizalp.org
            </a>
            {t("cookie:fifthChapterList2sub4")}</div>
            <br />
            <div style={{ fontWeight: "bold" }}>
              {t("cookie:fifthChapterList3")}
            </div>
            <br />
            <div>{t("cookie:fifthChapterList3sub1")}
            <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
              www.bizalp.org
            </a>
            {t("cookie:fifthChapterList3sub2")}</div>
            <br />
            <div style={{ fontWeight: "bold" }}>
              {t("cookie:fifthChapterList4")}
            </div>
            <br />
            <div>{t("cookie:fifthChapterList4sub1")}</div>
            <br />
            <div style={{ fontWeight: "bold" }}>
              {t("cookie:fifthChapterList5")}
            </div>
            <br />
            <div>{t("cookie:fifthChapterList5sub1")}</div>
            <br />
            <div style={{ fontWeight: "bold" }}>
              {t("cookie:fifthChapterList6")}
            </div>
            <br />
            <div>{t("cookie:fifthChapterList6sub1")}
            <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
              www.bizalp.org
            </a>
            </div>
            <br />
            <div style={{ fontWeight: "bold" }}>
              {t("cookie:fifthChapterList7")}
            </div>
            <br />
            <div>{t("cookie:fifthChapterList7sub1")}</div>
            <br />
            <div style={{ fontWeight: "bold" }}>
              {t("cookie:fifthChapterList8")}
            </div>
            <br />
            <div>{t("cookie:fifthChapterList8sub1")}
            <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
              www.bizalp.org
            </a>
            {t("cookie:fifthChapterList8sub2")}</div>
            <br />
            <div style={{ fontWeight: "bold" }}>
              {t("cookie:fifthChapterList9")}
            </div>
            <br />
            <div>{t("cookie:fifthChapterList9sub1")}</div>
            <br />
            <div style={{ fontWeight: "bold" }}>
              {t("cookie:fifthChapterList10")}
            </div>
            <br />
            <div>{t("cookie:fifthChapterList10sub1")}</div>
            <br />
          </span>
        </>
        {/* Sesto capitolo */}
        <>
          <div
            className="cookie-policy-title"
            style={{
              color: primaryColor,
            }}
          >
            6. {t("cookie:titleSixthChapter")}
          </div>
          <span>
            <div>{t("cookie:firstSentenceSixthChapter")}</div>
            <br />
            <div>{t("cookie:secondSentenceSixthChapter")}
            <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
              www.bizalp.org
            </a>
            </div>
          </span>
        </>
      </div>
    </div>
  );
};

export default CookiePolicy;
