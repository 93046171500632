import axios from "axios";
import { checkProduct } from "../../component/ProductsPage/componentsProducts/ProductsList/ProductsList";
import { product } from "../../models/Product/product";
import { ImageInterface, ImageWithURL } from "../../types/generic.types";
import { API_BASE_URL } from "../env";
import {
  DELETE_FILE,
  GET_GET_URL_FILE,
  GET_GET_URL_FILE_PRODUCTS,
  GET_URL_FILE,
  GET_URL_FILE_PRODUCTS,
  UPLOAD_FILE,
} from "./api";

let cacheUrl: Record<string, any> = {};

const getDate = (date: any) => {
  return (
    new Date(date.setMinutes(new Date(date).getMinutes() + 12)) < new Date()
  );
};

export const getUrlFile = async (requestBody?: any) => {
  let server = API_BASE_URL + GET_URL_FILE.path;
  let body = requestBody ? requestBody : GET_URL_FILE.requestBody;
  return await axios(server, {
    method: GET_URL_FILE.method,
    data: body,
  });
};

export const getGetUrlFile = async (requestBody: string) => {
  if (cacheUrl[requestBody] != null && getDate(cacheUrl[requestBody].createdAt))
    return cacheUrl[requestBody];
  else {
    let server = API_BASE_URL + GET_GET_URL_FILE.path + "/?key=" + requestBody;
    return new Promise((resolve, reject) => {
      axios(server, {
        method: GET_GET_URL_FILE.method,
        validateStatus: (status) => true,
      })
        .then((res) => {
          if (res.status === 200) {
            cacheUrl[requestBody] = { url: res.data, createdAt: new Date() };
            resolve(res.data);
          }
        })
        .catch((error) => console.trace(error));
    });
  }
};

export const getUrlFileProducts = async (requestBody?: any) => {
  let server = API_BASE_URL + GET_URL_FILE_PRODUCTS.path;
  let body = requestBody ? requestBody : GET_URL_FILE_PRODUCTS.requestBody;
  return await axios(server, {
    method: GET_URL_FILE_PRODUCTS.method,
    data: body,
  }).then((res) => {
    return res;
  });
};

export const getGetUrlFileProducts = async (requestBody: string) => {
  let server =
    API_BASE_URL + GET_GET_URL_FILE_PRODUCTS.path + "/?key=" + requestBody;
  if (
    cacheUrl[requestBody] != null &&
    getDate(cacheUrl[requestBody].createdAt)
  ) {
    return cacheUrl[requestBody];
  } else {
    return new Promise((resolve, reject) => {
      axios(server, {
        method: GET_GET_URL_FILE_PRODUCTS.method,
      })
        .then((res) => {
          if (res.status === 200) {
            cacheUrl[requestBody] = {
              url: res.data,
              createdAt: new Date(),
            };
            resolve(res.data);
          }
        })
        .catch((error) =>
          setTimeout(() => {
            if (error.code === "ERR_NETWORK")
              resolve(getGetUrlFileProducts(requestBody));
          }, 1000)
        );
    });
  }
};

export const uploadFile = async (requestBody?: any) => {
  let server = requestBody ? requestBody.url : "";
  let body = requestBody ? requestBody.file : UPLOAD_FILE.requestBody;
  return await axios(server, {
    method: UPLOAD_FILE.method,
    data: body,
  });
};

export const deleteFile = async (requestBody?: any) => {
  let server = API_BASE_URL + DELETE_FILE.path;
  let body = requestBody ? requestBody : DELETE_FILE.requestBody;
  return await axios(server, {
    method: DELETE_FILE.method,
    data: body,
  });
};

/*--------------------------------------- 
|    FUNZIONI PER PRENDERE IMMAGINI     |   
-------------------------------------- */

export const getUrlAndUploadFile = async (filename: any, file: File) => {
  return getUrlFile({ filename: filename }).then((resultGetUrl) => {
    if (resultGetUrl.status === 200) {
      const uploadObject = {
        url: resultGetUrl.data,
        file: file,
      };
      return uploadFile(uploadObject).then((resUpload: any) => {
        if (resUpload.status === 200) {
          const key = uploadObject.url.split("?")[0].split("public/")[1];
          return getGetUrlFile(key).then((res) => {
            return {
              key: key,
              url: res,
            };
          });
        } else throw new Error("");
      });
    } else throw new Error("");
  });
};

export const getMultipleUrl = async (images: ImageInterface[]) => {
  const amazonImages = images.map(async (data) => {
    return getGetUrlFile(data.key).then((res) => {
      return {
        url: res,
        key: data.key,
        filename: data.filename,
      };
    });
  });

  return Promise.all(amazonImages);
};

export const getMultipleUrlFromCompanies = async (companies: any[]) => {
  const allCompanies = companies.map(async (company) => {
    const images = await getMultipleUrl(company.images);
    company.images = images;
    return company;
  });

  return Promise.all(allCompanies).then((company) => company);
};

export const getUrlAndUploadFileMultiple = async (file: File[]) => {
  const amazonImages = [];
  for (let i in file) {
    amazonImages.push(
      getUrlAndUploadFile(file[i].name, file[i]).then((result: any) => {
        return {
          key: result.key,
          url: result.url,
          filename: file[i].name,
        };
      })
    );
  }
  return Promise.all(amazonImages).then((image: ImageWithURL[]) => image);
};

export const getUrlAndUploadProductFile = async (filename: any, file: File) => {
  return getUrlFileProducts({ filename: filename }).then((resultGetUrl) => {
    if (resultGetUrl.status === 200) {
      const uploadObject = {
        url: resultGetUrl.data,
        file: file,
      };
      return uploadFile(uploadObject).then((resUpload: any) => {
        if (resUpload.status === 200) {
          const key = uploadObject.url
            .split("?")[0]
            .split("productsImages/")[1];
          return getGetUrlFile(key).then((res) => {
            return {
              key: key,
              url: res,
            };
          });
        } else throw new Error("");
      });
    } else throw new Error("");
  });
};

export const getUrlAndUploadProductFileMultiple = async (file: File[]) => {
  const amazonImages = [];
  for (let i in file) {
    amazonImages.push(
      getUrlAndUploadProductFile(file[i].name, file[i]).then((result: any) => {
        return {
          key: result.key,
          url: result.url,
          filename: file[i].name,
        };
      })
    );
  }
  return Promise.all(amazonImages).then((image: ImageWithURL[]) => image);
};

export const getMultipleProductsUrl = async (images: ImageInterface[]) => {
  const amazonImages = images.map(async (data) => {
    return getGetUrlFileProducts(data.key).then((res) => {
      return {
        url: res,
        key: data.key,
        filename: data.filename,
      };
    });
  });

  return Promise.all(amazonImages).then((image: ImageWithURL[]) => image);
};

export const getMultipleProductsUrlFromCompany = async (
  products: product[]
) => {
  const productsAvailable = products.map(async (product) => {
    return getMultipleProductsUrl(product.picture).then((resImages) => {
      product.picture = resImages;
      return product;
    });
  });

  return Promise.all(productsAvailable);
};

export const getMultipleImagesFromProduct = async (
  products: any,
  searchValue: string
) => {
  return Promise.all(
    products.map((prod: product) => {
      if (checkProduct(prod, searchValue)) {
        return getMultipleProductsUrl(prod.picture).then((image) => {
          return {
            id: prod.id,
            productCategory: prod.category.name,
            productDescription: prod.description,
            productType: prod.type.name,
            images: image,
            link: prod.link ? prod.link : "",
          };
        });
      }
    })
  ).then((productImages) =>
    productImages.filter((productImage) => productImage != null)
  );
};

export const getUrlFromComments = (comments: any) => {
  const allComment = comments.map(async (comment: any) => {
    let res = undefined;
    if (comment.idCompany != null) {
      res = await getGetUrlFile(comment.idCompany.logo.key);
    }
    return {
      id: comment.id,
      text: comment.comment,
      image: {
        key: comment.idCompany ? comment.idCompany.logo.key : undefined,
        filename: comment.idCompany
          ? comment.idCompany.logo.filename
          : undefined,
        url: res,
      },
      idCompany: comment.idCompany ? comment.idCompany.id : undefined,
      name: comment.idCompany ? comment.idCompany.name : undefined,
    };
  });

  return Promise.all(allComment);
};
