export const GET_URL_FILE = {
  path: "files/getUrl",
  method: "PUT",
  requestBody: {
    filename: "",
  },
};

export const GET_GET_URL_FILE = {
  path: "files/getUrl",
  method: "GET",
  requestBody: {
    key: "",
  },
};

export const GET_URL_FILE_PRODUCTS = {
  path: "files/getUrlProducts",
  method: "PUT",
  requestBody: {
    filename: "",
  },
};

export const GET_GET_URL_FILE_PRODUCTS = {
  path: "files/getUrlProducts",
  method: "GET",
  requestBody: {
    key: "",
  },
};

export const UPLOAD_FILE = {
  method: "PUT",
  requestBody: {
    url: "Jhon",
    file: "File",
  },
};

export const DELETE_FILE = {
  path: "files/deleteFile",
  method: "DELETE",
  requestBody: {
    key: "1234",
  },
};
