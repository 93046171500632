export const showError = (toast: any, detail: string) => {
  toast.current!.show({
    severity: "error",
    summary: "Error Message",
    detail: detail,
    life: 3000,
  });
};

export const showSuccess = (toast: any, detail: string) => {
  toast.current!.show({
    severity: "success",
    summary: "Success Message",
    detail: detail,
    life: 3000,
  });
};

export const showInfo = (toast: any, detail: string) => {
  toast.current!.show({
    severity: "info",
    summary: "Info Message",
    detail: detail,
    life: 3000,
  });
};
