import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteCompanyService } from "../../../services/Company/CompanyServices";
import { deleteUserService } from "../../../services/User/UsersServices";
import { FilterMatchMode } from "primereact/api";

interface UserListDialogProps {
  allUsers: any;
  setAllUsers: Dispatch<SetStateAction<any>>;
  userList: boolean;
  setUserList: Dispatch<SetStateAction<boolean>>;
}

const UserListDialog = ({
  allUsers,
  setAllUsers,
  userList,
  setUserList,
}: UserListDialogProps) => {
  const { t } = useTranslation(["common", "signin"]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState<{
    global: { value: string | null; matchMode: FilterMatchMode.CONTAINS };
  }>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [selectedUser, setSelectedUser] = useState<any>();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const userDeleteAdmin = () => {
    deleteUserService({ _id: selectedUser._id })
      .then((resUser: any) => {
        if (resUser.status === 200) {
          deleteCompanyService({
            _id: selectedUser.companies[0]._id,
          }).then((resUser: any) => {
            setConfirmDelete(false);
            setSelectedUser(undefined);
            if (resUser.status === 200) {
              setAllUsers(
                allUsers.filter((e: any) => e._id !== selectedUser._id)
              );
            }
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const onGlobalFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <>
      <Dialog onHide={() => setUserList(false)} visible={userList}>
        <DataTable
          value={allUsers}
          className="p-datatable-customers"
          header={header}
          rows={10}
          dataKey="email"
          filters={filters}
          filterDisplay="menu"
          responsiveLayout="scroll"
          globalFilterFields={["name", "email"]}
          emptyMessage="Non sono presenti utenti"
        >
          <Column field="name" header="Name" />
          <Column field="email" header="Email" />
          <Column
            field="delete"
            body={(rowData) => (
              <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-warning"
                onClick={(e) => {
                  setSelectedUser(rowData);
                  setConfirmDelete(true);
                }}
              />
            )}
          ></Column>
        </DataTable>
      </Dialog>

      <Dialog
        onHide={() => setConfirmDelete(false)}
        visible={confirmDelete}
        closable={false}
        style={{ maxWidth: "50vh" }}
        footer={
          <div className="flex justify-content-space-between">
            <Button
              className="general-button"
              onClick={() => {
                setConfirmDelete(false);
                setSelectedUser(undefined);
              }}
            >
              {t("common:No")}
            </Button>
            <Button className="general-button" onClick={userDeleteAdmin}>
              {t("common:Yes")}
            </Button>
          </div>
        }
      >
        {t("common:confirmDeleteUser") + selectedUser?.name}
      </Dialog>
    </>
  );
};
export default UserListDialog;
