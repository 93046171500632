export const LOGIN = {
  path: "cognito/login",
  method: "POST",
  requestBody: {
    email: "",
    password: "",
  },
};

export const GETUSER = {
  path: "cognito/getUser",
  method: "POST",
  requestBody: {
    email: "",
    password: "",
  },
};

export const CONFIRMREGISTRATION = {
  path: "cognito/confirmRegistration",
  method: "POST",
  requestBody: {
    email: "",
    verifyCode: "",
  },
};

export const CONFIRMATIONCODE = {
  path: "cognito/resendConfirmationCode",
  method: "POST",
  requestBody: {
    email: "",
  },
};

export const FORGOTPASSWORD = {
  path: "cognito/forgotPasswordApi",
  method: "POST",
  requestBody: {
    email: "",
  }
};

export const RESETPASSWORD = {
  path: "cognito/confirmPasswordApi",
  method: "POST",
  requestBody: {
    email: "",
    code: "", 
    password: ""
  }
};

export const DELETEUSER = {
  path: "cognito/deleteUser",
  method: "POST",
  requestBody: {
    email:"",
    password:""
  }
}