import "./i18n";
import "./index.css";
import "../node_modules/primeflex/primeflex.css";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, Route, Routes } from "react-router-dom";
import SignIn from "./component/SignIn/SignIn";
import ConfirmRegistration from "./component/ConfirmRegistration/ConfirmRegistration";
import TypeCompany from "./component/SignUp/TypeCompany";
import Layout from "./component/Layout/Layout";
import RegisterCorporation from "./component/Corporation/RegisterCorporation";
import PaginatorProvider from "./component/Context/PagesContext";
import ProducerProvider from "./component/Context/ProducersContext";
import RegisterProducers from "./component/Producers/RegisterProducers";
import AddProduct from "./component/Product/AddProduct";
import ProductProvider from "./component/Context/ProductContext";
import ProductsMap from "./component/ProductsPage/componentsProducts/ProductsMap/ProductsMap";
import RecoverPassword from "./component/RecoverPassword/RecoverPassword";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HashRouter>
    <Routes>
      <Route path="/*" element={<Layout />} />
      <Route path="signin" element={<SignIn />} />
      <Route path="signup" element={<TypeCompany />} />
      <Route path="company" element={<ProductsMap />} />
      <Route
        path="signin/recoverPassword/:type"
        element={<RecoverPassword />}
      />
      <Route
        path="signup/confirmRegistration"
        element={<ConfirmRegistration />}
      />
      <Route
        path="producers"
        element={
          <PaginatorProvider>
            <ProducerProvider>
              <RegisterProducers />
            </ProducerProvider>
          </PaginatorProvider>
        }
      />
      <Route
        path="corporation"
        element={
          <PaginatorProvider>
            <ProducerProvider>
              <RegisterCorporation />
            </ProducerProvider>
          </PaginatorProvider>
        }
      />
      <Route
        path="addproduct"
        element={
          <PaginatorProvider>
            <ProductProvider>
              <AddProduct />
            </ProductProvider>
          </PaginatorProvider>
        }
      />
    </Routes>
  </HashRouter>
);

reportWebVitals();
