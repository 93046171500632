import { axiosWithAuth, axiosWithUserId } from "../../axiosWithAuth";
import { API_BASE_URL } from "../../env";
import {
  EP_CREATE_PRODUCT_CATEGORY,
  EP_DELETE_PRODUCT_CATEGORY,
  EP_GET_ALL_PRODUCT_CATEGORIES,
  EP_SEARCH_PRODUCT_CATEGORY,
  EP_UPDATE_PRODUCT_CATEGORY,
} from "./api";

export const getAllProductCategoriesService = async () => {
  let server = API_BASE_URL + EP_GET_ALL_PRODUCT_CATEGORIES.path;
  return await axiosWithAuth(server, {
    method: EP_GET_ALL_PRODUCT_CATEGORIES.method,
  });
};

export const createProductCategoryService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_CREATE_PRODUCT_CATEGORY.path;
  let body = requestBody ? requestBody : EP_CREATE_PRODUCT_CATEGORY.requestBody;
  return await axiosWithUserId(server, {
    method: EP_CREATE_PRODUCT_CATEGORY.method,
    data: { ...body },
  });
};

export const deleteProductCategoryService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_DELETE_PRODUCT_CATEGORY.path;
  let body = requestBody ? requestBody : EP_DELETE_PRODUCT_CATEGORY.requestBody;
  return await axiosWithUserId(server, {
    method: EP_DELETE_PRODUCT_CATEGORY.method,
    data: { ...body },
  });
};

export const updateProductCategoryService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_UPDATE_PRODUCT_CATEGORY.path;
  let body = requestBody ? requestBody : EP_UPDATE_PRODUCT_CATEGORY.requestBody;
  return await axiosWithUserId(server, {
    method: EP_UPDATE_PRODUCT_CATEGORY.method,
    data: { ...body },
  });
};

export const searchProductCategoryService = async (params?: any) => {
  let server = API_BASE_URL + EP_SEARCH_PRODUCT_CATEGORY.path;
  let queryParams = params ? params : EP_SEARCH_PRODUCT_CATEGORY.params;
  return await axiosWithAuth(server, {
    method: EP_SEARCH_PRODUCT_CATEGORY.method,
    params: queryParams,
  });
};
