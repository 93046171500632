export const CREATE_NOTIFY = {
  path: "notify/createNotify",
  method: "POST",
  requestBody: {
    emailCustomer: "testemailcustomer@test.com",
    emailSupplier: "testemailsupplier@test.com",
    alreadySent: false, //false: email non ancora inviata, true: email inviata
    text: "text",
    customerName: "test",
    supplierName: "matteo",
  },
};
