import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  forgotPassword,
  resetPassword,
} from "../../services/Cognito/CognitoServices";
import { primaryColor } from "../environment";
import LoadingComponent from "../LoadingPage/LoadingComponent";
import "../General.css";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { showError } from "../Toast/Toast.functions";
import { searchUserServiceId } from "../../services/User/UsersServices";

interface InitialLoginValues {
  [index: string]: string;
  email: string;
  password: string;
}

const RecoverPassword = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["signin", "common", "error"]);
  const [sentCode, setSentCode] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const toastError = useRef<Toast>(null);
  const { type } = useParams<{ type: "recovery" | "change" }>();

  let errors;
  let navigate = useNavigate();

  useEffect(() => {
    if (type === "change") {
      searchUserServiceId({ _id: sessionStorage.getItem("userId") }).then(
        (res) => {
          setEmail(res.data.email);
        }
      );
    }
  });

  const formik = useFormik({
    initialValues: {
      email: email,
      password: "",
      code: "",
    } as InitialLoginValues,

    validate: (data: InitialLoginValues) => {
      errors = {} as Record<string, string>;

      if (!data.email) {
        errors.email = t("error:emailRequired");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = t("error:emailRequired");
      }
      if (!data.password) {
        errors.password = t("error:passwordRequired");
      }
      if (!data.code) {
        errors.code = t("error:passwordInvalid");
      }
      return errors;
    },
    onSubmit: (data: InitialLoginValues) => {
      setLoading(!loading);
      formik.resetForm();
      const resetObject = {
        email: data.email.toLowerCase(),
        code: data.code,
        newPassword: data.password,
      };
      resetPassword(resetObject)
        .then((res) => {
          if (res.status === 202) {
            navigate("/signin");
          } else {
            showError(toastError, t("signin:errorPassword"));
          }
        })
        .catch((err) => {
          showError(toastError, t("signin:errorPassword"));
        });
    },
  });

  const isFormFieldValid = (name: string) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: string) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const forgotPasswordButton = () => {
    forgotPassword({ email: email })
      .then((res) => {
        if (res.status === 202) {
          setSentCode(true);
          formik.values.email = email;
        } else {
          showError(toastError, t("signin:errorCode"));
        }
      })
      .catch((err) => {
        showError(toastError, t("signin:errorCode"));
      });
  };

  return (
    <div
      className="display-flex flex-direction-column justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: primaryColor }}
    >
      {loading ? (
        <LoadingComponent text={t("signin:loadingUser")} />
      ) : (
        <div className="display-flex flex-direction-column justify-content-center align-items-center signin-modal form-card">
          <div className="big-font margin-bottom">BIZalp</div>
          <div className="medium-font margin-bottom">
            {type === "recovery"
              ? t("signin:recoverPassword")
              : t("signin:changePassword")}
          </div>
          <div className="small-font" style={{ marginBottom: "2rem" }}>
            {sentCode ? t("signin:codeInsert") : t("signin:emailInsert")}
          </div>
          {sentCode ? (
            <form
              onSubmit={() => {
                formik.handleSubmit();
                if (type === "change") sessionStorage.clear();
              }}
              className="p-fluid"
              style={{ width: "100%" }}
            >
              {/* Field Email */}
              <div className="field">
                <span className="p-float-label p-input-icon-right">
                  <InputText
                    disabled
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("email"),
                      "p-inputtext-left input-text-signin": true,
                    })}
                  />
                  <label
                    htmlFor="email"
                    className={classNames({
                      "p-error": isFormFieldValid("email"),
                    })}
                  >
                    Email*
                  </label>
                </span>
                {getFormErrorMessage("email")}
              </div>

              {/* Field Code */}
              <div className="field">
                <span className="p-float-label p-input-icon-right">
                  <InputText
                    id="code"
                    name="code"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("code"),
                      "p-inputtext-left input-text-signin": true,
                    })}
                  />
                  <label
                    htmlFor="code"
                    className={classNames({
                      "p-error": isFormFieldValid("code"),
                    })}
                  >
                    {t("common:Code")}*
                  </label>
                </span>
                {getFormErrorMessage("code")}
              </div>

              {/* Field Password */}
              <div className="field">
                <span className="p-float-label">
                  <Password
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    feedback={false}
                    toggleMask
                    inputClassName={classNames({
                      "p-invalid": isFormFieldValid("password"),
                      "p-inputtext-left input-text-signin": true,
                    })}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({
                      "p-error": isFormFieldValid("password"),
                    })}
                  >
                    {t("common:newPwd")}*
                  </label>
                </span>
                {getFormErrorMessage("password")}
              </div>

              <div
                className="flex justify-content-center"
                style={{ width: "100%" }}
              >
                <Button
                  type="submit"
                  className="mt-2 signup-button"
                  style={{ width: "30%" }}
                  label={
                    type === "recovery"
                      ? t("signin:sendCode")
                      : t("signin:Modify")
                  }
                ></Button>
              </div>
            </form>
          ) : (
            <>
              <InputText
                placeholder="example@email.com"
                id="email"
                name="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                className={classNames({
                  "p-inputtext-left input-text-signin": true,
                })}
              />
              <Button
                type="submit"
                className="mt-5 signup-button"
                style={{ width: "30%" }}
                onClick={forgotPasswordButton}
                label={
                  type === "recovery"
                    ? t("signin:sendCode")
                    : t("signin:Modify")
                }
                disabled={email.length === 0}
              ></Button>
            </>
          )}
          <div
            className="display-flex justify-content-flex-end"
            style={{ width: "100%" }}
          ></div>
        </div>
      )}
      <div style={{ position: "absolute", top: "2rem", left: "2rem" }}>
        <Button
          icon="pi pi-arrow-left"
          className="yellow-button"
          style={{ height: "56px" }}
          onClick={() => {
            if (type === "change") window.open("/#/profile", "_self");
            else window.open("/#/signin", "_self");
          }}
        ></Button>
      </div>
      <Toast ref={toastError} />
    </div>
  );
};

export default RecoverPassword;
