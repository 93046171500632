import { axiosWithAuth, axiosWithUserId } from "../axiosWithAuth";
import { API_BASE_URL } from "../env";
import {
  ADD_COMMENT_TO_POST,
  CREATE_POST,
  DELETE_POST,
  GET_ALL_POSTS,
  SEARCH_POST,
  DELETE_COMMENT_POST,
  UPDATE_POST,
  DELETE_SELF_COMMENT_POST,
  DELETE_SELF_POST,
} from "./api";

export const getAllPosts = async () => {
  let server = API_BASE_URL + GET_ALL_POSTS.path;
  return await axiosWithAuth(server, {
    method: GET_ALL_POSTS.method,
  });
};

export const createPosts = async (requestBody?: any) => {
  let server = API_BASE_URL + CREATE_POST.path;
  let body = requestBody ? requestBody : CREATE_POST.requestBody;
  return await axiosWithAuth(server, {
    method: CREATE_POST.method,
    data: { ...body },
  });
};

export const deletePosts = async (requestBody?: any) => {
  let server = API_BASE_URL + DELETE_POST.path;
  let body = requestBody ? requestBody : DELETE_POST.requestBody;
  return await axiosWithUserId(server, {
    method: DELETE_POST.method,
    data: { ...body },
  });
};

export const deleteSelfPosts = async (requestBody?: any) => {
  let server = API_BASE_URL + DELETE_SELF_POST.path;
  let body = requestBody ? requestBody : DELETE_POST.requestBody;
  return await axiosWithUserId(server, {
    method: DELETE_POST.method,
    data: { ...body },
  });
};

export const updatePosts = async (requestBody?: any) => {
  let server = API_BASE_URL + UPDATE_POST.path;
  let body = requestBody ? requestBody : UPDATE_POST.requestBody;
  return await axiosWithAuth(server, {
    method: UPDATE_POST.method,
    data: { ...body },
  });
};

export const addCommentToPost = async (requestBody?: any) => {
  let server = API_BASE_URL + ADD_COMMENT_TO_POST.path;
  let body = requestBody ? requestBody : ADD_COMMENT_TO_POST.requestBody;
  return await axiosWithAuth(server, {
    method: ADD_COMMENT_TO_POST.method,
    data: { ...body },
  });
};

export const deleteCommentToPost = async (requestBody?: any) => {
  let server = API_BASE_URL + DELETE_COMMENT_POST.path;
  let body = requestBody;
  return await axiosWithUserId(server, {
    method: DELETE_COMMENT_POST.method,
    data: { ...body },
  });
};

export const deleteSelfCommentToPost = async (requestBody?: any) => {
  let server = API_BASE_URL + DELETE_SELF_COMMENT_POST.path;
  let body = requestBody;
  return await axiosWithUserId(server, {
    method: DELETE_COMMENT_POST.method,
    data: { ...body },
  });
};

export const searchPost = async (requestBody?: any) => {
  let server = API_BASE_URL + SEARCH_POST.path;
  return await axiosWithAuth(server, {
    method: SEARCH_POST.method,
    params: { _id: requestBody },
  });
};
