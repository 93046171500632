import BizalpArea from "./BizalpArea";
import CosaFacciamo from "./CosaFacciamo";
import MountainCulturePhilosophy from "./MountainCulturePhilosphy";
import PhilosphyDescription from "./PhilosophyDescription";
import "./Philosopy.css";
import SignUpPhilosphy from "./SignUpPhilosphy";
import WhyBizalp from "./WhyBizalp";

const Philosophy = () => {
  return (
    <div className="flex flex-column gap-5">
      <div>
        <div className="background-image-philosophy"></div>
      </div>
      <PhilosphyDescription />
      <MountainCulturePhilosophy />
      <BizalpArea />
      <div className="grey-section">
        <CosaFacciamo />
        <WhyBizalp />
      </div>
      <SignUpPhilosphy />
    </div>
  );
};

export default Philosophy;
