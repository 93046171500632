import Footer from "../../Footer/Footer";
import { UseProductContext } from "../../Context/ProductContext";
import "./CardSummary.css";
import { secondColor } from "../../environment";
import { createProductService } from "../../../services/Product/ProductServices";
import { product_input } from "../../../models/Product/product";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductCard from "../../ProductsPage/componentsProducts/ProductCard/ProductCard";

const CardSummary = () => {
  const [finish, setFinish] = useState<boolean>(false);
  const pcontex = UseProductContext();
  let nav = useNavigate();

  const productCreate = () => {
    let body: product_input = {
      name: pcontex.productType.label ? pcontex.productType.label : "",
      description: pcontex.productDescription,
      link: pcontex.productLink,
      category: pcontex.productCategory.id,
      type: pcontex.productType.value,
      allergens: [],
      picture: pcontex.images.map((image) => {
        return { filename: image.filename, key: image.key };
      }),
      price: 0,
      discount: 0,
      available: true,
    };

    createProductService(body)
      .then(() => {
        nav("/products");
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (finish) productCreate();
  }, [finish]);

  return (
    <div className="h-full flex flex-column justify-content-space-between align-items-center">
      <div className="h-full flex flex-column justify-content-center">
        <ProductCard
          product={{
            productCategory: pcontex.productCategory.name,
            productDescription: pcontex.productDescription,
            productType: pcontex.productType.label || "",
            images: pcontex.images,
          }}
        />
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={90}
          enableNextButton={pcontex.productCategory.id !== ""}
          generalColor={secondColor}
          setFinish={setFinish}
        />
      </div>
    </div>
  );
};

export default CardSummary;
