import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { Dispatch, RefObject, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import image from "../../Assets/image-gallery.png";
import { onImageChange } from "../../Functions/ImageFunctions";

export interface UploadImagesProps {
  toastError: RefObject<Toast>;
  numberFile: number;
  numOfFiles: number;
  setNumOfFiles: Dispatch<SetStateAction<number>>;
  images: any[];
  setImages: Dispatch<SetStateAction<any[]>>;
  firstSentence: string;
  secondSentence: string;
  thirdSentence: string;
  showImage: boolean;
  setButtonText?: Dispatch<SetStateAction<string>>;
  acceptFile?: boolean;
}

export const UploadImages = ({
  toastError,
  numberFile,
  numOfFiles,
  setNumOfFiles,
  images,
  setImages,
  firstSentence,
  secondSentence,
  thirdSentence,
  showImage,
  setButtonText,
  acceptFile,
}: UploadImagesProps) => {
  const { t } = useTranslation("common");
  return (
    <>
      <label
        htmlFor="image-producers"
        className={classNames({
          "image-template-label display-flex flex-direction-column justify-content-center align-items-center":
            showImage,
          "upload-other-image display-flex justify-content-center": !showImage,
        })}
      >
        {showImage ? (
          <>
            <img src={image} alt="immagine" style={{ width: "4rem" }}></img>
            <span className="first-sentence">{firstSentence}</span>
            <span className="second-sentence">{secondSentence}</span>
            <span className="third-sentence">{thirdSentence}</span>
          </>
        ) : (
          <>{thirdSentence}</>
        )}
      </label>
      <input
        id="image-producers"
        type="file"
        accept={acceptFile ? "*" : "image/*"}
        multiple
        className="input-image-template"
        draggable={true}
        onChange={(e) => {
          if (setButtonText != null) setButtonText("upload");
          onImageChange(
            e,
            toastError,
            numberFile,
            numOfFiles,
            setNumOfFiles,
            images,
            setImages,
            t
          );
        }}
      />
    </>
  );
};
