export const EP_GET_ALL_PRODUCTS = {
  path: "product/getAllProducts",
  method: "GET",
};

export const EP_GET_PRODUCTS_BY_NAME = {
  path: "product/getProductsByProductName?name=",
  method: "GET",
};

export const EP_CREATE_PRODUCT = {
  path: "product/createProduct",
  method: "POST",
  requestBody: {
    name: "Jhon",
    description: "Jhon",
    link: "link.com",
    category: "id",
    type: "id",
    allergens: [],
    picture: [],
    price: 0,
    discount: 0,
    available: true,
  },
};

export const EP_DELETE_PRODUCT = {
  path: "product/deleteProduct",
  method: "DELETE",
  requestBody: {
    name: "Jhon",
    description: "Jhon",
    category: 1,
    allergens: ["a", "b", "c"],
    picture: ["img/test.jpg"],
    price: 200,
    discount: 30,
    available: "true/false",
    company: "vvuviuugigu",
    _id: "",
  },
};

export const EP_UPDATE_PRODUCT = {
  path: "product/updateProduct",
  method: "PATCH",
  requestBody: {
    name: "Jhon",
    description: "Jhon",
    category: 1,
    allergens: ["a", "b", "c"],
    picture: ["img/test.jpg"],
    price: 200,
    discount: 30,
    available: "true/false",
    company: "vvuviuugigu",
    _id: "",
  },
};

export const EP_SEARCH_PRODUCT = {
  path: "product/searchProduct",
  method: "GET",
  params: {
    _id: "0",
    name: "Jhon",
    description: "Jhon",
    category: 1,
    price: 200.0,
    discount: 30,
  },
};
