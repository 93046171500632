import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { UseProducerContext } from "../../Context/ProducersContext";
import { useFormik } from "formik";
import { primaryColor } from "../../environment";
import { useTranslation } from "react-i18next";
import Footer from "../../Footer/Footer";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { PASSWORD_REGEX } from "../../../types/Regex";
import { searchUserServiceEmail } from "../../../services/User/UsersServices";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";

interface InitialSignupValues {
  [index: string]: string;
  email: string;
  password: string;
}

const OtherData = () => {
  const {
    producerEmail,
    setProducerEmail,
    producerPassword,
    setProducerPassword,
  } = UseProducerContext();
  const toast = useRef<Toast>(null);
  const { t } = useTranslation(["common", "error", "signin"]);
  let errors;
  const [newUser, setNewUser] = useState<boolean>(true);
  const [showLoginDialog, setShowLoginDialog] = useState<boolean>(false);
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: producerEmail,
      password: producerPassword,
    } as InitialSignupValues,

    validate: (data: InitialSignupValues) => {
      errors = {} as Record<string, string>;

      if (!data.email) {
        errors.email = t("error:emailRequired");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = t("error:emailInvalid");
      }

      if (!data.password) {
        errors.password = t("error:passwordRequired");
      } else if (!PASSWORD_REGEX.test(data.password)) {
        errors.password = t("error:passwordInvalid");
      }

      return errors;
    },
    onSubmit: (data: InitialSignupValues) => {
      setProducerEmail(data.email.toLowerCase());
      setProducerPassword(data.password);
    },
  });

  const isFormFieldValid = (name: string) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: string) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const passwordHeader = <h6>{`${t("signin:choosePassword")}`}</h6>;
  const passwordFooter = (
    <React.Fragment>
      <p className="mt-2">{`${t("signin:tip")}`}</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>{`${t("signin:lowercaseChar")}`}</li>
        <li>{`${t("signin:uppercaseChar")}`}</li>
        <li>{`${t("signin:numericChar")}`}</li>
        <li>{`${t("signin:specialChar")}`}</li>
        <li>{`${t("signin:minimumChar")}`}</li>
      </ul>
    </React.Fragment>
  );

  return (
    <>
      <div className="display-flex flex-direction-column h-full justify-content-space-evenly">
        <div className="display-flex flex-direction-column justify-content-center h-full ">
          {showLoginDialog ? (
            <Dialog
              visible={true}
              header={t("common:alreadyRegister")}
              footer={
                <>
                  <Button
                    className="general-button"
                    onClick={() => navigate("/signin")}
                  >
                    Login
                  </Button>
                </>
              }
              onHide={() => {
                setShowLoginDialog(false);
                setNewUser(false);
                formik.values.email = "";
                formik.values.password = "";
              }}
            >
              {t("common:goToLogin")}
            </Dialog>
          ) : (
            <></>
          )}

          <form
            onSubmit={formik.handleSubmit}
            className="p-fluid display-flex flex-direction-column justify-content-center"
            style={{ width: "100%" }}
          >
            {/* Field Email */}
            <div className="p-float-label">
              <InputText
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid ": isFormFieldValid("email"),
                  "p-inputtext-left producer-input-text": true,
                })}
              />
              <label
                htmlFor="email"
                className={classNames({
                  "p-error": isFormFieldValid("email"),
                })}
              >
                Email*
              </label>
            </div>
            {getFormErrorMessage("email")}

            {/* Field Password */}
            <div
              className="p-float-label p-input-icon-right"
              style={{ marginTop: "3rem" }}
            >
              <Password
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                toggleMask
                header={passwordHeader}
                footer={passwordFooter}
                className={classNames({
                  "p-invalid": isFormFieldValid("password"),
                  "p-inputtext-left producer-input-text": true,
                })}
              />
              <label
                htmlFor="password"
                className={classNames({
                  "p-error": isFormFieldValid("password"),
                })}
              >
                Password*
              </label>
            </div>
            {getFormErrorMessage("password")}
            <div
              className="flex justify-content-center"
              style={{ width: "100%", marginTop: "3rem" }}
            >
              <Button
                type="submit"
                className="general-button"
                style={{ width: "30%" }}
                label={t("common:dataConfirm")}
                onClick={() => {
                  searchUserServiceEmail({ email: formik.values.email }).then(
                    (res: any) => {
                      if (res.data.email == formik.values.email) {
                        setShowLoginDialog(true);
                        setNewUser(false);
                      } else {
                        setNewUser(true);
                      }
                    }
                  );
                }}
              ></Button>
            </div>
          </form>
        </div>
        <div className="footer-position">
          <Footer
            progressbarValue={50}
            generalColor={primaryColor}
            enableNextButton={
              producerEmail !== "" && producerPassword !== "" && newUser
            }
          ></Footer>
        </div>
      </div>

      <Toast ref={toast} />
    </>
  );
};

export default OtherData;
