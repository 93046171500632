import LoadingPage from "../LoadingPage/LoadingPage";
import { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { company } from "../../models/Company/company";
import { primaryColor } from "../environment";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import DialogCompany from "./ModifyProfile/DialogCompany";
import "../General.css";
import "./ProfilePage.css";
import "primeicons/primeicons.css";
import {
  deleteCompanyService,
  searchCompanyService,
} from "../../services/Company/CompanyServices";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { deleteUserService } from "../../services/User/UsersServices";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "../../services/Cognito/CognitoServices";
import { Password } from "primereact/password";

export interface User {
  companies: company[];
  email: string;
  name: string;
  surname: string;
  userType: number;
  _id: string;
}

const ProfilePage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [company, setCompany] = useState<company>();
  const [updateUser, setUpdateUser] = useState(false);
  const [showDialogCompany, setShowDialogCompany] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const toast = useRef<Toast>(null);
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  const titleCard = (text: string) => (
    <>
      <div className="display-flex flex-direction-column align-items-center justify-content-center">
        <span style={{ color: primaryColor }}>{text}</span>

        <span
          className="modify-button"
          onClick={() => setShowDialogCompany(true)}
        >
          {t("common:modifyCompany")}
        </span>
      </div>
    </>
  );

  const deleteProfile = () => {
    if (company) {
      deleteUser({ email: company.email, password: password })
        .then((resCognito: any) => {
          if (resCognito.status === 201) {
            deleteCompanyService({ _id: sessionStorage.getItem("company") })
              .then((resCompany: any) => {
                if (resCompany.status === 200) {
                  deleteUserService({
                    _id: sessionStorage.getItem("userId"),
                  }).then((resUser: any) => {
                    if (resUser.status === 200) {
                      sessionStorage.clear();
                      navigate("/");
                      window.location.reload();
                    }
                  });
                }
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((errCognito: any) => console.log(errCognito));
    }
  };

  useEffect(() => {
    setLoading(true);

    if (sessionStorage.getItem("company") == null) {
      navigate("/");
    }

    searchCompanyService({ _id: sessionStorage.getItem("company") }).then(
      (res) => {
        if (res && res.data) {
          setCompany(res.data);
          setLoading(false);
        }
      }
    );
  }, [updateUser]);

  if (loading) return <LoadingPage />;
  else
    return (
      <div>
        <Toast ref={toast} position="top-right" />
        <>
          <div className="display-flex justify-content-space-evenly align-items-center max-height-profile-page mobile-flex-column">
            {company ? (
              <Card
                title={titleCard("Company")}
                className="profile-company-card"
              >
                <div
                  className="display-flex justify-content-space-between mobile-flex-column"
                  style={{ marginTop: "2rem", width: "100%" }}
                >
                  <div
                    className="display-flex flex-direction-column"
                    style={{ marginRight: "2rem" }}
                  >
                    <span className="card-profile-small-title">
                      {t("common:Name")}
                    </span>
                    <span className="card-profile-small-text">
                      {company?.name}
                    </span>
                    <span className="card-profile-small-title">
                      {t("common:Address")}
                    </span>
                    <span className="card-profile-small-text">
                      {company?.address.label}
                    </span>

                    <span className="card-profile-small-title">
                      {t("common:Email")}
                    </span>
                    <span className="card-profile-small-text">
                      {company?.email}
                    </span>
                  </div>
                  <div className="display-flex flex-direction-column">
                    <span className="card-profile-small-title">
                      {t("common:Phone")}
                    </span>
                    <span className="card-profile-small-text">
                      {company?.phone}
                    </span>
                    <span className="card-profile-small-title">
                      {t("common:Description")}
                    </span>
                    <span className="card-profile-small-text">
                      {company?.description}
                    </span>
                    <span className="card-profile-small-title">
                      {t("common:value")}
                    </span>
                    <span className="card-profile-small-text">
                      {company?.values}
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => setShowDeleteDialog(true)}
                  style={{
                    cursor: "pointer",
                    color: "red",
                    textAlign: "center",
                    textDecoration: "underline",
                    width: "100%",
                  }}
                >
                  {t("common:DeleteProfile")}
                </div>
              </Card>
            ) : (
              <></>
            )}
          </div>

          {company ? (
            <DialogCompany
              showDialogProfile={showDialogCompany}
              setShowDialogProfile={setShowDialogCompany}
              company={company}
              updateUser={updateUser}
              setUpdateUser={setUpdateUser}
            />
          ) : (
            <></>
          )}
          {showDeleteDialog ? (
            <Dialog
              header={t("common:confirmDeleteProfile")}
              onHide={() => setShowDeleteDialog(false)}
              visible={showDeleteDialog}
              closable={false}
              footer={
                <div className="flex justify-content-space-between w-full">
                  <Button
                    className="yellow-button"
                    onClick={() => setShowDeleteDialog(false)}
                  >
                    {t("common:undo")}
                  </Button>
                  <Button className="general-button" onClick={deleteProfile}>
                    {t("common:confirm")}
                  </Button>
                </div>
              }
            >
              <div className="flex flex-column">
                <label>{t("common:insertPassword")}</label>
                <Password
                  inputClassName="w-full"
                  toggleMask
                  className="mt-3"
                  placeholder="Password"
                  value={password}
                  feedback={false}
                  onChange={(event) => setPassword(event.target.value)}
                ></Password>
              </div>
            </Dialog>
          ) : (
            <></>
          )}
        </>
      </div>
    );
};

export default ProfilePage;
