import { Toast } from "primereact/toast";
import { useState, useRef, useEffect } from "react";
import { UsePaginatorContext } from "../../Context/PagesContext";
import { UseProducerContext } from "../../Context/ProducersContext";
import { primaryColor } from "../../environment";
import {
  createImagesURLs,
  dropEvent,
  onDragOverFunction,
} from "../../Functions/ImageFunctions";
import Footer from "../../Footer/Footer";
import AmazonLogo from "./AmazonLogo";
import { UploadImages } from "./UploadImages";
import { ShowImages } from "./ShowImages";
import { ImageInterface, ImageWithURL } from "../../../types/generic.types";
import {
  getGetUrlFile,
  getUrlAndUploadFile,
} from "../../../services/File/File";
import { useTranslation } from "react-i18next";
import { LoadingImage } from "../../LoadingImage/LoadingImage";
import { showError, showSuccess } from "../../Toast/Toast.functions";

const LogoTemplate = () => {
  const { currentPage, setCurrentPage } = UsePaginatorContext();
  const { producerLogo, setProducerLogo } = UseProducerContext();
  /** Contiene tutte le immagini caricate */
  const [images, setImages] = useState<any[]>([]);
  /** Contiene gli url di tutte le immagini caricate */
  const [imagesUrls, setImagesUrls] = useState<any[]>([]);
  /** Contiene la key e il filename salvati a DB per poter ottenere l'immagine da amazon */
  const [amazonData, setAmazonData] = useState<ImageInterface>(producerLogo);
  /** Contiene l'url e il filename per visualizzare la foto*/
  const [amazonUrls, setAmazonUrls] = useState<ImageWithURL>({
    url: "",
    filename: "",
    key: "",
  });
  const [numOfFiles, setNumOfFiles] = useState(0);
  const toastError = useRef<any>(null);
  const { t } = useTranslation(["common", "imagesSentence"]);
  const [buttonText, setButtonText] = useState(
    amazonData.key !== "" ? "next" : "upload"
  );

  const [visibleDialog, setVisibleDialog] = useState(false);

  /** Creo tutte gli url delle immagini caricate e le inserisco nell'array newImagesURL */
  useEffect(() => {
    createImagesURLs(images, setImagesUrls);
  }, [images]);

  useEffect(() => {
    if (amazonData.key !== "") {
      getGetUrlFile(amazonData.key)
        .then((res) => {
          setAmazonUrls({
            url: res,
            key: amazonData.key,
            filename: amazonData.filename,
          });
        })
        .catch(() => showError(toastError, t("imagesSentence:errorGetFoto")));
    }
  }, [amazonData]);

  const logoUploadFinish = () => {
    if (buttonText === "upload") {
      setVisibleDialog(true);
      getUrlAndUploadFile(images[0].name, images[0])
        .then((result) => {
          setProducerLogo({ key: result.key, filename: images[0].name });
          setAmazonData({ key: result.key, filename: images[0].name });
          setImagesUrls([]);
          setImages([]);
          setButtonText("next");
          setVisibleDialog(false);
          showSuccess(toastError, t("imagesSentence:logoUploadedSuccess"));
        })
        .catch(() =>
          showError(toastError, t("imagesSentence:errorUploadFoto"))
        );
    } else setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <div className="flex flex-column justify-content-center h-full">
        <div
          id="div-card-image"
          className="card-image"
          onDrop={(event) =>
            dropEvent(
              event,
              toastError,
              numOfFiles,
              setNumOfFiles,
              images,
              setImages,
              1,
              t
            )
          }
          onDragOver={onDragOverFunction}
          draggable={true}
        >
          {amazonUrls.url !== "" ? (
            <AmazonLogo
              amazonUrls={amazonUrls}
              numOfFiles={numOfFiles}
              setAmazonUrls={setAmazonUrls}
              setAmazonData={setAmazonData}
              producerImages={producerLogo}
              setProducerImages={setProducerLogo}
              setNumOfFiles={setNumOfFiles}
              setButtonText={setButtonText}
              buttonText={buttonText}
              toastError={toastError}
            />
          ) : (
            <>
              {imagesUrls.length > 0 ? (
                <ShowImages
                  images={images}
                  setImages={setImages}
                  imagesUrls={imagesUrls}
                  setImagesUrls={setImagesUrls}
                  numOfFiles={numOfFiles}
                  setNumOfFiles={setNumOfFiles}
                />
              ) : amazonUrls.key !== "" ? (
                <></>
              ) : (
                <UploadImages
                  toastError={toastError}
                  numberFile={1}
                  numOfFiles={numOfFiles}
                  setNumOfFiles={setNumOfFiles}
                  images={images}
                  setImages={setImages}
                  firstSentence={t("imagesSentence:firstSentenceLogo")}
                  secondSentence={""}
                  thirdSentence={t("imagesSentence:thirdSentence")}
                  showImage={true}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={60}
          generalColor={primaryColor}
          enableNextButton={imagesUrls.length > 0 || amazonUrls.key !== ""}
          nextPageText={
            buttonText === "upload" ? t("common:upload") : t("common:next")
          }
          setFinish={logoUploadFinish}
        ></Footer>
      </div>
      <Toast ref={toastError} position="top-right" />
      <LoadingImage visible={visibleDialog} />
    </>
  );
};

export default LogoTemplate;
