import { primaryColor } from "../environment";
import NewPost from "./NewPost";
import Post from "./Post/Post";
import "./BulletinBoard.css";
import { useEffect, useState } from "react";
import { getAllBulletinBoards } from "../../services/BulletinBoard/BulletinBoard";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import ForumRules from "../ForumRules/ForumRules";

const BulletinBoard = () => {
  const [bulletinBoardId, setBulletinBoardId] = useState<any[]>([]);
  const [addedPost, setAddedPost] = useState<boolean>(false);
  const [showCommentInPost, setShowCommentInPost] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(true);
  const [superUserLogged, setSuperUserLogged] = useState(false);
  const { t } = useTranslation(["common"]);
  const [visible, setVisible] = useState<boolean>(true);

  const saveConfirm = () => {
    window.localStorage.setItem("forum_rules_accepted", "true");
    setVisible(false);
  };

  useEffect(() => {
    getAllBulletinBoards()
      .then((res) => setBulletinBoardId(res.data))
      .catch((error) => console.log(error));
  }, [addedPost, showCommentInPost]);

  useEffect(() => {
    if (window.sessionStorage.getItem("superUser") === "true") {
      setSuperUserLogged(true);
    }
    if (superUserLogged) {
      window.sessionStorage.setItem("superUser", "true");
    }
  }, []);

  return (
    <div className="flex flex-column pb-4">
      {/* {window.localStorage.getItem("enteredForum") ? (
        <></>
      ) : (
        <Dialog
          header={t("common:welcome")}
          onHide={() => {
            setShowDialog(false);
            window.localStorage.setItem("enteredForum", "true");
          }}
          visible={showDialog}
          className="dialog-signup-width"
        >
          <p>{t("common:forum")}</p>
          <li>{t("common:forumList1")}</li>
          <li>{t("common:forumList2")}</li>
          <li>{t("common:forumList3")}</li>
          <li>{t("common:forumList4")}</li>
          <li>{t("common:forumList5")}</li>
        </Dialog>
      )} */}
      <div
        style={{ backgroundColor: primaryColor }}
        className=" bulletin-board-title"
      >
        BIZalp
        <br />
        community
      </div>
      <Panel header={t("common:welcome")} toggleable>
        <p>{t("common:forum")}</p>
        <li>{t("common:forumList1")}</li>
        <li>{t("common:forumList2")}</li>
        <li>{t("common:forumList3")}</li>
        <li>{t("common:forumList4")}</li>
        <li>{t("common:forumList5")}</li>
      </Panel>
      <div className="bullettin-board-display">
        {!superUserLogged ? (
          <NewPost addedPost={addedPost} setAddedPost={setAddedPost} />
        ) : (
          <></>
        )}
        <Post
          bulletinBoardIds={bulletinBoardId}
          setShowCommentInPost={setShowCommentInPost}
          showCommentInPost={showCommentInPost}
        />
      </div>
      {window.localStorage.getItem("forum_rules_accepted") === "true" ? (
        <></>
      ) : (
        <Dialog
          header={t("common:forumRulesConfirm")}
          visible={visible}
          onHide={saveConfirm}
          style={{ width: "50vw" }}
          position="bottom"
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div style={{ marginBottom: "1rem" }}>
              {t("common:firstSentenceForumDialog")}
              <Link to={"/forumRules"}>{t("common:forumRules")}</Link>.
            </div>
            <Button className="general-button" onClick={saveConfirm}>
              {t("common:confirm")}
            </Button>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default BulletinBoard;
