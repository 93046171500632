import { axiosWithAuth } from "./../axiosWithAuth";
import { API_BASE_URL } from "../env";
import {
  EP_CREATE_USER,
  EP_DELETE_USER,
  EP_GET_ALL_USERS,
  EP_SEARCH_USER_EMAIL,
  EP_SEARCH_USER_ID,
  EP_UPDATE_USER,
} from "./api";

export const getAllusersService = async () => {
  let server = API_BASE_URL + EP_GET_ALL_USERS.path;
  return await axiosWithAuth(server, {
    method: EP_GET_ALL_USERS.method,
  });
};

export const createUserService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_CREATE_USER.path;
  let body = requestBody ? requestBody : EP_CREATE_USER.requestBody;
  return await axiosWithAuth(server, {
    method: EP_CREATE_USER.method,
    data: { ...body },
  }).then((res) => {
    sessionStorage.setItem("userId", res.data);
    return res;
  });
};

export const deleteUserService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_DELETE_USER.path;
  let body = requestBody ? requestBody : EP_DELETE_USER.requestBody;
  return await axiosWithAuth(server, {
    method: EP_DELETE_USER.method,
    data: { ...body },
  });
};

export const updateUserService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_UPDATE_USER.path;
  let body = requestBody ? requestBody : EP_UPDATE_USER.requestBody;
  return await axiosWithAuth(server, {
    method: EP_UPDATE_USER.method,
    data: { ...body },
  });
};

export const searchUserServiceId = async (params?: any) => {
  let server = API_BASE_URL + EP_SEARCH_USER_ID.path;
  let queryParams = params ? params : EP_SEARCH_USER_ID.params;
  return await axiosWithAuth(server, {
    method: EP_SEARCH_USER_ID.method,
    params: queryParams,
  });
};

export const searchUserServiceEmail = async (params?: any) => {
  let server = API_BASE_URL + EP_SEARCH_USER_EMAIL.path;
  let queryParams = params ? params : EP_SEARCH_USER_EMAIL.params;
  return await axiosWithAuth(server, {
    method: EP_SEARCH_USER_EMAIL.method,
    params: queryParams,
  });
};
