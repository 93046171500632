import { AES } from "crypto-js";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import CryptoJS from "crypto-js";
import {
  confirmRegistration,
  loginCognito,
  resendConfirmationCode,
} from "../../services/Cognito/CognitoServices";
import { useTranslation } from "react-i18next";
import { primaryColor } from "../environment";
import { showError, showSuccess } from "../Toast/Toast.functions";
import { Toast } from "primereact/toast";
import { searchUserServiceEmail } from "../../services/User/UsersServices";
import { company } from "../../models/Company/company";
import { createNotify } from "../../services/Notify/Notify";

const ConfirmRegistration = () => {
  const [email, setEmail] = useState<string>(
    sessionStorage.getItem("email") + ""
  );
  const [verificationCode, setVerificationCode] = useState<string>();
  const { t } = useTranslation(["common", "error"]);
  const toast = useRef<Toast>(null);

  /* Prendo la password cryptata per poi fare la login */
  const passwordEncrypted = sessionStorage.getItem("xce4i");
  const passwordD = AES.decrypt(passwordEncrypted!, "sha256");

  const VerifyEmailAPI = () => {
    const verify = {
      email: email,
      verifyCode: verificationCode,
    };

    /** Chiamata per confermare il codice di registrazione */
    confirmRegistration(verify)
      .then((result) => {
        if (result.status === 202) {
          /** Chiamata per login così posso salvare lo user nel mongo */
          loginCognito({
            email: email,
            password: JSON.parse(passwordD.toString(CryptoJS.enc.Utf8)),
          })
            .then(() => {
              sessionStorage.removeItem("xce4i");
              sessionStorage.removeItem("email");
              searchUserServiceEmail({ email: email }).then((searchResult) => {
                if (searchResult.data === "") {
                  showError(toast, t("signin:genericError"));
                  sessionStorage.clear();
                } else {
                  /** salva in sessionStorage l'id dell'utente, il nome
                   * se di tipo 2 o 3 salva anche l'id della compagnia */
                  window.sessionStorage.setItem(
                    "userId",
                    searchResult.data._id
                  );
                  window.sessionStorage.setItem("user", searchResult.data.name);
                  if (
                    searchResult.data.userType === 1 ||
                    searchResult.data.userType === 2
                  ) {
                    const company: company = searchResult.data.companies[0];
                    window.sessionStorage.setItem("company", company._id);
                    const emailToAdministratorRegister = {
                      suppliers: [],
                      emailCustomer: email,
                      customerName: company.name,
                      customerPhoneNumber: company.phone,
                      alreadySent: false,
                      text: "Nuovo utente registrato.",
                      type: 3,
                    };
                    createNotify(emailToAdministratorRegister).then(
                      (res: any) => {
                        if (res.status === 201) {
                          window.open("/", "_self");
                        } else
                          return showError(toast, t("error:emailNotSentAdmin"));
                      }
                    );
                  }
                  if (searchResult.data.userType === 3) {
                    window.sessionStorage.setItem("superUser", "true");
                    /**reindirizzamento alla homepage */
                    window.open("/", "_self");
                  }
                }
              });
            })
            .catch(() => showError(toast, t("common:loginError")));
        }
      })
      .catch(() => showError(toast, t("common:wrongCode")));
  };

  const resendCode = () => {
    resendConfirmationCode({ email: email })
      .then(() => showSuccess(toast, t("resendCodeSuccess")))
      .catch(() => showError(toast, t("resendCodeError")));
  };

  return (
    <div
      className="display-flex flex-direction-column justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: primaryColor }}
    >
      <div className="display-flex flex-direction-column justify-content-center align-items-center confirmRegistrationModal">
        <div style={{ marginBottom: "3rem" }} className="big-font">
          {t("common:confirm")}
        </div>
        <InputText
          disabled={true}
          style={{ width: "100%", marginBottom: "1rem", borderRadius: "50px" }}
          placeholder={t("common:Email")}
          value={email}
        />
        <InputText
          style={{ width: "100%", borderRadius: "50px" }}
          placeholder={t("common:verifyCode")}
          value={verificationCode}
          onChange={(e) => {
            setVerificationCode(e.target.value);
          }}
        />
        <Button
          name="Login"
          className="p-button-raised p-button-text"
          onClick={VerifyEmailAPI}
          style={{
            marginTop: "1rem",
            backgroundColor: primaryColor,
            color: "#fff",
          }}
        >
          {t("common:confirm")}
        </Button>
        <div
          className="display-flex justify-content-flex-end"
          style={{ width: "100%" }}
        >
          <div
            className="no-decoration"
            onClick={resendCode}
            style={{
              color: primaryColor,
              cursor: "pointer",
            }}
          >
            {t("signin:resendCode")}
          </div>
        </div>
      </div>
      <Toast ref={toast}></Toast>
    </div>
  );
};

export default ConfirmRegistration;
