export const EP_GET_ALL_COMPANIES = {
  path: "company/getAllCompanies",
  method: "GET",
};

export const EP_CREATE_COMPANY = {
  path: "company/createCompany",
  method: "POST",
  requestBody: {
    name: "Jhon",
    description: "Jhon",
    vat: "IT0000",
    address: "via test 1",
    logo: "img/test.jpg",
    pictures: ["img/test.jpg,img/test1.jpg,img/test2.jpg"],
    email: "test@test.com",
    phone: "+39 333 3333 333",
    payment: ["payment1", "payment2"],
    users: ["user1_id"],
  },
};

export const EP_DELETE_COMPANY = {
  path: "company/deleteCompany",
  method: "DELETE",
  requestBody: {
    name: "Jhon",
    description: "Jhon",
    vat: "IT0000",
    address: "via test 1",
    logo: "img/test.jpg",
    pictures: ["img/test.jpg,img/test1.jpg,img/test2.jpg"],
    email: "test@test.com",
    phone: "+39 333 3333 333",
    payment: ["payment1", "payment2"],
    _id: "",
  },
};

export const EP_UPDATE_COMPANY = {
  path: "company/updateCompany",
  method: "PATCH",
  requestBody: {
    name: "Jhon",
    description: "Jhon",
    vat: "IT0000",
    address: "via test 1",
    logo: "img/test.jpg",
    pictures: ["img/test.jpg,img/test1.jpg,img/test2.jpg"],
    email: "test@test.com",
    phone: "+39 333 3333 333",
    payment: ["payment1", "payment2"],
    _id: "",
  },
};

export const EP_SEARCH_COMPANY = {
  path: "company/searchCompany",
  method: "GET",
  params: {
    _id: "0",
    name: "Jhon",
    description: "Jhon",
    vat: "IT0000",
    address: "via test 1",
    logo: "img/test.jpg",
    email: "test@test.com",
    phone: "+39 333 3333 333",
  },
};
