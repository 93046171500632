import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { Image } from "primereact/image";
import { useState } from "react";
import { UseProductContext } from "../../Context/ProductContext";
import "./ImageCard.css";
export interface imageProps {
  src: string;
  alt: string;
  index?: string | number;
  title: string;
}

const ImageCard = (props: {
  filename: string;
  publicKey: string;
  url: string;
}) => {
  const { images, setImages } = UseProductContext();

  const initCheck = () => {
    let initRes: boolean = false;
    if (images.length)
      if (images.findIndex((img) => img.key === props.publicKey) !== -1)
        initRes = true;
    return initRes;
  };

  const [checked, setChecked] = useState<boolean>(initCheck());

  const handleClickChecked = (e: CheckboxChangeParams) => {
    if (e.checked && images.length < 3) {
      setChecked(e.checked);
      let imgs = images;
      imgs.push({
        filename: props.filename,
        key: props.publicKey,
        url: props.url,
      });
      setImages(imgs);
    } else if (!e.checked) {
      setChecked(e.checked);
      let imgs = images;
      let found = imgs.findIndex((el) => el && el.key === props.publicKey);
      if (found !== -1) {
        imgs.splice(found, 1);
        setImages(imgs);
      }
    }
  };

  return (
    <div
      style={{
        margin: "2px",
        display: "flex",
        justifyContent: "center",
        marginRight: "1rem",
        marginBottom: " 1rem",
        maxWidth: "8rem",
      }}
    >
      <div className="wrapper-image-checkbox">
        <Image
          src={props.url}
          alt={props.filename}
          key={props.publicKey}
          preview
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            width: "100%",
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            inputId="binary"
            checked={checked}
            onChange={handleClickChecked}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
