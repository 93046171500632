import React from "react";
import { useTranslation } from "react-i18next";
import cosa_facciamo from "../Assets/cosa_facciamo_filosofia.png";
import { primaryColor } from "../environment";

const CosaFacciamo = () => {
  const { t } = useTranslation(["philosophy"]);

  return (
    <div className="display-flex flex-direction-row justify-content-space-around cosa-facciamo-position">
      <div className="w-30vw display-flex flex-direction-column align-items-center">
        <img
          src={cosa_facciamo}
          alt="cosa facciamo"
          className="sell-veg-image-size"
        ></img>
      </div>
      <div className="w-30vw display-flex flex-direction-column">
        <div
          className="big-font title-cosa-facciamo"
          style={{ color: primaryColor, textAlign: "center" }}
        >
          {t("philosophy:cosaFacciamoTitle")}
        </div>
        <div
          className="sentence-join-family small-font mobile-padding"
          style={{ marginTop: "1rem", fontWeight: "400" }}
        >
          {t("philosophy:cfSentencePhilosophy")}
        </div>
      </div>
    </div>
  );
};

export default CosaFacciamo;
