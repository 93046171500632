import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { secondColor } from "../../../environment";

interface RenderSuppliersInterface {
  numMax: any;
  setPickAll: Dispatch<SetStateAction<boolean>>;
  setUnpickAll: Dispatch<SetStateAction<boolean>>;
  unpickAll: boolean;
  pickAll: boolean;
  picked: any[];
  supplier: any;
  choosedSuppliers: any[];
  setChoosedSuppliers: Dispatch<SetStateAction<any[]>>;
}

const RenderSuppliers = ({
  numMax,
  setPickAll,
  setUnpickAll,
  unpickAll,
  pickAll,
  picked,
  supplier,
  choosedSuppliers,
  setChoosedSuppliers,
}: RenderSuppliersInterface) => {
  const [toggleClicked, setToggleClicked] = useState<boolean>(false);
  useEffect(() => {
    picked.forEach((company) => {
      if (company.companyId === supplier.companyId) {
        supplier.selected = true;
        setToggleClicked(true);
        if (!choosedSuppliers.find((e) => e.companyId === supplier.companyId))
          choosedSuppliers.push(supplier);
      }
    });
  }, []);

  useEffect(() => {
    if (pickAll) {
      if (!picked.find((e) => e.companyId === supplier.companyId))
        picked.push(supplier);
      setChoosedSuppliers(picked);
      setToggleClicked(true);
    }
    if (unpickAll) {
      picked = [];
      setChoosedSuppliers([]);
      setToggleClicked(false);
    }
  }, [pickAll, unpickAll]);

  useEffect(() => {
    if (picked.length === numMax) {
      setPickAll(true);
      setUnpickAll(false);
    }
  }, []);

  const SetCheckedCompany = (value: boolean) => {
    if (value) {
      supplier.selected = true;
      choosedSuppliers.push(supplier);
    } else {
      supplier.selected = false;
      let a = [
        ...choosedSuppliers.filter((choosedSupplier: any) => {
          return choosedSupplier.companyId !== supplier.companyId;
        }),
      ];
      setChoosedSuppliers(a);
    }
    setToggleClicked(value);
  };

  function checkPickedAll() {
    if (choosedSuppliers.length === numMax) {
      setPickAll(true);
      setUnpickAll(false);
    } else if (choosedSuppliers.length === 0) {
      setPickAll(false);
      setUnpickAll(true);
    } else if (choosedSuppliers.length === 1) {
      setPickAll(false);
    }
  }

  return (
    <Button
      style={{
        backgroundColor: secondColor,
        marginRight: "1rem",
        width: "auto",
        minWidth: "8rem",
        marginTop: "0.5rem",
      }}
      className="yellow-button"
      onClick={() => {
        SetCheckedCompany(!toggleClicked);
        checkPickedAll();
      }}
      key={supplier.companyId}
    >
      <Checkbox
        inputId={supplier.companyId}
        className="checbok-buy-product"
        checked={toggleClicked}
        style={{ borderRadius: "100%" }}
      />
      <span className="ml-2">{supplier.name}</span>
    </Button>
  );
};

export default RenderSuppliers;
