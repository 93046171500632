import { InputText } from "primereact/inputtext";
import Footer from "../../Footer/Footer";
import { UseProducerContext } from "../../Context/ProducersContext";
import { primaryColor } from "../../environment";
import { useTranslation } from "react-i18next";

const ProducerName = () => {
  const { producerName, setProducerName } = UseProducerContext();
  const { t } = useTranslation("common");
  return (
    <>
      <div className="display-flex flex-direction-column h-full justify-content-center">
        <span style={{ marginBottom: "1rem" }}>{t("common:pickName")}</span>
        <InputText
          value={producerName}
          maxLength={50}
          className="producer-input-text"
          onChange={(e) => setProducerName(e.target.value)}
        ></InputText>
        <span style={{ marginTop: "1rem" }}>{producerName.length}/50</span>
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={12}
          enableNextButton={producerName !== ""}
          generalColor={primaryColor}
        ></Footer>
      </div>
    </>
  );
};

export default ProducerName;
