import React, { useEffect, useState } from "react";
import Comments from "./Comments";
import PostData from "./PostData";
import NewComment from "./NewComment";
import "./Post.css";
import DeletePost from "./DeletePost";
import { useTranslation } from "react-i18next";

const Post = ({
  bulletinBoardIds,
  showCommentInPost,
  setShowCommentInPost,
}: {
  bulletinBoardIds: any[];
  showCommentInPost: boolean;
  setShowCommentInPost: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [superUserLogged, setSuperUserLogged] = useState(false);
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (window.sessionStorage.getItem("superUser") === "true") {
      setSuperUserLogged(true);
    }
    if (superUserLogged) {
      window.sessionStorage.setItem("superUser", "true");
    }
  }, []);

  return (
    <div>
      {bulletinBoardIds
        .filter((post) => post._idPost != null)
        .sort(
          (a: any, b: any) =>
            new Date(b._idPost.dateTime).getTime() -
            new Date(a._idPost.dateTime).getTime()
        )
        .map((id) => (
          <div key={id._id} className="post-card">
            <PostData
              title={id._idPost.title}
              description={id._idPost.description}
              name={
                id._idPost.idCompany
                  ? id._idPost.idCompany.name
                  : t("common:deletedAccount")
              }
              image={
                id._idPost.idCompany ? id._idPost.idCompany.logo : undefined
              }
              dateTime={id._idPost.dateTime}
              file={id._idPost.images}
            />
            <Comments
              data={id}
              showCommentInPost={showCommentInPost}
              setShowCommentInPost={setShowCommentInPost}
              isSuperUser={superUserLogged}
            />
            {!superUserLogged ? (
              <NewComment
                idPost={id._idPost._id}
                showCommentInPost={showCommentInPost}
                setShowCommentInPost={setShowCommentInPost}
              />
            ) : (
              <></>
            )}
            {superUserLogged ||
            (id._idPost.idCompany !== null &&
              id._idPost.idCompany.id ==
                window.sessionStorage.getItem("company")) ? (
              <DeletePost
                idPost={id._idPost}
                selfRemove={
                  id._idPost.idCompany?.id ==
                  window.sessionStorage.getItem("company")
                }
              />
            ) : (
              <></>
            )}
          </div>
        ))}
    </div>
  );
};

export default Post;
