import Footer from "../../Footer/Footer";
import { secondColor } from "../../environment";
import { UseProductContext } from "../../Context/ProductContext";
import { InputText } from "primereact/inputtext";

const LinkTemplate = () => {
  const { productLink, setProductLink } = UseProductContext();
  return (
    <div className="flex flex-column h-full align-items-center justify-content-space-between">
      <div className="display-flex flex-direction-column justify-content-center h-full max-width-description-template">
        <InputText
          value={productLink}
          maxLength={50}
          className="producer-input-text"
          onChange={(e) => setProductLink(e.target.value)}
        ></InputText>
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={60}
          generalColor={secondColor}
          enableNextButton={true}
        ></Footer>
      </div>
    </div>
  );
};

export default LinkTemplate;
