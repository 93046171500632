// import youtube from "./Assets/youtube.png";
// import instagram from "./Assets/instagram.png";
// import facebook from "./Assets/facebook.png";
import logo1 from "./Assets/logo1.png";
import logo2 from "./Assets/logo2.png";
import "./Home.css";
import "../General.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <div
        style={{
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        {t("common:finanziato")}
      </div>
      <div
        className="display-flex justify-content-center"
        style={{ backgroundColor: "white", marginBottom: "1rem" }}
      >
        <img
          src={logo1}
          alt="logo1"
          style={{ width: "40%", marginRight: "15px" }}
        />
      </div>
      <div
        style={{
          backgroundColor: "#ee7050",
          padding: "1rem",
        }}
        className="display-flex justify-content-start align-items-center flex-direction-column"
      >
        <div className="big-font" style={{ color: "#fff" }}>
          BIZalp
        </div>
        <div style={{ marginTop: "2rem", fontSize: "15px", fontWeight: 600 }}>
          {t("common:bizalpService")}
        </div>
        {/* <div style={{ marginTop: "2rem" }}>
        <img
          src={facebook}
          alt="facebook"
          style={{ width: "30px", marginRight: "1rem" }}
        ></img>
        <img
          src={instagram}
          alt="instagram"
          style={{ width: "30px", marginRight: "1rem" }}
        ></img>
        <img src={youtube} alt="youtube" style={{ width: "30px" }}></img>
      </div> */}
        <div style={{ marginTop: "2rem", fontSize: "15px", fontWeight: 600 }}>
          Corso J. Lombardini 2 – 10066 Torre Pellice (TO) Partita IVA/Codice
          Fiscale: 08435750016 Registro delle Imprese di Torino n. 08435750016
        </div>
        <div style={{ marginTop: "2rem" }}>
          <Link
            style={{
              marginTop: "2rem",
              fontSize: "15px",
              fontWeight: 600,
              color: "#000",
              marginRight: "1rem",
              textDecoration: "none",
            }}
            to="/cookiePolicy"
          >
            Cookie Policy
          </Link>
          <Link
            style={{
              marginTop: "2rem",
              fontSize: "15px",
              fontWeight: 600,
              color: "#000",
              marginRight: "1rem",
              textDecoration: "none",
            }}
            to="/privacyPolicy"
          >
            Privacy Policy
          </Link>
          <Link
            style={{
              marginTop: "2rem",
              fontSize: "15px",
              fontWeight: 600,
              color: "#000",
              marginRight: "1rem",
              textDecoration: "none",
            }}
            to="/forumrules"
          >
            {t("common:forumRules")}
          </Link>
        </div>
        <p
          style={{
            marginTop: "2rem",
            fontSize: "15px",
            fontWeight: 600,
            color: "#000",
            marginRight: "1rem",
            textDecoration: "none",
          }}
        >
          <a
            href="mailto:info@bizalp.org"
            style={{ textDecoration: "none", color: "black" }}
          >
            info@bizalp.org
          </a>
        </p>
        <img src={logo2} alt="logo2" style={{ width: "15%" }} />
      </div>
    </>
  );
};

export default Footer;
