import "./Home.css";
import "../General.css";
import { useTranslation } from "react-i18next";

const OurValues = () => {
  const { t } = useTranslation("common");

  const cards = [
    {
      title: t("area"),
      text: t("areaText"),
      backgroundColor: "#e37545",
    },
    {
      title: t("community"),
      text: t("communityText"),
      backgroundColor: "#c4d37c",
    },
    {
      title: t("culture"),
      text: t("cultureText"),
      backgroundColor: "#4eb1ab",
    },
    {
      title: t("change"),
      text: t("changeText"),
      backgroundColor: "#f0b760",
    },
  ];

  return (
    <div
      className="display-flex flex-direction-column"
      style={{ marginBottom: "5rem" }}
    >
      <div className="big-font our-values-title">{t("ourValues")}</div>
      <div className="ourvalues-card-position">
        {cards.map((card, x) => (
          <div
            key={x}
            style={{ backgroundColor: card.backgroundColor }}
            className="card-style"
          >
            <div className="card-title">{card.title}</div>
            <div className="card-text">{card.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurValues;
