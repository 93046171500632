import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getAllProductImage } from "../../../../../services/ProductsImages/ProductsImages";
import { ImageWithURL } from "../../../../../types/generic.types";
import { getMultipleProductsUrl } from "../../../../../services/File/File";
import ImageCardCheckbox from "./ImageCardCheckbox";

const GalleryNav = ({
  productImages,
  setProductImages,
}: {
  productImages: ImageWithURL[];
  setProductImages: Dispatch<SetStateAction<ImageWithURL[]>>;
}) => {
  const [images, setImages] = useState<ImageWithURL[]>([]);

  useEffect(() => {
    getAllProductImage()
      .then(async (res: any) => {
        if (res.status === 200) {
          getMultipleProductsUrl(res.data)
            .then((resMultiple) => setImages(resMultiple))
            .catch((error) => console.log(error));
        } else console.log(res);
      })
      .catch((error) => console.log(error));
  }, []);

  const checkImage = (image: ImageWithURL) => {
    const selected = productImages
      .map((productImage) => productImage.key === image.key)
      .filter((value) => value !== false)[0];

    return selected ? true : false;
  };

  return (
    <div className="gallery-nav">
      {images.map((image) => (
        <ImageCardCheckbox
          key={image.key}
          filename={image.filename}
          publicKey={image.key}
          url={image.url}
          choosed={checkImage(image)}
          productImages={productImages}
          setProductImages={setProductImages}
        />
      ))}
    </div>
  );
};
export default GalleryNav;
