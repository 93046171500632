import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import italy from "./Assets/icon-italy.png";
import france from "./Assets/icon-france.png";
import { Dropdown } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import { searchUserServiceId } from "../../services/User/UsersServices";

import "./Navbar.css";
import { generateJwt } from "../../services/Utils/Contributi";

interface UserInterface {
  _id: string;
  email: string;
  name: string;
  surname: string;
  userType: number;
  userImage: { url: string; filename: string };
  companies: any;
}

interface Languages {
  name: string;
  code: string;
  image: string;
}

const languages = [
  { name: "Italiano", code: "it", image: italy },
  { name: "French", code: "fr", image: france },
];

const name = window.sessionStorage.getItem("user");

const goToContributiEuropa = async ()=>{
  let url ="https://contributi.bizalp.org"
  if(!!sessionStorage.getItem("userId")){
    let token = await generateJwt();
    url = url + "?token=" + token;
  }
  window.open(url , '_blank');
}

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userId = sessionStorage.getItem("userId");
  const [user, setUser] = useState<UserInterface | undefined>(undefined);
  const { t } = useTranslation("common");
  let nav = useNavigate();
  const menu = useRef<Menu>(null);
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<Languages>({
    name: "Italiano",
    code: "it",
    image: italy,
  });

  useEffect(() => {
    const correctLan = languages.find((l) => i18n.language.includes(l.code));
    if (correctLan != null) {
      setSelectedLanguage(correctLan);
      i18n.changeLanguage(correctLan.code);
    } else {
      setSelectedLanguage(languages[0]);
      i18n.changeLanguage(languages[0].code);
    }
  }, [i18n]);

  const menuProfile: { label: string; command: () => void }[] = useMemo(
    () => [],
    [nav]
  );

  useEffect(() => {
    if (userId == undefined) return;
    searchUserServiceId({ _id: userId }).then((res) => {
      if (res && res.data && res.data.length !== 0) {
        setUser(res.data);
      }
    });
  }, [userId]);

  useEffect(() => {
    if (user) {
      if (user.userType === 1 || user.userType === 2) {
        menuProfile.push(
          {
            label: t("common:Settings"),
            command: () => nav("/profile"),
          },
          {
            label: t("common:Profile"),
            command: () => nav("/profile/producer"),
          },
          {
            label: t("common:Exit"),
            command: () => {
              sessionStorage.clear();
              window.open("/", "_self");
            },
          }
        );
      }
      if (user.userType === 3) {
        menuProfile.push(
          {
            label: "Administrator",
            command: () => nav("/admin"),
          },
          {
            label: t("common:Exit"),
            command: () => {
              sessionStorage.clear();
              window.open("/", "_self");
            },
          }
        );
      }
      // if (window.location.origin.includes("localhost")) {
      //   menuProfile.push({
      //     label: "Administrator",
      //     command: () => nav("/admin"),
      //   });
      // }
    }
  }, [menuProfile, nav, user]);

  const onLanguageChange = useCallback(
    (e: { value: Languages }) => {
      setSelectedLanguage(e.value);
      i18n.changeLanguage(e.value.code);
    },
    [i18n]
  );

  const countryOptionTemplate = (option: Languages) => {
    return (
      <div className="country-item">
        <img alt={option.name} src={option.image} className="flag-size" />
      </div>
    );
  };

  const selectedCountryTemplate = (option: {
    name: string;
    code: string;
    image: string;
  }) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <img alt={option.name} src={option.image} className="flag-size" />
        </div>
      );
    }

    return <span></span>;
  };

  return (
    <div className="Navbar h-8vh">
      <span
        className="nav-toggle big-item no-decoration"
        onClick={() => (window.location.href = "/#/")}
      >
        BIZalp
      </span>
      <div className={`nav-items ${isOpen ? "open" : "close"}`}>
        <span
          style={{ cursor: "pointer" }}
          className={`big-item no-decoration ${
            isOpen ? "navbar-open-title" : ""
          }`}
          onClick={() => (window.location.href = "/#/")}
        >
          BIZalp
        </span>
        <Link
          to="/"
          className="small-item no-decoration"
          onClick={() => setIsOpen(false)}
        >
          Home
        </Link>
        <Link
          to="/philosophy"
          className="small-item no-decoration"
          onClick={() => setIsOpen(false)}
        >
          {t("philosophy")}
        </Link>
        <Link
          to="/products"
          className="small-item no-decoration"
          onClick={() => setIsOpen(false)}
        >
          {t("product")}
        </Link>
        <Link
          to="/company"
          className="small-item no-decoration"
          onClick={() => setIsOpen(false)}
        >
          {t("company")}
        </Link>
        {window.sessionStorage.getItem("idToken") ? (
          <Link
            to="/bulletinboard"
            className="small-item no-decoration"
            onClick={() => setIsOpen(false)}
          >
            {t("bulletinBoard")}
          </Link>
        ) : (
          <></>
        )}
        <Link
          to="/"
          onClick={()=>{
            goToContributiEuropa()
          }}
          className="small-item no-decoration"
        >
          {t("contribution")}
        </Link>

        {isOpen ? (
          <>
            {!name ? (
              <>
                <Link
                  to="/signin"
                  className="small-item no-decoration"
                  onClick={() => setIsOpen(false)}
                >
                  {t("signin")}
                </Link>
                <Link
                  to="/signup"
                  className="small-item no-decoration"
                  onClick={() => setIsOpen(false)}
                >
                  {t("signup")}
                </Link>
              </>
            ) : (
              <>
                {menuProfile.map((menu) => (
                  <a
                    onClick={() => {
                      menu.command();
                      setIsOpen(false);
                    }}
                    className="small-item no-decoration "
                  >
                    {menu.label}
                  </a>
                ))}
              </>
            )}
            <div className="flex margin-flag">
              <div className="margin-flag-right" key={"it"}>
                <img
                  className={` ${
                    selectedLanguage.code === languages[0].code
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => onLanguageChange({ value: languages[0] })}
                  src={italy}
                  alt="italiano"
                ></img>
              </div>
              <div
                key={"fr"}
                onClick={() => onLanguageChange({ value: languages[1] })}
              >
                <img
                  className={
                    selectedLanguage.code === languages[1].code
                      ? "selected"
                      : ""
                  }
                  src={france}
                  alt="francese"
                ></img>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <>
        {user != undefined ? (
          <div className={`nav-items ${isOpen ? "hidden" : "close"}`}>
            <Menu model={menuProfile} popup ref={menu} id="popup_menu" />
            <div
              style={{ cursor: "pointer" }}
              className="small-item"
              onClick={(event) =>
                menu && menu.current && menu.current.toggle(event)
              }
              aria-controls="popup_menu"
              aria-haspopup
            >
              {user.userType !== 3 ? user?.companies[0].name : "Administrator"}
            </div>
            <Dropdown
              value={selectedLanguage}
              options={languages}
              onChange={onLanguageChange}
              optionLabel="name"
              showClear={false}
              autoFocus
              placeholder="Select a language"
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
              dropdownIcon=""
              panelClassName="navbar-dropdown-panel"
            />
          </div>
        ) : (
          <div className={`nav-items ${isOpen ? "hidden" : "close"}`}>
            <Link to="/signin" className="small-item no-decoration">
              {t("signin")}
            </Link>
            <Link
              to="/signup"
              className="small-item no-decoration general-button"
            >
              {t("signup")}
            </Link>
            <Dropdown
              style={{ marginRight: "2em" }}
              value={selectedLanguage}
              options={languages}
              onChange={onLanguageChange}
              optionLabel="name"
              showClear={false}
              autoFocus
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
              dropdownIcon=""
              panelClassName="navbar-dropdown-panel"
            />
          </div>
        )}
      </>

      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? (
          <i className="pi pi-times" style={{ fontSize: "2em", zIndex: 2 }}></i>
        ) : (
          <i className="pi pi-bars" style={{ fontSize: "2em" }}></i>
        )}
      </div>
    </div>
  );
};

export default Navbar;
