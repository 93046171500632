import { Link } from "react-router-dom";
import "../General.css";
import "./Home.css";
import farmers from "./Assets/farmers.png";
import { useTranslation } from "react-i18next";

const Producers = () => {
  const { t } = useTranslation("common");
  return (
    <div className="display-flex flex-direction-row justify-content-space-around producers-position sentence-position">
      <div className="w-30vw text-align-producers">
        <div className="big-font margin-bottom" style={{ marginTop: "2rem" }}>
          {t("producersTitle")}
        </div>
        <div
          className="sentence-join-family margin-bottom small-font mobile-padding"
          style={{ fontWeight: "400" }}
        >
          {t("producersSentence")}
        </div>
        <div className="flex flex-column justify-content-center align-items-center">
          <Link
            to="/company"
            style={{ margin: "1rem" }}
            className="small-font no-decoration general-button producers-button"
          >
            {t("discoverProducers")}
          </Link>
          <Link
            to="/products"
            className="small-font no-decoration general-button producers-button"
          >
            {t("discoverProducts")}
          </Link>
        </div>
      </div>
      <div className="w-30vw display-flex flex-direction-column align-items-center image-producers-position">
        <img src={farmers} alt="farmers" className="sell-veg-image-size"></img>
      </div>
    </div>
  );
};

export default Producers;
