import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { ListBox } from "primereact/listbox";
import { Link } from "react-router-dom";
import { thirdColor, primaryColor } from "../environment";
import {
  createProductCategoryService,
  deleteProductCategoryService,
  getAllProductCategoriesService,
} from "../../services/Product/ProductCategory/ProductServices";
import {
  createProductTypeService,
  deleteProductTypeService,
  getAllProductTypesService,
} from "../../services/Product/ProductType/ProductServices";
import { useEffect, useRef, useState } from "react";
import { SelectItem, SelectItemOptionsType } from "primereact/selectitem";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import {
  deleteProductService,
  getAllproductsService,
} from "../../services/Product/ProductServices";

export const CategoryAndProduct = () => {
  const toast = useRef<Toast>(null);
  const [newProductCatgory, setNewProductCategory] = useState<string>("");
  const [newProductType, setNewProductType] = useState<string>("");
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [productTypesData, setProductTypesData] =
    useState<SelectItemOptionsType>([]);
  const [productCategoriesData, setProductCategoriesData] =
    useState<SelectItemOptionsType>([]);
  const { t } = useTranslation(["common", "error"]);

  const [catSelected, setCatSelected] = useState<SelectItem>({
    value: "",
    label: "",
  });
  const [typeSelected, setTypeSelected] = useState<SelectItem>({
    value: "",
    label: "",
  });

  /**Funzioni */
  const parseData = (data: any) => {
    let catArr: SelectItemOptionsType = [];
    if (data && data.data) {
      data.data.map((cat: any) =>
        catArr.push({ value: cat.id, label: cat.name })
      );
      return catArr;
    }
    return catArr;
  };

  const fetchData = () => {
    getAllProductCategoriesService().then((data) => {
      setProductCategoriesData(parseData(data));
      setCatSelected({ value: "", label: "" });
    });
    getAllProductTypesService().then((data) => {
      setProductTypesData(parseData(data));
      setTypeSelected({ value: "", label: "" });
    });
    getAllproductsService().then((data) => {
      setAllProducts(data.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addCategory = () => {
    if (newProductCatgory.length) {
      createProductCategoryService({ name: newProductCatgory })
        .then(() => {
          fetchData();
          setNewProductCategory("");
        })
        .catch((err) => console.error(err));
    } else
      toast.current?.show({
        severity: "error",
        summary: t("error:missingField"),
        detail: t("error:fillCategory"),
        life: 3000,
      });
  };

  const addType = () => {
    if (newProductType.length)
      createProductTypeService({ name: newProductType.toLowerCase() })
        .then(() => {
          fetchData();
          setNewProductType("");
        })
        .catch((err) => console.error(err));
    else
      toast.current?.show({
        severity: "error",
        summary: t("error:missingField"),
        detail: t("error:fillType"),
        life: 3000,
      });
  };

  const deleteCategory = () => {
    if (catSelected.value !== "" && catSelected.label !== "")
      deleteProductCategoryService({ _id: catSelected.value })
        .then(() => {
          allProducts
            .filter((e) => e.category._id === catSelected.value)
            .forEach((e) => deleteProductService({ _id: e._id }));
          fetchData();
        })
        .catch((err) => console.error(err));
  };
  const deleteType = () => {
    if (typeSelected.value !== "" && typeSelected.label !== "")
      deleteProductTypeService({ _id: typeSelected.value })
        .then(() => {
          allProducts
            .filter((e) => e.type._id === typeSelected.value)
            .forEach((e) => deleteProductService({ _id: e._id }));
          fetchData();
        })
        .catch((err) => console.error(err));
  };

  return (
    <div
      className="flex flex-column  w-full"
      style={{
        backgroundColor: thirdColor,
      }}
    >
      <div className="flex flex-row justify-content-space-between">
        <div
          className="flex flex-column justify-content-center align-items-center"
          style={{ width: "50%" }}
        >
          <h1 style={{ marginBottom: "3rem" }}>{t("common:productConfig")}</h1>
          <div className="flex flex-row align-items-center ">
            <div
              className="general-button"
              onClick={addCategory}
              style={{ marginLeft: "1rem" }}
            >
              {t("common:addProduct")}
            </div>
            <Tooltip
              target={document.getElementById("cat-info-icon") || ""}
              position={"top"}
              content={t("common:examples")}
            />
            <i
              id="cat-info-icon"
              style={{ color: primaryColor, marginLeft: "1rem" }}
              className="pi pi-info-circle"
            ></i>
          </div>
          <div style={{ margin: "2rem" }}>
            <InputText
              placeholder={t("common:agricolo")}
              value={newProductCatgory}
              onChange={(e) => setNewProductCategory(e.target.value)}
            />
          </div>
          <div className="display-flex flex-direction-row align-items-center ">
            <div className="general-button" onClick={addType}>
              {t("common:addProduct")}{" "}
            </div>
            <Tooltip
              target={document.getElementById("info-icon-type") || ""}
              position={"top"}
              content={"Esempio : Mele Renette, Mele X, Pera Y o Kiwi"}
            />
            <i
              id="info-icon-type"
              style={{ color: primaryColor, marginLeft: "1rem" }}
              className="pi pi-info-circle"
            ></i>
          </div>
          <div style={{ margin: "2rem" }}>
            <InputText
              placeholder={t("common:apple")}
              value={newProductType}
              onChange={(e) => setNewProductType(e.target.value)}
            />
          </div>
        </div>

        <div
          className="display-flex flex-direction-column justify-content-center align-items-center"
          style={{ width: "50%" }}
        >
          <div style={{ margin: "1rem" }}> {t("common:productCat")} </div>
          <div className="display-flex flex-direction-row justify-content-center align-items-center">
            <ListBox
              optionLabel="label"
              value={undefined}
              options={productCategoriesData.sort(function (a, b) {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              })}
              onChange={(e) =>
                setCatSelected({
                  value: e.value,
                  label: productCategoriesData
                    ? productCategoriesData.find((opt) => opt.value === e.value)
                        .label
                    : "",
                })
              }
              style={{ width: "15rem", marginRight: "2rem" }}
              listStyle={{ height: "250px" }}
            />
            {catSelected.value !== "" ? (
              <i
                onClick={deleteCategory}
                style={{ color: primaryColor, cursor: "pointer" }}
                className="pi pi-trash"
              ></i>
            ) : null}
          </div>
          <div style={{ margin: "1rem" }}> {t("common:prodType")} </div>
          <div className="display-flex flex-direction-row justify-content-center align-items-center">
            <ListBox
              optionLabel="label"
              value={undefined}
              options={productTypesData.sort(function (a, b) {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              })}
              onChange={(e) =>
                setTypeSelected({
                  value: e.value,
                  label: productTypesData
                    ? productTypesData.find((opt) => opt.value === e.value)
                        .label
                    : "",
                })
              }
              style={{ width: "15rem", marginRight: "2rem" }}
              listStyle={{ height: "250px" }}
            />
            {typeSelected.value !== "" ? (
              <i
                onClick={deleteType}
                style={{ color: primaryColor, cursor: "pointer" }}
                className="pi pi-trash"
              ></i>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row justify-content-center">
        <Link to="/addproduct" className="add-product-button">
          <i
            style={{
              color: primaryColor,
            }}
            className="pi pi-plus pi-plus-style"
          ></i>
          {t("common:newProduct")}
        </Link>
      </div>
      <Toast ref={toast} position="top-right" />
    </div>
  );
};
