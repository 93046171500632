import { axiosWithAuth, axiosWithUserId } from "../axiosWithAuth";
import { API_BASE_URL } from "../env";
import {
  CREATE_COMMENT,
  DELETE_COMMENT,
  GET_ALL_COMMENTS,
  SEARCH_COMMENT,
  UPDATE_COMMENT,
} from "./api";

export const getAllPosts = async () => {
  let server = API_BASE_URL + GET_ALL_COMMENTS.path;
  return await axiosWithAuth(server, {
    method: GET_ALL_COMMENTS.method,
  });
};

export const createComment = async (requestBody?: any) => {
  let server = API_BASE_URL + CREATE_COMMENT.path;
  let body = requestBody ? requestBody : CREATE_COMMENT.requestBody;
  return await axiosWithAuth(server, {
    method: CREATE_COMMENT.method,
    data: { ...body },
  }).catch((error) => console.log(error));
};

export const deleteComment = async (requestBody?: any) => {
  let server = API_BASE_URL + DELETE_COMMENT.path;
  let body = requestBody ? requestBody : DELETE_COMMENT.requestBody;
  return await axiosWithUserId(server, {
    method: DELETE_COMMENT.method,
    data: { ...body },
  });
};

export const updateComment = async (requestBody?: any) => {
  let server = API_BASE_URL + UPDATE_COMMENT.path;
  let body = requestBody ? requestBody : UPDATE_COMMENT.requestBody;
  return await axiosWithAuth(server, {
    method: UPDATE_COMMENT.method,
    data: { ...body },
  });
};

export const searchComment = async (params?: any) => {
  let server = API_BASE_URL + SEARCH_COMMENT.path;
  let queryParams = params ? params : SEARCH_COMMENT.params;
  return await axiosWithAuth(server, {
    method: SEARCH_COMMENT.method,
    params: queryParams,
  });
};
