import { axiosWithAuth } from "../axiosWithAuth";
import { API_BASE_URL } from "../env";
import {
  CREATE_PRODUCTS_IMAGE,
  DELETE_PRODUCTS_IMAGE,
  GET_ALL_PRODUCTS_IMAGE,
} from "./api";

export const getAllProductImage = async () => {
  let server = API_BASE_URL + GET_ALL_PRODUCTS_IMAGE.path;
  return await axiosWithAuth(server, {
    method: GET_ALL_PRODUCTS_IMAGE.method,
  });
};

export const createProductImage = async (requestBody?: any) => {
  let server = API_BASE_URL + CREATE_PRODUCTS_IMAGE.path;
  let body = requestBody ? requestBody : CREATE_PRODUCTS_IMAGE.requestBody;
  return await axiosWithAuth(server, {
    method: CREATE_PRODUCTS_IMAGE.method,
    data: { ...body },
  });
};

export const deleteProductImage = async (requestBody?: any) => {
  let server = API_BASE_URL + DELETE_PRODUCTS_IMAGE.path;
  let body = requestBody ? requestBody : DELETE_PRODUCTS_IMAGE.requestBody;
  return await axiosWithAuth(server, {
    method: DELETE_PRODUCTS_IMAGE.method,
    data: { ...body },
  });
};
