export const GET_ALL_COMMENTS = {
  path: "Comment/getAllComments",
  method: "GET",
};

export const CREATE_COMMENT = {
  path: "Comment/createComment",
  method: "POST",
  requestBody: {
    idUser: "Jhon",
    comment: "Jhon",
  },
};

export const DELETE_COMMENT = {
  path: "Comment/deleteComment",
  method: "DELETE",
  requestBody: {
    _id: "id",
  },
};

export const UPDATE_COMMENT = {
  path: "Comment/updateComment",
  method: "PATCH",
  requestBody: {
    _id: "id",
  },
};

export const SEARCH_COMMENT = {
  path: "Comment/updateComment",
  method: "GET",
  params: {
    _id: "id",
  },
};
