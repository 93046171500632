import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Carousel } from "primereact/carousel";
import { useEffect, useRef, useState } from "react";
import { CompanyProfile } from "../../../../models/Company/company";
import {
  searchAvailableSuppliersService,
  updateCompanySuppliers,
} from "../../../../services/Supplier/SupplierService";
import SupplierCard from "../../../ProductsPage/componentsProducts/SupplierCard/SupplierCard";
import { showError, showSuccess } from "../../../Toast/Toast.functions";
import "./ProfileSupplier.css";
import RenderSuppliers from "./RenderSuppliers";
import { useTranslation } from "react-i18next";

interface IProps {
  suppliers: CompanyProfile[];
  newSupplier: boolean;
}

const ProfileSupplier = (props: IProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [availableSuppliers, setAvailableSuppliers] = useState<any[]>([]);
  const [choosedSuppliers, setChoosedSuppliers] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [uncheckAll, setUncheckAll] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    searchAvailableSuppliersService({
      input: sessionStorage.getItem("company"),
    }).then((data) => {
      let available = data.data.map((company: any) => {
        return {
          companyId: company._id,
          name: company.name,
          selected: false,
          images: company.pictures,
        };
      });
      setAvailableSuppliers(available);
    });
  }, []);

  const submitUpdatedSuppliers = () => {
    updateCompanySuppliers(choosedSuppliers, {
      input: sessionStorage.getItem("company"),
    })
      .then(() => {
        showSuccess(toast, t("common:addedWithSuccess"));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        showError(toast, t("common:genericError"));
      });
  };

  const dialogFooterContent = (
    <div className="flex flex-row justify-content-space-between">
      <Button
        label={checkAll ? t("common:unpickAll") : t("common:pickAll")}
        onClick={() => {
          setUncheckAll(checkAll);
          setCheckAll(!checkAll);
        }}
        className="small-item no-decoration general-button"
      ></Button>
      <Button
        name="confirmAdmin"
        className="small-item no-decoration general-button"
        label={t("common:modifyCompanies")}
        onClick={() => {
          choosedSuppliers.length > 0
            ? submitUpdatedSuppliers()
            : showError(toast, t("common:fornitoriError"));
        }}
      ></Button>
    </div>
  );

  const supplierTemplate = (supplier: any) => {
    return (
      <div
        key={supplier._id}
        className="product-item"
        style={{ marginBottom: "1rem" }}
      >
        <div className="product-item-content">
          <SupplierCard supplier={supplier} />
        </div>
      </div>
    );
  };

  const responsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "960px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "768px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <div
        className="display-flex justify-content-center align-items-center flex-direction-column"
        style={{ backgroundColor: "#f1f1f0" }}
      >
        <span className="medium-font my-4">{t("common:fornitori")}</span>
        {props.newSupplier ? (
          <Button
            type="submit"
            className="small-item no-decoration general-button"
            style={{ margin: "1rem" }}
            label={t("common:modifyCompanies")}
            onClick={() => {
              setVisible(true);
            }}
          ></Button>
        ) : (
          <></>
        )}

        <div className="carousel-demo">
          {props.suppliers && props.suppliers.length ? (
            <Carousel
              value={props.suppliers}
              numVisible={
                props.suppliers.length > 2 ? 3 : props.suppliers.length
              }
              numScroll={1}
              itemTemplate={supplierTemplate}
              responsiveOptions={responsiveOptions}
            />
          ) : (
            <div className="empty-products">{t("common:emptyFornitori")}</div>
          )}
        </div>
      </div>

      <Dialog
        header={t("common:pickFornitori")}
        visible={visible}
        footer={dialogFooterContent}
        onHide={() => {
          setVisible(false);
          setCheckAll(false);
          setUncheckAll(false);
        }}
      >
        {availableSuppliers.length > 0 ? (
          availableSuppliers.map((supplier) => (
            <RenderSuppliers
              key={supplier.companyId}
              numMax={availableSuppliers.length}
              setPickAll={setCheckAll}
              setUnpickAll={setUncheckAll}
              unpickAll={uncheckAll}
              pickAll={checkAll}
              picked={props.suppliers}
              supplier={supplier}
              choosedSuppliers={choosedSuppliers}
              setChoosedSuppliers={setChoosedSuppliers}
            />
          ))
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
};

export default ProfileSupplier;
