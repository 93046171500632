export const EP_GET__SUPPLIERS_BY_COMPANY = {
  path: "supplier/getSuppliersByCompany",
  method: "GET",
  params: {
    companyId : "0"
  }
};

export const EP_GET_AVAILABLE_SUPPLIERS = {
  path: "supplier/getAvailableSuppliers",
  method: "GET"
}

export const EP_UPDATE_COMPANY_SUPPLIERS = {
  path: "supplier/updateCompanySuppliers",
  method: "PATCH",
  params: {
    companyId : "0"
  }
}
