import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createImagesURLs,
  dropEvent,
  onDragOverFunction,
} from "../../Functions/ImageFunctions";
import { ShowImages } from "../../Producers/ImageTemplate/ShowImages";
import { UploadImages } from "../../Producers/ImageTemplate/UploadImages";

const DialogFileForum = ({
  visible,
  setVisible,
  images,
  setImages,
  imagesURL,
  setImagesURL,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  images: any[];
  setImages: Dispatch<SetStateAction<any[]>>;
  imagesURL: any[];
  setImagesURL: Dispatch<SetStateAction<any[]>>;
}) => {
  const [numOfFiles, setNumOfFiles] = useState<number>(images.length);
  const toast = useRef<Toast>(null);
  const { t } = useTranslation(["imagesSentence", "common"]);

  useEffect(() => {
    createImagesURLs(images, setImagesURL);
  }, [images]);

  return (
    <Dialog
      onHide={() => {
        setVisible(false);
      }}
      closable={images.length < 1}
      visible={visible}
      footer={
        <>
          {images.length > 0 ? (
            <Button
              className="general-button"
              onClick={() => setVisible(false)}
            >
              {t("common:upload")}
            </Button>
          ) : (
            <></>
          )}
        </>
      }
    >
      <div className="flex flex-column h-full justify-content-space-between">
        <div className="flex align-items-center h-full">
          <div
            id="div-card-image"
            className="card-image"
            onDrop={(event) =>
              dropEvent(
                event,
                toast,
                numOfFiles,
                setNumOfFiles,
                images,
                setImages,
                5,
                t
              )
            }
            onDragOver={onDragOverFunction}
            draggable={true}
          >
            {images.length > 0 ? (
              <>
                <ShowImages
                  images={images}
                  imagesUrls={imagesURL}
                  numOfFiles={numOfFiles}
                  setImages={setImages}
                  setImagesUrls={setImagesURL}
                  setNumOfFiles={setNumOfFiles}
                />
                {images.length < 5 ? (
                  <UploadImages
                    toastError={toast}
                    numberFile={5}
                    numOfFiles={numOfFiles}
                    setNumOfFiles={setNumOfFiles}
                    images={images}
                    setImages={setImages}
                    firstSentence={""}
                    secondSentence={""}
                    thirdSentence={
                      5 - numOfFiles > 0
                        ? `${t("imagesSentence:uploadOtherImages")} ${
                            5 - numOfFiles
                          } ${t("imagesSentence:images")}
                    `
                        : ""
                    }
                    showImage={false}
                    acceptFile={true}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <UploadImages
                toastError={toast}
                numberFile={5}
                numOfFiles={numOfFiles}
                setNumOfFiles={setNumOfFiles}
                images={images}
                setImages={setImages}
                firstSentence={t("imagesSentence:firstSentence")}
                secondSentence={
                  t("imagesSentence:secondSentence") +
                  5 +
                  " " +
                  t("imagesSentence:photo")
                }
                thirdSentence={t("imagesSentence:thirdSentence")}
                showImage={true}
                acceptFile={true}
              />
            )}
          </div>
        </div>
      </div>
      <Toast ref={toast}></Toast>
    </Dialog>
  );
};
export default DialogFileForum;
