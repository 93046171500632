import { useEffect, useState } from "react";
import MapView from "./MapLeaflet/MapView";
import Navbar from "../../../Navbar/Navbar";
import { getAllCompaniesService } from "../../../../services/Company/CompanyServices";
import { company } from "../../../../models/Company/company";
import "../../Products.css";

const ProductsMap = () => {
  const [company, setCompany] = useState<company[]>([]);

  useEffect(() => {
    getAllCompaniesService().then((data) => setCompany(data.data));
  }, []);

  return (
    <>
      <Navbar />
      <MapView company={company} />
    </>
  );
};

export default ProductsMap;
