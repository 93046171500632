import { InputText } from "primereact/inputtext";
import Footer from "../../Footer/Footer";
import { UseProducerContext } from "../../Context/ProducersContext";
import { primaryColor } from "../../environment";
import { InputMask } from "primereact/inputmask";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import "./OtherData.css";
import { Checkbox } from "primereact/checkbox";
import { useState } from "react";
import { Link } from "react-router-dom";

const OtherData = () => {
  const {
    producerVAT,
    setProducerVAT,
    producerPhone,
    setProducerPhone,
    producerWebSite,
    setProducerWebsite,
  } = UseProducerContext();

  const { i18n, t } = useTranslation();

  const [checked, setChecked] = useState<boolean>(false);
  const [checkedForum, setCheckedForum] = useState<boolean>(false);

  return (
    <>
      <div className="display-flex flex-direction-column h-full justify-content-center">
        <>
          <label htmlFor="VAT" className="label">
            {t("common:iva")}*
          </label>
          <InputMask
            value={producerVAT}
            className={`input-text-style `}
            id="VAT"
            mask={
              i18n.language === "it" ? "IT 99999999999" : "FR 99999999999?999"
            }
            placeholder={
              i18n.language === "it" ? "IT 99999999999" : "FR 99999999999"
            }
            onChange={(e) => {
              setProducerVAT(e.target.value);
            }}
          ></InputMask>
        </>
        <>
          <label htmlFor="phone" className="label">
            {t("common:phone")}*
          </label>
          <InputMask
            value={producerPhone}
            className={`input-text-style `}
            id="phone"
            mask={
              i18n.language === "it" ? "+39 999 999 9999" : "+33-9-99-99-99-99"
            }
            placeholder={
              i18n.language === "it" ? "+39 999 999 9999" : "+33-9-99-99-99-99"
            }
            onChange={(e) => {
              setProducerPhone(e.target.value);
            }}
          ></InputMask>
        </>
        <>
          <label
            htmlFor="VAT"
            className={classNames({
              label: true,
            })}
          >
            {t("common:website")}
          </label>
          <InputText
            value={producerWebSite}
            placeholder="www.example.com"
            className="input-text-style"
            onChange={(e) => setProducerWebsite(e.target.value)}
          ></InputText>
        </>
        <div className="field-checkbox">
          <Checkbox
            inputId="accept"
            name="accept"
            checked={checked}
            onChange={() => {
              localStorage.removeItem("privacy_policy_accepeted");
              setChecked(!checked);
              localStorage.setItem("privacy_policy_accepeted", !checked + "");
            }}
          />
          <label htmlFor="accept" style={{ fontSize: "12px" }}>
            {`${t("signin:privacyPolicy")}`}
            <Link to="/privacyPolicy" target={"_blank"}>
              Privacy Policy*{/**i18n */}
            </Link>
          </label>
        </div>
        <div className="field-checkbox">
          <Checkbox
            inputId="forum"
            name="forum"
            checked={checkedForum}
            onChange={() => {
              localStorage.removeItem("forum_rules_accepeted");
              setCheckedForum(!checkedForum);
              localStorage.setItem("forum_rules_accepeted", !checkedForum + "");
            }}
          />
          <label htmlFor="forum" style={{ fontSize: "12px" }}>
            {`${t("signin:forumRulesAccept")}`}
            <Link to="/forumrules" target={"_blank"}>
              {`${t("signin:forumRulesLink")}`}
            </Link>
          </label>
        </div>
        <span style={{ textAlign: "end", marginBottom: "1rem" }}>
          *{t("common:requiredFields")}
        </span>
      </div>
      <div className="footer-position">
        <Footer
          generalColor={primaryColor}
          progressbarValue={80}
          enableNextButton={
            producerPhone !== "" &&
            producerVAT !== "" &&
            checked &&
            checkedForum
          }
        ></Footer>
      </div>
    </>
  );
};

export default OtherData;
