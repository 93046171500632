export const GET_ALL_BULLETINBOARDS = {
  path: "BulletinBoard/getAllBulletinBoards",
  method: "GET",
};

export const CREATE_BULLETIN_BOARD = {
  path: "BulletinBoard/createBulletinBoard",
  method: "POST",
  requestBody: {
    _idPost: "id",
  },
};

export const DELETE_BULLETIN_BOARD = {
  path: "BulletinBoard/deleteBulletinBoard",
  method: "DELETE",
  requestBody: {
    _idPost: "id",
  },
};

export const DELETE_SELF_BULLETIN_BOARD = {
  path: "BulletinBoard/deleteSelfBulletinBoard",
  method: "DELETE",
  requestBody: {
    _idPost: "id",
  },
};

export const UPDATE_BULLETIN_BOARD = {
  path: "BulletinBoard/updateBulletinBoard",
  method: "PATCH",
  requestBody: {
    _idPost: "id",
  },
};

export const SEARCH_BULLETIN_BOARD = {
  path: "BulletinBoard/searchBulletinBoard",
  method: "GET",
  params: {
    _idPost: "id",
  },
};
