import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { company, CompanyProfile } from "../../../../models/Company/company";
import { product } from "../../../../models/Product/product";
import { updateCompanyService } from "../../../../services/Company/CompanyServices";
import { getMultipleProductsUrlFromCompany } from "../../../../services/File/File";
import { getAllproductsService } from "../../../../services/Product/ProductServices";
import { showError } from "../../../Toast/Toast.functions";
import ProductsButton from "./ProductsButton";

const DialogProducts = ({
  visible,
  setVisible,
  header,
  company,
  products,
  setProducts,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  header: string;
  company: CompanyProfile;
  products: product[];
  setProducts: Dispatch<SetStateAction<product[]>>;
}) => {
  const [availableProducts, setAvailableProducts] = useState<product[]>([]);
  const toast = useRef<Toast>(null);
  const { t } = useTranslation("common");

  const closeDialog = () => {
    const companyModificata: company = {
      _id: company.id,
      name: company.name,
      description: company.description,
      address: company.producerPosition,
      pictures: company.producerImages,
      logo: company.producerLogo,
      email: company.producerEmail,
      payment: company.producerPayment,
      phone: company.producerPhone,
      vat: company.producerVat,
      website: company.producerWebSite,
      values: company.producerValues,
      companyType: company.producerType,
      users: company.producerUsers,
      products: products,
    };

    updateCompanyService(companyModificata).then((res: any) => {
      if (res.status === 200) setVisible(false);
      else {
        showError(toast, t("genericError"));
      }
    });
  };

  useEffect(() => {
    getAllproductsService()
      .then((res: any) => {
        if (res.status === 200) {
          getMultipleProductsUrlFromCompany(res.data).then(
            (responseProducts) => {
              setAvailableProducts(responseProducts);
            }
          );
        } else {
          showError(toast, t("genericError"));
        }
      })
      .catch(() => {
        showError(toast, t("genericError"));
      });
  }, []);

  return (
    <>
      <Dialog
        footer={
          <div
            className="flex flex-row justify-content-space-between"
            style={{ paddingTop: "1rem", alignItems: "center" }}
          >
            <label className="mailto" style={{ cursor: "pointer" }}>
              <a
                style={{ color: "#232323", cursor: "pointer" }}
                href="mailto:info@bizalp.org"
              >
                {t("common:askProduct")}
              </a>
            </label>
            <Button className="general-button" onClick={closeDialog}>
              {t("common:confirm")}
            </Button>
          </div>
        }
        visible={visible}
        closable={false}
        onHide={() => null}
        className="w-11 h-full md:w-6"
      >
        <div className="flex flex-column gap-2">
          {availableProducts.map((availableProduct: product) => (
            <ProductsButton
              availableProduct={availableProduct}
              key={availableProduct.id}
              choosedProducts={products}
              setChoosedProducts={setProducts}
            />
          ))}
        </div>
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default DialogProducts;
