import { axiosWithAuth, axiosWithUserId } from "../axiosWithAuth";
import { API_BASE_URL } from "../env";
import {
  CREATE_BULLETIN_BOARD,
  DELETE_BULLETIN_BOARD,
  DELETE_SELF_BULLETIN_BOARD,
  GET_ALL_BULLETINBOARDS,
  SEARCH_BULLETIN_BOARD,
  UPDATE_BULLETIN_BOARD,
} from "./api";

export const getAllBulletinBoards = async () => {
  let server = API_BASE_URL + GET_ALL_BULLETINBOARDS.path;
  return await axiosWithAuth(server, {
    method: GET_ALL_BULLETINBOARDS.method,
  });
};

export const createBulletinBoards = async (requestBody?: any) => {
  let server = API_BASE_URL + CREATE_BULLETIN_BOARD.path;
  let body = requestBody ? requestBody : CREATE_BULLETIN_BOARD.requestBody;
  return await axiosWithAuth(server, {
    method: CREATE_BULLETIN_BOARD.method,
    data: { ...body },
  }).catch((error) => console.log(error));
};

export const deleteBulletinBoards = async (requestBody?: any) => {
  let server = API_BASE_URL + DELETE_BULLETIN_BOARD.path;
  let body = requestBody ? requestBody : DELETE_BULLETIN_BOARD.requestBody;
  return await axiosWithUserId(server, {
    method: DELETE_BULLETIN_BOARD.method,
    data: { ...body },
  });
};

export const deleteSelfBulletinBoards = async (requestBody?: any) => {
  let server = API_BASE_URL + DELETE_SELF_BULLETIN_BOARD.path;
  let body = requestBody ? requestBody : DELETE_BULLETIN_BOARD.requestBody;
  return await axiosWithUserId(server, {
    method: DELETE_BULLETIN_BOARD.method,
    data: { ...body },
  });
};

export const updateBulletinBoards = async (requestBody?: any) => {
  let server = API_BASE_URL + UPDATE_BULLETIN_BOARD.path;
  let body = requestBody ? requestBody : UPDATE_BULLETIN_BOARD.requestBody;
  return await axiosWithAuth(server, {
    method: UPDATE_BULLETIN_BOARD.method,
    data: { ...body },
  });
};

export const searchBulletinBoards = async (params?: any) => {
  let server = API_BASE_URL + SEARCH_BULLETIN_BOARD.path;
  let queryParams = params ? params : SEARCH_BULLETIN_BOARD.params;
  return await axiosWithAuth(server, {
    method: SEARCH_BULLETIN_BOARD.method,
    params: queryParams,
  });
};
