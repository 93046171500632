import { primaryColor } from "../../environment";
import Footer from "../../Footer/Footer";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import {
  getMultipleUrl,
  getUrlAndUploadFileMultiple,
} from "../../../services/File/File";
import { UsePaginatorContext } from "../../Context/PagesContext";
import { UseProducerContext } from "../../Context/ProducersContext";
import { showError, showSuccess } from "../../Toast/Toast.functions";
import {
  createImagesURLs,
  dropEvent,
  onDragOverFunction,
} from "../../Functions/ImageFunctions";
import AmazonImages from "./AmazonImages";
import "./ImageTemplate.css";
import { ShowImages } from "./ShowImages";
import { UploadImages } from "./UploadImages";
import { useTranslation } from "react-i18next";
import { ImageWithURL } from "../../../types/generic.types";
import { LoadingImage } from "../../LoadingImage/LoadingImage";

const ImageTemplate = () => {
  const { currentPage, setCurrentPage } = UsePaginatorContext();
  const { producerImages, setProducerImages } = UseProducerContext();
  /** Contiene tutte le immagini caricate */
  const [images, setImages] = useState<any>([]);
  /** Contiene gli url di tutte le immagini caricate */
  const [imagesUrls, setImagesUrls] = useState<any[]>([]);
  /** Contiene l'url e il filename per visualizzare la foto*/
  const [amazonUrls, setAmazonUrls] = useState<ImageWithURL[]>([]);
  const [numOfFiles, setNumOfFiles] = useState(producerImages.length);
  const toastError = useRef<Toast>(null);
  const [buttonText, setButtonText] = useState(
    producerImages.length > 0 ? "next" : "upload"
  );
  const { t } = useTranslation(["common", "imagesSentence"]);
  const [visibleDialog, setVisibleDialog] = useState(false);

  /** Creo tutte gli url delle immagini caricate e le inserisco nell'array newImagesURL */
  useEffect(() => {
    createImagesURLs(images, setImagesUrls);
    if (images.length > 0) setButtonText("upload");
  }, [images]);

  useEffect(() => {
    if (producerImages.length === 0) return;
    getMultipleUrl(producerImages)
      .then((image) => setAmazonUrls([...image]))
      .catch(() => showError(toastError, t("imagesSentence:errorGetFoto")));
  }, [producerImages, t]);

  const uploadFileApi = () => {
    if (buttonText === "upload") {
      setVisibleDialog(true);
      getUrlAndUploadFileMultiple(images)
        .then((image) => {
          setProducerImages([...producerImages, ...image]);
          setAmazonUrls([...amazonUrls, ...image]);
          setImages([]);
          setImagesUrls([]);
          setButtonText("next");
          setVisibleDialog(false);
          showSuccess(toastError, t("imagesSentence:imagesUploadedSuccess"));
        })
        .catch(() =>
          showError(toastError, t("imagesSentence:errorUploadFoto"))
        );
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className="flex flex-column h-full justify-content-space-between">
        <div className="flex align-items-center h-full">
          <div
            id="div-card-image"
            className="card-image"
            onDrop={(event) =>
              dropEvent(
                event,
                toastError,
                numOfFiles,
                setNumOfFiles,
                images,
                setImages,
                5,
                t
              )
            }
            onDragOver={onDragOverFunction}
            draggable={true}
          >
            {amazonUrls.length > 0 ? (
              <AmazonImages
                amazonUrls={amazonUrls}
                numOfFiles={numOfFiles}
                setAmazonUrls={setAmazonUrls}
                producerImages={producerImages}
                setProducerImages={setProducerImages}
                setNumOfFiles={setNumOfFiles}
                buttonText={buttonText}
                setButtonText={setButtonText}
                toastError={toastError}
              />
            ) : (
              <></>
            )}

            {imagesUrls.length > 0 ? (
              <>
                <ShowImages
                  images={images}
                  setImages={setImages}
                  imagesUrls={imagesUrls}
                  setImagesUrls={setImagesUrls}
                  numOfFiles={numOfFiles}
                  setNumOfFiles={setNumOfFiles}
                />
                {imagesUrls.length < 5 ? (
                  <>
                    <UploadImages
                      toastError={toastError}
                      numberFile={5}
                      numOfFiles={numOfFiles}
                      setNumOfFiles={setNumOfFiles}
                      images={images}
                      setImages={setImages}
                      firstSentence={""}
                      secondSentence={""}
                      thirdSentence={
                        5 - numOfFiles > 0
                          ? `${t("imagesSentence:uploadOtherImages")} ${
                              5 - numOfFiles
                            } ${t("imagesSentence:images")}
                      `
                          : ""
                      }
                      showImage={false}
                      setButtonText={setButtonText}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : amazonUrls.length > 0 ? (
              <div
                className="display-flex flex-direction-column "
                style={{ overflowY: "auto", width: "100%" }}
              >
                <UploadImages
                  toastError={toastError}
                  numberFile={5}
                  numOfFiles={numOfFiles}
                  setNumOfFiles={setNumOfFiles}
                  images={images}
                  setImages={setImages}
                  firstSentence={""}
                  secondSentence={""}
                  thirdSentence={
                    5 - numOfFiles > 0
                      ? `${t("imagesSentence:uploadOtherImages")} ${
                          5 - numOfFiles
                        } ${t("imagesSentence:images")}
                    `
                      : ""
                  }
                  showImage={false}
                />
              </div>
            ) : (
              <UploadImages
                toastError={toastError}
                numberFile={5}
                numOfFiles={numOfFiles}
                setNumOfFiles={setNumOfFiles}
                images={images}
                setImages={setImages}
                firstSentence={t("imagesSentence:firstSentence")}
                secondSentence={
                  t("imagesSentence:secondSentence") +
                  5 +
                  " " +
                  t("imagesSentence:photo")
                }
                thirdSentence={t("imagesSentence:thirdSentence")}
                showImage={true}
              />
            )}
          </div>
        </div>
        <div className="footer-position">
          <Footer
            progressbarValue={70}
            generalColor={primaryColor}
            enableNextButton={imagesUrls.length > 0 || amazonUrls.length > 0}
            nextPageText={
              buttonText === "upload" ? t("common:upload") : t("common:next")
            }
            setFinish={uploadFileApi}
          ></Footer>
        </div>
        <LoadingImage visible={visibleDialog} />
      </div>
      <Toast ref={toastError} position="top-right" />
    </>
  );
};

export default ImageTemplate;
