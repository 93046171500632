import { axiosWithAuth } from "../axiosWithAuth";
import { API_BASE_URL } from "../env";
import { CREATE_NOTIFY } from "./api";

export const createNotify = async (requestBody?: any) => {
  let server = API_BASE_URL + CREATE_NOTIFY.path;
  let body = requestBody ? requestBody : CREATE_NOTIFY.requestBody;
  return await axiosWithAuth(server, {
    method: CREATE_NOTIFY.method,
    data: body,
  });
};
