import "./RegisterProducers.css";
import "../General.css";
import CardTemplate from "./CardTemplate/CardTemplate";
import MapTemplate from "./MapTemplate/MapTemplate";
import ProducerName from "./ProducerName/ProducerName";
import DescriptionTemplate from "./DescriptionTemplate/DescriptionTemplate";
import ImageTemplate from "./ImageTemplate/ImageTemplate";
import OtherData from "./OtherData/OtherData";
import { UsePaginatorContext } from "../Context/PagesContext";
import FirstPage from "./FirstPage/FirstPage";
import LogoTemplate from "./ImageTemplate/LogoTemplate";
import Values from "./Values/Values";
import EmailCompany from "./EmailCompany/EmailCompany";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

let colorBackground = "#ee7050";

const SwitchComponent = () => {
  const { setPageTitle, currentPage } = UsePaginatorContext();
  const { t } = useTranslation("common");
  switch (currentPage) {
    case 1:
      const values = [
        { value: "products", label: t("common:products"), type: 1 },
        { value: "services", label: t("common:services"), type: 2 },
        {
          value: "productsServices",
          label: t("common:productsServices"),
          type: 3,
        },
      ];
      setPageTitle(t("common:selection"));
      return <CardTemplate values={values}></CardTemplate>;
    case 2:
      setPageTitle(t("common:place"));
      return <MapTemplate></MapTemplate>;
    case 3:
      setPageTitle(t("common:name"));
      return <ProducerName></ProducerName>;
    case 4:
      setPageTitle(t("common:description"));
      return <DescriptionTemplate></DescriptionTemplate>;
    case 5:
      setPageTitle(t("common:credentials"));
      return <EmailCompany />;
    case 6:
      setPageTitle(t("common:logo"));
      return <LogoTemplate />;
    case 7:
      setPageTitle(t("common:photos"));
      return <ImageTemplate></ImageTemplate>;
    case 8:
      setPageTitle(t("common:infos"));
      return <OtherData></OtherData>;
    case 9:
      setPageTitle(t("common:values"));
      return <Values />;
    default:
      return <></>;
  }
};

const RegisterProducers = () => {
  const { pageTitle, currentPage } = UsePaginatorContext();

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <div className="registerProducersWrapper">
      {currentPage === 0 ? (
        <FirstPage />
      ) : (
        <>
          <div
            style={{
              backgroundColor: colorBackground,
            }}
            className="flex flex-column align-items-center wrap title-wrapper"
          >
            <p className="medium-font text-color-producers mobile-title">
              BIZalp
            </p>
            <div className="big-font text-color-producers title-position">
              {pageTitle}
            </div>
          </div>
          <div className="flex flex-column align-items-center producer-content-position">
            <SwitchComponent />
          </div>
        </>
      )}
    </div>
  );
};

export default RegisterProducers;
