import "leaflet/dist/leaflet.css";
import Footer from "../../Footer/Footer";
import { primaryColor } from "../../environment";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { UseProducerContext } from "../../Context/ProducersContext";
import { UsePaginatorContext } from "../../Context/PagesContext";
import "./MapTemplate.css";
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../environment";
import { useTranslation } from "react-i18next";

const MapTemplate = () => {
  const { producerPosition, setProducerPosition } = UseProducerContext();
  const { currentPage, setCurrentPage } = UsePaginatorContext();
  const [address, setAddress] = useState<string>(producerPosition.label);
  const [place, setPlace] = useState<any[]>([]);
  const [searchBox, setSearchBox] = useState<any>(null);
  const { t } = useTranslation("common");
  const [enableNextButton, setEnableNextButton] = useState(
    producerPosition.lat ? true : false
  );

  const onPlacesChanged = () => {
    const place = searchBox?.getPlaces();
    setAddress(place.name);
    setPlace(place);
    setEnableNextButton(true);
  };

  const onSBLoad = (ref: any) => {
    setSearchBox(ref);
  };

  const setPosition = () => {
    if (place) {
      setProducerPosition({
        label: place[0].formatted_address,
        lat: place[0].geometry.location.lat(),
        lon: place[0].geometry.location.lng(),
      });
    } else {
      setProducerPosition({
        label: address,
        lat: "",
        lon: "",
      });
    }
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <div className="flex flex-column h-full pb-2 align-items-center">
        <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={["places"]}>
          <div
            className="display-flex flex-column justify-content-center align-items-center md:relative absolute md:pt-2 pt-0"
            style={{
              width: "100%",
              zIndex: "999",
              overflowX: "hidden",
            }}
          >
            <StandaloneSearchBox
              onPlacesChanged={onPlacesChanged}
              onLoad={onSBLoad}
            >
              <InputText
                placeholder={t("common:typeAddress")}
                value={address}
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
                className="search-bar-map"
              ></InputText>
            </StandaloneSearchBox>
          </div>
          <GoogleMap
            mapContainerClassName="map-position"
            options={{
              disableDefaultUI: true,
            }}
            center={{ lat: 45.505, lng: 7.6 }}
            zoom={7}
          />
        </LoadScript>
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={20}
          generalColor={primaryColor}
          enableNextButton={enableNextButton}
          setFinish={setPosition}
        />
      </div>
    </>
  );
};

export default MapTemplate;
