import { Image } from "primereact/image";
import deleteImage from "../../Assets/delete.png";
import { ImageInterface, ImageWithURL } from "../../../types/generic.types";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { deleteFile } from "../../../services/File/File";
import schedaProduttore from "../../Assets/Scheda_produttore.png";
export interface AmazonImagesInterface {
  amazonUrls: ImageWithURL[];
  setAmazonUrls: Dispatch<SetStateAction<ImageWithURL[]>>;
  producerImages?: ImageInterface[];
  setProducerImages?: Dispatch<SetStateAction<ImageInterface[]>>;
  numOfFiles: number;
  setNumOfFiles: Dispatch<SetStateAction<number>>;
  buttonText: string;
  setButtonText: Dispatch<SetStateAction<string>>;
  toastError: MutableRefObject<any>;
  saveOnDB?: any;
  modifyImage?: boolean;
}

const AmazonImages = ({
  amazonUrls,
  setAmazonUrls,
  producerImages,
  setProducerImages,
  numOfFiles,
  setNumOfFiles,
  saveOnDB,
  modifyImage,
}: AmazonImagesInterface) => {
  const onDeleteImages = async (amazonImage: ImageWithURL) => {
    deleteFile({ key: amazonImage.key })
      .then((res) => {
        if (res.status === 200) {
          /** ProducerImages servirà solo in fase di creazione */
          if (producerImages !== undefined && setProducerImages !== undefined) {
            setProducerImages(
              producerImages.filter(
                (image: any) => image.key !== amazonImage.key
              )
            );
          }
          setAmazonUrls(
            amazonUrls.filter((amazon: any) => amazon.key !== amazonImage.key)
          );
          const amazonUrlsTmp = amazonUrls.filter(
            (amazon: any) => amazon.key !== amazonImage.key
          );
          setNumOfFiles(numOfFiles - 1);
          /** saveOnDB servirà solo in fase di modifica delle immagini */
          if (saveOnDB) {
            const objectWithoutUrl = amazonUrlsTmp.map((image) => {
              return { key: image.key, filename: image.filename };
            });

            saveOnDB(objectWithoutUrl);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {amazonUrls.map((amazonImage: any, index: number) => (
        <div
          className="image-template-card display-flex justify-content-space-between align-items-center"
          key={index}
        >
          <Image
            src={amazonImage ? amazonImage.url : schedaProduttore}
            alt="immagine"
            preview
            className="dialog-images"
          />
          {modifyImage ? (
            <>
              {numOfFiles > 1 ? (
                <img
                  src={deleteImage}
                  alt="delete"
                  style={{ width: "2rem", height: "2rem" }}
                  onClick={() => onDeleteImages(amazonImage)}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <img
                src={deleteImage}
                alt="delete"
                style={{ width: "2rem", height: "2rem" }}
                onClick={() => onDeleteImages(amazonImage)}
              />
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default AmazonImages;
