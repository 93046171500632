import Footer from "../../Footer/Footer";
import { InputTextarea } from "primereact/inputtextarea";
import { secondColor } from "../../environment";
import { UseProductContext } from "../../Context/ProductContext";
import { useTranslation } from "react-i18next";

const DescriptionTemplate = () => {
  const { t } = useTranslation("common");
  const { productDescription, setProductDescription } = UseProductContext();

  return (
    <div className="flex flex-column h-full align-items-center justify-content-space-between">
      <div className="display-flex flex-direction-column justify-content-center h-full max-width-description-template">
        <span style={{ marginBottom: "1rem" }}>
          {t("common:typeDescription")}
        </span>
        <InputTextarea
          rows={5}
          cols={50}
          maxLength={150}
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
        />
        <span style={{ marginTop: "1rem" }}>
          {productDescription.length}/500
        </span>
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={60}
          generalColor={secondColor}
          enableNextButton={productDescription !== ""}
        ></Footer>
      </div>
    </div>
  );
};

export default DescriptionTemplate;
