export const EP_GET_ALL_PRODUCT_CATEGORIES = 
{
    path:"productCategory/getAllProductCategories",
    method:"GET"
};

export const EP_CREATE_PRODUCT_CATEGORY = 
{
    path:"productCategory/createProductCategory",
    method:"POST",
    requestBody: {
      "name": "abc"
    }
};

export const EP_DELETE_PRODUCT_CATEGORY = 
{
    path:"productCategory/deleteProductCategory",
    method:"DELETE",
    requestBody:{
        "_id": ""
      }
};

export const EP_UPDATE_PRODUCT_CATEGORY = 
{
    path:"productCategory/updateProductCategory",
    method:"PATCH",
    requestBody: {
        "name": "Jhon",
        "_id": ""
      }
};

export const EP_SEARCH_PRODUCT_CATEGORY = 
{
    path:"productCategory/searchProductCategory",
    method:"GET",
    params: {
        _id : "0"
    }
};
