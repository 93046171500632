import { useEffect, useRef, useState } from "react";
import schedaProduttore from "../Assets/Scheda_produttore.png";
import { Image } from "primereact/image";
import { Galleria } from "primereact/galleria";
import { useTranslation } from "react-i18next";
import "../General.css";
import { ProgressSpinner } from "primereact/progressspinner";
import { CompanyProfile, company } from "../../models/Company/company";
import { product } from "../../models/Product/product";
import {
  getGetUrlFile,
  getMultipleUrl,
  getMultipleProductsUrlFromCompany,
  getMultipleUrlFromCompanies,
} from "../../services/File/File";
import { searchSuppliersService } from "../../services/Supplier/SupplierService";
import { ImageWithURL } from "../../types/generic.types";
import LoadingPage from "../LoadingPage/LoadingPage";
import ProfileImagesInfo from "../ProfilePage/ProfileProducerPage/ProfileImagesInfo/ProfileImagesInfo";
import ProfileProductsNavigator from "../ProfilePage/ProfileProducerPage/ProfileProductsNavigator/ProfileProductsNavigator";
import ProfileSupplier from "../ProfilePage/ProfileProducerPage/ProfileSupplier/ProfileSupplier";
import { searchCompanyService } from "../../services/Company/CompanyServices";
import { useParams } from "react-router-dom";
import { showError } from "../Toast/Toast.functions";
import { Toast } from "primereact/toast";

export const responsiveOptions = [
  {
    breakpoint: "1024px",
    numVisible: 5,
  },
  {
    breakpoint: "768px",
    numVisible: 3,
  },
  {
    breakpoint: "560px",
    numVisible: 1,
  },
];

const ProfileProducerPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<product[]>([]);
  const [profile, setProfile] = useState<CompanyProfile | null>(null);
  const [producerImages, setProducerImages] = useState<ImageWithURL[]>([]);
  const [logoImage, setLogoImage] = useState<ImageWithURL>({
    key: "",
    filename: "",
    url: "",
  });
  const [newProductVisible, setNewProductVisible] = useState<boolean>(false);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [showSupplierList, setShowSupplierList] = useState<boolean>(false);
  const [visibleImage, setVisibleImage] = useState(true);
  const { t } = useTranslation("common");
  const { id } = useParams<{ id: string }>();
  const toast = useRef<Toast>(null);

  const companyParseResponse = (resData: company) => {
    getGetUrlFile(resData.logo.key).then((resLogo) => {
      getMultipleUrl(resData.pictures).then((images) => {
        setLogoImage({
          key: resData.logo.key,
          filename: resData.logo.filename,
          url: resLogo,
        });
        setProducerImages(images);
        setProfile({
          id: resData._id,
          name: resData.name,
          description: resData.description,
          producerPhone: resData.phone,
          producerEmail: resData.email,
          producerLogo: resData.logo,
          producerImages: resData.pictures,
          producerPosition: resData.address,
          producerWebSite: resData.website,
          producerVat: resData.vat,
          producerPayment: resData.payment,
          producerValues: resData.values,
          producerType: resData.companyType,
          producerUsers: resData.users,
          producerProducts: resData.products,
        });
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    searchCompanyService({ _id: id }).then((res: any) => {
      if (res.status === 200) {
        if (
          res.data.companyType === 1 ||
          res.data.companyType === 2 ||
          res.data.companyType === 3
        ) {
          setNewProductVisible(true);

          getMultipleProductsUrlFromCompany(res.data.products).then(
            (resProduct) => {
              setProducts(resProduct);
              companyParseResponse(res.data);
            }
          );
        } else if (res.data.companyType === 0) {
          setShowSupplierList(true);
          companyParseResponse(res.data);

          searchSuppliersService({
            input: id,
          }).then((data) => {
            getMultipleUrlFromCompanies(data.data.suppliers).then((res) => {
              setSupplierList(res);
              setLoading(false);
            });
          });
        }
      } else showError(toast, t("common:genericError"));
    });
  }, [id]);

  const itemTemplate = (item: any) => {
    return (
      <>
        {visibleImage ? (
          <Image
            preview
            src={item.url}
            onError={() => setVisibleImage(false)}
            alt={item.filename}
            className="galleria-image"
          />
        ) : (
          <ProgressSpinner style={{ width: "50px" }} />
        )}
      </>
    );
  };

  if (loading) return <LoadingPage />;
  else
    return (
      <div>
        {profile !== null ? (
          <>
            <div
              className="w-full flex justify-content-center"
              style={{ height: "300px" }}
            >
              <Image
                preview
                src={logoImage.url}
                onError={(e: any) => {
                  e.target.src = schedaProduttore;
                }}
                alt="logo produttore"
                imageStyle={{ height: "100%" }}
              ></Image>
            </div>
            <div className="info-name-description-container">
              <h1>{profile.name}</h1>
              <h3 style={{ maxWidth: "50%", textAlign: "center" }}>
                {profile.description}
              </h3>
            </div>
            {profile.producerValues && profile.producerValues !== " " ? (
              <div
                className="display-flex flex-direction-column justify-content-center align-items-center"
                style={{ width: "100vw", marginTop: "1rem" }}
              >
                <span className="medium-font" style={{ marginBottom: "1rem" }}>
                  {t("common:value")}
                </span>
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  <h3>{profile.producerValues}</h3>
                </div>
              </div>
            ) : (
              <></>
            )}
            {newProductVisible ? (
              <ProfileProductsNavigator
                company={profile}
                products={products}
                setProducts={setProducts}
                newProduct={false}
              />
            ) : showSupplierList ? (
              <ProfileSupplier newSupplier={false} suppliers={supplierList} />
            ) : (
              <></>
            )}

            <div
              className="card display-flex flex-direction-column justify-content-center align-items-center"
              style={{ width: "100vw", marginTop: "1rem" }}
            >
              <span className="medium-font" style={{ marginBottom: "1rem" }}>
                {t("common:photo")}
              </span>
              <Galleria
                value={producerImages}
                numVisible={5}
                responsiveOptions={responsiveOptions}
                style={{ maxWidth: "300px" }}
                showThumbnails={false}
                showIndicators
                changeItemOnIndicatorHover
                item={itemTemplate}
              />
            </div>
            <ProfileImagesInfo {...profile} />
          </>
        ) : (
          <></>
        )}
        <Toast ref={toast} />
      </div>
    );
};

export default ProfileProducerPage;
