import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { primaryColor } from "../environment";
import "./ForumRules.css";

const ForumRules = () => {
  const { t } = useTranslation(["forumRules"]);

  useEffect(() => {
    document.getElementById("top-page")?.scrollIntoView();
  }, []);

  return (
    <div className="flex flex-column align-items-center gap-6 pb-6">
      <div className="forum-rules-policy-page">
        <div
          id="top-page"
          className="forum-rules-policy-title"
          style={{
            color: primaryColor,
          }}
        >
          {t("forumRules:title")}
        </div>
        {/* Primo capitoletto */}
        <>
          <div
            className="forum-rules-policy-subtitle"
            style={{
              color: primaryColor,
            }}
          >
            1. {t("forumRules:titleFirstChapter")}
          </div>
          <div>
            <ul>
              <li>{t("forumRules:firstLiFirstChapter")}</li>
              <li>{t("forumRules:secondLiFirstChapter")}</li>
              <li>{t("forumRules:thirdLiFirstChapter")}</li>
              <li>{t("forumRules:fourthLiFirstChapter")}</li>
              <li>{t("forumRules:fifthLiFirstChapter")}</li>
              <li>{t("forumRules:sixthLiFirstChapter")}</li>
              <li>{t("forumRules:seventhLiFirstChapter")}</li>
            </ul>
            <div>{t("forumRules:secondDivFirstChapter")}</div>
            <ul>
              <li>{t("forumRules:firstLiSecondDivFirstChapter")}</li>
              <li>{t("forumRules:secondLiSecondDivFirstChapter")}</li>
              <li>{t("forumRules:thirdLiSecondDivFirstChapter")}</li>
            </ul>
            <div>{t("forumRules:conclusionFirstChapter")}</div>
          </div>
        </>
        {/* Secondo capitolo */}
        <>
          <div
            className="forum-rules-policy-subtitle"
            style={{
              color: primaryColor,
            }}
          >
            2. {t("forumRules:titleSecondChapter")}
          </div>
          <div>
            <div>{t("forumRules:firstSentenceSecondChapter")}</div>
          </div>
        </>
        {/* Terzo capitolo */}
        <>
          <div
            className="forum-rules-policy-subtitle"
            style={{
              color: primaryColor,
            }}
          >
            3. {t("forumRules:titleThirdChapter")}
          </div>
          <div>
            <div>{t("forumRules:firstSentenceThirdChapter")}</div>
            <div>{t("forumRules:secondSentenceThirdChapter")}</div>
            <ul>
              <li>{t("forumRules:firstLiThirdChapter")}</li>
              <li>{t("forumRules:secondLiThirdChapter")}</li>
              <li>{t("forumRules:thirdLiThirdChapter")}</li>
              <li>{t("forumRules:fourthLiThirdChapter")}</li>
            </ul>
          </div>
        </>
        {/* Quarto capitolo */}
        <>
          <div
            className="forum-rules-policy-subtitle"
            style={{
              color: primaryColor,
            }}
          >
            4. {t("forumRules:titleFourthChapter")}
          </div>
          <div>
            <div>{t("forumRules:firstSenteceFourthChapter")}</div>
            <div>{t("forumRules:secondSenteceFourthChapter")}</div>
            <div>{t("forumRules:thirdSenteceFourthChapter")}</div>
          </div>
        </>
        {/* Quinto capitolo */}
        <>
          <div
            className="forum-rules-policy-subtitle"
            style={{
              color: primaryColor,
            }}
          >
            5. {t("forumRules:titleFifthChapter")}
          </div>
          <div>
            <div>{t("forumRules:firstSentenceFifthChapter")}</div>
          </div>
        </>

        {/* Sesto capitolo */}
        <>
          <div
            className="forum-rules-policy-subtitle"
            style={{
              color: primaryColor,
            }}
          >
            6. {t("forumRules:titleSixthChapter")}
          </div>
          <div>
            <div>{t("forumRules:firstSentenceSixthChapter")}</div>
          </div>
        </>

        {/* Settimo capitolo */}
        <>
          <div
            className="forum-rules-policy-subtitle"
            style={{
              color: primaryColor,
            }}
          >
            7. {t("forumRules:titleSeventhChapter")}
          </div>
          <div>
            <div>{t("forumRules:firstSentenceSeventhChapter")}</div>
            <div>{t("forumRules:secondSentenceSeventhChapter")}</div>
            <div>{t("forumRules:thirdSentenceSeventhChapter")}</div>
            <div>{t("forumRules:fourthSentenceSeventhChapter")}</div>
            <div>{t("forumRules:fifthSentenceSeventhChapter")}</div>
          </div>
        </>

        {/* Ottavo capitolo */}
        <>
          <div
            className="forum-rules-policy-subtitle"
            style={{
              color: primaryColor,
            }}
          >
            8. {t("forumRules:titleEighthChapter")}
          </div>
          <div>
            <div>{t("forumRules:firstSentenceEighthChapter")}</div>
            <div>{t("forumRules:secondSentenceEighthChapter")}</div>
            <div>{t("forumRules:thirdSentenceEighthChapter")}</div>
          </div>
        </>
        {/* Ottavo capitolo */}
        <>
          <div
            className="forum-rules-policy-subtitle"
            style={{
              color: primaryColor,
            }}
          >
            9. {t("forumRules:titleNinthChapter")}
          </div>
          <div>
            <div>{t("forumRules:firstSentenceNinthChapter")}</div>
            <div>{t("forumRules:secondSentenceNinthChapter")}</div>
            <div>{t("forumRules:thirdSentenceNinthChapter")}</div>
            <div>{t("forumRules:fourthSentenceNinthChapter")}</div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ForumRules;
