import axios from "axios";
import { axiosWithAuth } from "../axiosWithAuth";
import { API_BASE_URL } from "../env";
import { CONFIRMATIONCODE, CONFIRMREGISTRATION, DELETEUSER, FORGOTPASSWORD, GETUSER, LOGIN, RESETPASSWORD } from "./api";

export const loginCognito = async (requestBody?: any) => {
  let server = API_BASE_URL + LOGIN.path;
  let body = requestBody ? requestBody : LOGIN.requestBody;
  return await axios(server, {
    method: LOGIN.method,
    data: { ...body },
  }).then((res) => {
    window.sessionStorage.setItem("accessToken", res.data.accessToken);
    window.sessionStorage.setItem("idToken", res.data.idToken);
    window.sessionStorage.setItem("refreshToken", res.data.refreshToken.token);
  });
};

export const getUserCognito = async (requestBody?: any) => {
  let server = API_BASE_URL + GETUSER.path;
  let body = requestBody ? requestBody : GETUSER.requestBody;
  return await axiosWithAuth(server, {
    method: GETUSER.method,
    data: { ...body },
  });
};

export const confirmRegistration = async (requestBody?: any) => {
  let server = API_BASE_URL + CONFIRMREGISTRATION.path;
  let body = requestBody ? requestBody : CONFIRMREGISTRATION.requestBody;
  return await axios(server, {
    method: CONFIRMREGISTRATION.method,
    data: { ...body },
  });
};

export const resendConfirmationCode = async (requestBody?: any) => {
  let server = API_BASE_URL + CONFIRMATIONCODE.path;
  let body = requestBody ? requestBody : CONFIRMATIONCODE.requestBody;
  return await axios(server, {
    method: CONFIRMATIONCODE.method,
    data: { ...body },
  });
};

export const forgotPassword = async (requestBody?: any) => {
  let server = API_BASE_URL + FORGOTPASSWORD.path;
  let body = requestBody ? requestBody : FORGOTPASSWORD.requestBody;
  return await axios(server, {
    method: FORGOTPASSWORD.method,
    data: { ...body },
  });
};

export const resetPassword = async (requestBody?: any) => {
  let server = API_BASE_URL + RESETPASSWORD.path;
  let body = requestBody ? requestBody : RESETPASSWORD.requestBody;
  return await axios(server, {
    method: RESETPASSWORD.method,
    data: { ...body },
  });
};

export const deleteUser = async (requestBody?: any) => {
  let server = API_BASE_URL + DELETEUSER.path;
  let body = requestBody ? requestBody : DELETEUSER.requestBody;
  return await axiosWithAuth(server, {
    method: DELETEUSER.method,
    data: { ...body },
  });
};
