import ProfileImagesInfo from "./ProfileImagesInfo/ProfileImagesInfo";
import ProfileProductsNavigator from "./ProfileProductsNavigator/ProfileProductsNavigator";
import { useEffect, useRef, useState } from "react";
import { company, CompanyProfile } from "../../../models/Company/company";
import { product } from "../../../models/Product/product";
import LoadingPage from "../../LoadingPage/LoadingPage";
import schedaProduttore from "../Assets/Scheda_produttore.png";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Galleria } from "primereact/galleria";
import { Toast } from "primereact/toast";
import DialogUploadLogo from "../../DialogUploadFoto/DialogUploadLogo";
import DialogUploadImages from "../../DialogUploadFoto/DialogUploadImages";
import { searchUserServiceId } from "../../../services/User/UsersServices";
import { ProgressSpinner } from "primereact/progressspinner";
import { searchSuppliersService } from "../../../services/Supplier/SupplierService";
import ProfileSupplier from "./ProfileSupplier/ProfileSupplier";
import {
  getGetUrlFile,
  getMultipleProductsUrlFromCompany,
  getMultipleUrl,
  getMultipleUrlFromCompanies,
} from "../../../services/File/File";
import { ImageWithURL } from "../../../types/generic.types";
import { useTranslation } from "react-i18next";
import "../../General.css";
import "./ProfileProducerPage.css";

export const responsiveOptions = [
  {
    breakpoint: "1024px",
    numVisible: 5,
  },
  {
    breakpoint: "768px",
    numVisible: 3,
  },
  {
    breakpoint: "560px",
    numVisible: 1,
  },
];

const ProfileProducerPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<product[]>([]);
  const [profile, setProfile] = useState<CompanyProfile | null>(null);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [dialogImagesVisible, setDialogImagesVisible] =
    useState<boolean>(false);
  const [dialogHeader, setDialogHeader] = useState<string>("");
  const toastError = useRef<any>(null);
  const [producerImages, setProducerImages] = useState<ImageWithURL[]>([]);
  const [logoImage, setLogoImage] = useState<ImageWithURL>({
    key: "",
    filename: "",
    url: "",
  });
  const [newProductVisible, setNewProductVisible] = useState<boolean>(false);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [newSupplierVisible, setNewSupplierVisible] = useState<boolean>(false);
  const [showSupplierList, setShowSupplierList] = useState<boolean>(false);
  const [visibleImage, setVisibleImage] = useState(true);
  const { t } = useTranslation("common");

  const companyParseResponse = (resData: company) => {
    getGetUrlFile(resData.logo.key).then((resLogo) => {
      getMultipleUrl(resData.pictures).then((images) => {
        setLogoImage({
          key: resData.logo.key,
          filename: resData.logo.filename,
          url: resLogo,
        });
        setProducerImages(images);
        setProfile({
          id: resData._id,
          name: resData.name,
          description: resData.description,
          producerPhone: resData.phone,
          producerEmail: resData.email,
          producerLogo: resData.logo,
          producerImages: resData.pictures,
          producerPosition: resData.address,
          producerWebSite: resData.website,
          producerVat: resData.vat,
          producerPayment: resData.payment,
          producerValues: resData.values,
          producerType: resData.companyType,
          producerUsers: resData.users,
          producerProducts: resData.products,
        });
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    searchUserServiceId({ _id: sessionStorage.getItem("userId") }).then(
      (res) => {
        if (res && res.data) {
          if (res.data.userType === 1 || res.data.userType === 2) {
            if (res.data.userType === 1) {
              setNewProductVisible(true);

              getMultipleProductsUrlFromCompany(
                res.data.companies[0].products
              ).then((resProduct) => {
                setProducts(resProduct);
                companyParseResponse(res.data.companies[0]);
              });
            }
            if (res.data.userType === 2) {
              setNewSupplierVisible(true);
              setShowSupplierList(true);
              companyParseResponse(res.data.companies[0]);
            }

            searchSuppliersService({
              input: sessionStorage.getItem("company"),
            }).then((data) => {
              getMultipleUrlFromCompanies(data.data.suppliers).then((res) => {
                setSupplierList(res);
                setLoading(false);
              });
            });
          }
        }
      }
    );
  }, []);

  const itemTemplate = (item: any) => {
    return (
      <>
        {visibleImage ? (
          <Image
            preview
            src={item ? item.url : schedaProduttore}
            onError={() => setVisibleImage(false)}
            alt={item ? item.filename : ""}
            className="galleria-image"
          />
        ) : (
          <ProgressSpinner style={{ width: "50px" }} />
        )}
      </>
    );
  };

  const modificaLogoHandleClick = () => {
    setDialogHeader(t("common:modifyLogo"));
    setDialogVisible(true);
  };

  const modificaFotoHandleClick = () => {
    setDialogHeader(t("common:modifyImages"));
    setDialogImagesVisible(true);
  };

  if (loading) return <LoadingPage />;
  else
    return (
      <div>
        <Toast ref={toastError} position="top-right" />
        {/**SEZIONE CON TUTTI I DIALOG  */}
        {logoImage.url === "" ? (
          <></>
        ) : (
          <DialogUploadLogo
            toast={toastError}
            header={dialogHeader}
            visible={dialogVisible}
            setVisible={setDialogVisible}
            logoImage={logoImage}
            setLogoImage={setLogoImage}
            companyProfile={profile}
          />
        )}
        {dialogImagesVisible ? (
          <DialogUploadImages
            toast={toastError}
            header={dialogHeader}
            visible={dialogImagesVisible}
            setVisible={setDialogImagesVisible}
            companyProfile={profile}
            amazonImages={producerImages}
            setAmazonImages={setProducerImages}
          />
        ) : (
          <></>
        )}
        {/** FINE SEZIONE CON TUTTI I DIALOG  */}
        {profile !== null ? (
          <>
            <div className="w-full flex justify-content-center height-logo-mobile">
              <Image
                preview
                src={logoImage.url}
                onError={(e: any) => {
                  e.target.src = schedaProduttore;
                }}
                alt="logo produttore"
                imageStyle={{ height: "100%" }}
              ></Image>
            </div>
            <div className="display-flex justify-content-center flex-direction-column align-items-center w-full">
              <Button
                className="small-item no-decoration general-button"
                style={{ marginTop: "1rem", color: "#fff" }}
                onClick={modificaLogoHandleClick}
              >
                {t("common:modifyLogo")}
              </Button>
            </div>
            <div className="info-name-description-container">
              <h1>{profile.name}</h1>
              <h3 style={{ maxWidth: "50%", textAlign: "center" }}>
                {profile.description}
              </h3>
            </div>
            {profile.producerValues && profile.producerValues !== " " ? (
              <div
                className="display-flex flex-direction-column justify-content-center align-items-center"
                style={{ width: "100vw", marginTop: "1rem" }}
              >
                <span className="medium-font" style={{ marginBottom: "1rem" }}>
                  {t("common:yourValues")}
                </span>
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  <h3>{profile.producerValues}</h3>
                </div>
              </div>
            ) : (
              <></>
            )}
            {newProductVisible ? (
              <ProfileProductsNavigator
                company={profile}
                products={products}
                setProducts={setProducts}
                newProduct={true}
              />
            ) : (
              <></>
            )}
            {showSupplierList ? (
              <ProfileSupplier
                newSupplier={newSupplierVisible}
                suppliers={supplierList}
              />
            ) : (
              <></>
            )}
            <div
              className="card display-flex flex-direction-column justify-content-center align-items-center"
              style={{ width: "100vw", marginTop: "1rem" }}
            >
              <span className="medium-font" style={{ marginBottom: "1rem" }}>
                {t("common:yourImages")}
              </span>
              <Galleria
                value={producerImages}
                numVisible={5}
                responsiveOptions={responsiveOptions}
                style={{ maxWidth: "300px" }}
                showThumbnails={false}
                showIndicators
                changeItemOnIndicatorHover
                item={itemTemplate}
              />
              <Button
                className="small-item no-decoration general-button"
                style={{ marginTop: "1rem", color: "#fff" }}
                onClick={modificaFotoHandleClick}
              >
                {t("common:modifyImages")}
              </Button>
            </div>
            <ProfileImagesInfo {...profile} />
          </>
        ) : (
          <></>
        )}
      </div>
    );
};

export default ProfileProducerPage;
