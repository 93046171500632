import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { iphone } from "../../types/Regex";
import producer from "../Assets/producer.jpeg";
import { primaryColor } from "../environment";
import "./SignUp.css";
import "../General.css";
import { Button } from "primereact/button";

const TypeCompany = () => {
  let navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const [showDialog, setShowDialog] = useState<boolean>(true);
  const signUpTypes = [
    {
      title: t("common:AgriculturalProducer"),
      image: producer,
      key: 1,
      url: "/producers",
      description: t("common:AgriculturalDescription"),
    },
    {
      title: t("common:Corporation"),
      image: producer,
      key: 2,
      url: "/corporation",
      description: t("common:CorporationDescription"),
    },
  ];

  return (
    <div
      className={`flex choose-type-company-page ${iphone ? "safari" : ""}`}
      style={{
        backgroundColor: primaryColor,
      }}
    >
      <Dialog
        header={t("common:welcome")}
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        className="dialog-signup-width"
      >
        <p>{t("common:bizalpInfo")}</p>
        <p>{t("common:bizalpJoin")}</p>
        <p>{t("common:listStart")}</p>
        <li>{t("common:list1")}</li>
        <li>{t("common:list2")}</li>
        <li>{t("common:list3")}</li>
        <li>{t("common:list4")}</li>
        <li>{t("common:list5")}</li>
        <li>{t("common:list6")}</li>
        <p>{t("common:registerWarning")}</p>
      </Dialog>
      <div style={{ position: "absolute", top: "1rem", left: "2rem" }}>
        <Button
          icon="pi pi-arrow-left"
          className="yellow-button"
          style={{ height: "56px" }}
          onClick={() => {
            window.open("/#/", "_self");
          }}
        ></Button>
      </div>
      <div className="signup-title" style={{ marginBottom: "1rem" }}>
        BIZalp
      </div>
      <div className="flex justify-content-center align-items-center flex-wrap">
        {signUpTypes.map((signUpType) => (
          <div
            key={signUpType.key}
            className="flex flex-column align-items-center signup-card"
          >
            <img src={signUpType.image} alt={signUpType.title} />
            <div className="signup-card-title">{signUpType.title}</div>
            <div className="signup-card-description">
              {signUpType.description}
            </div>
            <button
              className="general-button"
              style={{ fontSize: "15px", marginBottom: "2rem" }}
              onClick={() => navigate(signUpType.url)}
            >
              {t("common:signup")}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TypeCompany;
