import React, { useEffect, useRef, useState } from "react";
import { addComment } from "./NewComment";
import profile_blank from "../../Assets/profileblank.png";
import { deleteComment } from "../../../services/Comment/Comment";
import { showError, showSuccess } from "../../Toast/Toast.functions";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import {
  deleteCommentToPost,
  deleteSelfCommentToPost,
} from "../../../services/Post/Post";

const ShowAllComments = ({
  idPost,
  title,
  data,
  showCommentInPost,
  setShowCommentInPost,
  isSuperUser,
  setShowModal,
}: {
  idPost: string;
  title: string;
  data: any;
  showCommentInPost: boolean;
  setShowCommentInPost: any;
  isSuperUser: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showInput, setShowInput] = useState(false);
  const [comment, setComment] = useState("");
  const toast = useRef<Toast>(null);
  const { t } = useTranslation(["error", "common"]);

  const addNewComment = () => {
    addComment(
      comment,
      toast,
      idPost,
      setComment,
      setShowInput,
      setShowCommentInPost,
      showCommentInPost,
      t
    );
  };

  const removeComment = (comment: any) => {
    deleteCommentToPost({ _id: idPost, commentId: comment.id })
      .then((res: any) => {
        if (res.status === 200) {
          deleteComment({ _id: comment.id })
            .then((resComment: any) => {
              if (resComment.status === 200) {
                showSuccess(toast, t("common:deletedWithSuccess"));
                setShowCommentInPost(!showCommentInPost);
              }
            })
            .catch(() => {
              showError(toast, t("common:genericError"));
            });
        }
      })
      .catch(() => showError(toast, t("common:genericError")));
  };

  const removeSelfComment = (comment: any) => {
    deleteSelfCommentToPost({ _id: idPost, commentId: comment.id })
      .then((res: any) => {
        if (res.status === 200) {
          deleteComment({ _id: comment.id })
            .then((resComment: any) => {
              if (resComment.status === 200) {
                showSuccess(toast, t("common:deletedWithSuccess"));
                setShowCommentInPost(!showCommentInPost);
              }
            })
            .catch(() => {
              showError(toast, t("common:genericError"));
            });
        }
      })
      .catch(() => showError(toast, t("common:genericError")));
  };

  const footerTemplate = () => {
    return (
      <>
        {!showInput && !isSuperUser ? (
          <Button className="general-button" onClick={() => setShowInput(true)}>
            {t("common:comment")}
          </Button>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <Dialog
      header={title}
      visible={true}
      className="dialog-width"
      footer={footerTemplate}
      onHide={() => setShowModal(false)}
    >
      {data.map((comment: any) => (
        <div
          className="display-flex align-items-center"
          style={{ marginBottom: "1rem" }}
          key={comment.id}
        >
          <img
            src={comment.image.url ? comment.image.url : profile_blank}
            alt="profile_blank"
            style={{ width: "50px", marginRight: "1rem" }}
          />
          <div
            style={{
              borderRadius: "25px",
              height: "auto",
              padding: "1rem",
              backgroundColor: "rgba(0,0,0,0.2)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              {comment.name ? comment.name : t("common:deletedAccount")}
            </span>
            <span style={{ fontSize: "15px", whiteSpace: "pre-wrap" }}>
              {comment.text}
            </span>
          </div>
          {isSuperUser ||
          (comment.idCompany !== null &&
            comment.idCompany == window.sessionStorage.getItem("company")) ? (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!isSuperUser) removeSelfComment(comment);
                else removeComment(comment);
              }}
            >
              <i className="pi pi-ban ml-2"></i>
            </span>
          ) : (
            <></>
          )}
        </div>
      ))}

      {showInput && !isSuperUser ? (
        <div className="flex flex-column w-full align-items-flex-end gap-2">
          <InputTextarea
            style={{ width: "100%", borderRadius: "25px" }}
            placeholder={t("common:writeComment")}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <Button
            className="general-button ml-1 w-4"
            label={t("common:send")}
            onClick={addNewComment}
          ></Button>
        </div>
      ) : (
        <></>
      )}
      <Toast ref={toast} position="top-right" />
    </Dialog>
  );
};

export default ShowAllComments;
