export const GET_ALL_POSTS = {
  path: "Post/getAllPosts",
  method: "GET",
};

export const CREATE_POST = {
  path: "Post/createPost",
  method: "POST",
  requestBody: {
    idUser: "12345678",
    title: "Jhon",
    dateTime: "12/12/2022",
    description: "Jhon",
    images: [],
  },
};

export const DELETE_POST = {
  path: "Post/deletePost",
  method: "DELETE",
  requestBody: {
    _idPost: "id",
  },
};

export const DELETE_SELF_POST = {
  path: "Post/deleteSelfPost",
  method: "DELETE",
  requestBody: {
    _idPost: "id",
  },
};

export const UPDATE_POST = {
  path: "Post/updatePost",
  method: "PATCH",
  requestBody: {
    _id: "id",
    idUser: "Jhon",
    title: "Jhon",
    dateTime: "12/12/2022",
    description: "Jhon",
    images: [],
    comments: [],
  },
};

export const ADD_COMMENT_TO_POST = {
  path: "Post/addCommentToPost",
  method: "PATCH",
  requestBody: {
    _id: "id",
    idUser: "Jhon",
    comments: ["id"],
  },
};

export const DELETE_COMMENT_POST = {
  path: "Post/deleteCommentPost",
  method: "PATCH",
  requestBody: {
    _id: "id",
    commentId: "",
  },
};

export const DELETE_SELF_COMMENT_POST = {
  path: "Post/deleteSelfCommentPost",
  method: "PATCH",
  requestBody: {
    _id: "id",
    commentId: "",
  },
};

export const SEARCH_POST = {
  path: "Post/searchPost",
  method: "GET",
  requestBody: {
    _id: "id",
  },
};
