import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import "../General.css";
import "primeicons/primeicons.css";

import { Toast } from "primereact/toast";
import { createImagesURLs } from "../Functions/ImageFunctions";
import { getAllusersService } from "../../services/User/UsersServices";
import { Button } from "primereact/button";
import { CategoryAndProduct } from "./NewCategoryAndProduct";
import AdminDialog from "./AdminDialog/AdminDialog";
import PhotoDialog from "./AdminDialog/PhotoDialog";
import UserListDialog from "./AdminDialog/UserListDialog";
import { useNavigate } from "react-router-dom";
import XLSX from "sheetjs-style";
import { getAllCompaniesService } from "../../services/Company/CompanyServices";

const Admin = () => {
  const { t } = useTranslation(["common", "signin"]);
  const [visible, setVisible] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>();
  const [showAddAdmin, setShowAddAdmin] = useState<boolean>(false);
  const [showAddPhoto, setShowAddPhoto] = useState<boolean>(false);
  const [userList, setUserList] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const [numOfFiles, setNumOfFiles] = useState<number>(0);
  const [images, setImages] = useState<any[]>([]);
  const [imagesUrls, setImagesUrls] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const navigate = useNavigate();
  

  useEffect(() => {
    if (sessionStorage.getItem("superUser") == null) {
      navigate("/");
    }
    createImagesURLs(images, setImagesUrls);
    getAllusersService()
      .then((res: any) => {
        if (res.status === 200) {
          setAllUsers(res.data);
        }
      })
      .then((err) => console.log(err));
  }, [images]);

  let exportUsers = () => {
    getAllusersService()
      .then((res: any) => {
        if (res.status === 200) {
          let wscols = [
              {wch:100},
              {wch:100},
              {wch:100}
          ];
        
          let ws = XLSX.utils.json_to_sheet(res.data.map((row:any)=> {
            return {nome: row.name, cognome: row.surname, email: row.email}
          }));
          ws['!cols'] = wscols;

          let wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb,ws,"utenti");
          let excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"array"});
          console.log(excelBuffer);
          let data = new Blob([excelBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          actualDownload(data,"utentiBizalp.xlsx");
        }
      })
      .then((err) => console.log(err));
  }

  let exportCompanies = () => {
    getAllCompaniesService()
      .then((res: any) => {
        if (res.status === 200) {
          let wscols = [
              {wch:100},
              {wch:100},
              {wch:100},
              {wch:100},
              {wch:100},
              {wch:100},
              {wch:100}
          ];
        
          let ws = XLSX.utils.json_to_sheet(res.data.map((row:any)=> {
            return {ragioneSociale: row.name, pIva: row.vat, tipologia: row.companyType == 0 ? "ENTE/IMPRESA": "PRODUTTORE", indirizzo: row.address.label, email: row.email, telefono: row.phone, website: row.website }
          }));
          ws['!cols'] = wscols;

          let wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb,ws,"aziende");
          let excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"array"});
          console.log(excelBuffer);
          let data = new Blob([excelBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          actualDownload(data,"aziendeBizalp.xlsx");
        }
      })
      .then((err) => console.log(err));
  }

  let actualDownload = (data: any, exportName: string) => {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(data);
    link.download = exportName;
    link.target = '_blank';
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    window.URL.revokeObjectURL(link.href);
    link.remove();
  }

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <CategoryAndProduct />
      <div className="flex justify-content-space-between mt-3 mx-7">
        <Button
          className="general-button"
          onClick={() => setShowAddAdmin(true)}
        >
          {t("common:addAdmin")}
        </Button>
        <Button
          className="general-button"
          onClick={() => setShowAddPhoto(true)}
        >
          {t("common:productPhoto")}
        </Button>
        <Button className="general-button" onClick={() => setUserList(true)}>
          {t("common:userList")}
        </Button>
        
      </div>
      <div style={{textAlign: "center", marginBottom:"2em"}}>
        <p><b>Exports:</b></p>
        <i className="pi pi-users" aria-label="Export utenti" onClick={() => exportUsers()} style={{ textTransform:"uppercase", cursor: "pointer"}} >utenti</i>
        &nbsp;&nbsp;&nbsp;
        <i className="pi pi-building" aria-label="Export aziende" onClick={() => exportCompanies()} style={{ textTransform:"uppercase", cursor: "pointer"}} >aziende</i>
      </div>
      <AdminDialog
        visible={visible}
        setVisible={setVisible}
        verificationCode={verificationCode}
        setVerificationCode={setVerificationCode}
        showAddAdmin={showAddAdmin}
        setShowAddAdmin={setShowAddAdmin}
      />
      <PhotoDialog
        images={images}
        setImages={setImages}
        imagesUrls={imagesUrls}
        setImagesUrls={setImagesUrls}
        numOfFiles={numOfFiles}
        setNumOfFiles={setNumOfFiles}
        showAddPhoto={showAddPhoto}
        setShowAddPhoto={setShowAddPhoto}
        toast={toast}
      />
      <UserListDialog
        allUsers={allUsers}
        setAllUsers={setAllUsers}
        userList={userList}
        setUserList={setUserList}
      />
      <Toast ref={toast}></Toast>
    </>
  );
};

export default Admin;
