export const GET_ALL_PRODUCTS_IMAGE = {
  path: "productImages/getAllProductImage",
  method: "GET",
};

export const CREATE_PRODUCTS_IMAGE = {
  path: "productImages/createProductImage",
  method: "POST",
  requestBody: {
    url: " ",
    filename: " ",
    category: 0,
  },
};

export const DELETE_PRODUCTS_IMAGE = {
  path: "productImages/deleteProductImage",
  method: "DELETE",
  requestBody: {
    url: " ",
    filename: " ",
    category: 0,
    _id: "id",
  },
};
