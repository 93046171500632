import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteComment } from "../../../services/Comment/Comment";
import { getUrlFromComments } from "../../../services/File/File";
import {
  deleteCommentToPost,
  deleteSelfCommentToPost,
} from "../../../services/Post/Post";
import profile_blank from "../../Assets/profileblank.png";
import { showSuccess, showError } from "../../Toast/Toast.functions";
import ShowAllComments from "./ShowAllComments";

const Comments = ({
  data,
  showCommentInPost,
  isSuperUser,
  setShowCommentInPost,
}: {
  data: any;
  showCommentInPost: boolean;
  isSuperUser: boolean;
  setShowCommentInPost: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  let comments = data._idPost.comments;
  const [showComments, setShowComments] = useState<any[]>([]);
  const { t } = useTranslation(["common"]);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (comments.length > 0) {
      getUrlFromComments(comments).then((res) => {
        return setShowComments(res);
      });
    }
  }, [comments]);

  const removeComment = (comment: any) => {
    deleteCommentToPost({ _id: data._idPost.id, commentId: comment.id })
      .then((res: any) => {
        if (res.status === 200) {
          deleteComment({ _id: comment.id })
            .then((resComment: any) => {
              if (resComment.status === 200) {
                showSuccess(toast, t("common:deletedWithSuccess"));
                setShowCommentInPost(!showCommentInPost);
              }
            })
            .catch(() => {
              showError(toast, t("common:genericError"));
            });
        }
      })
      .catch(() => showError(toast, t("common:genericError")));
  };

  const removeSelfComment = (comment: any) => {
    deleteSelfCommentToPost({ _id: data._idPost.id, commentId: comment.id })
      .then((res: any) => {
        if (res.status === 200) {
          deleteComment({ _id: comment.id })
            .then((resComment: any) => {
              if (resComment.status === 200) {
                showSuccess(toast, t("common:deletedWithSuccess"));
                setShowCommentInPost(!showCommentInPost);
              }
            })
            .catch(() => {
              showError(toast, t("common:genericError"));
            });
        }
      })
      .catch(() => showError(toast, t("common:genericError")));
  };

  return (
    <div style={{ marginLeft: "2rem", marginTop: "2rem" }}>
      {showComments.length > 0 ? (
        showComments.map((comment: any, index) => {
          if (index < 2) {
            return (
              <div
                className="display-flex align-items-center mb-3"
                key={comment.id}
              >
                <img
                  src={comment.image.url ? comment.image.url : profile_blank}
                  alt="profile_blank"
                  style={{ width: "50px", marginRight: "1rem" }}
                />
                <div className="comment-style">
                  <div style={{ cursor: "pointer", fontWeight: "bold" }}>
                    {comment.name ? comment.name : t("common:deletedAccount")}
                  </div>
                  <div style={{ cursor: "pointer", whiteSpace: "pre-wrap" }}>
                    {comment.text}
                  </div>
                </div>
                {isSuperUser ||
                (comment.idCompany !== null &&
                  comment.idCompany ==
                    window.sessionStorage.getItem("company")) ? (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (!isSuperUser) removeSelfComment(comment);
                      else removeComment(comment);
                      if (index === 0) window.location.reload();
                    }}
                  >
                    <i className="pi pi-ban" style={{ marginLeft: "1em" }}></i>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          }
        })
      ) : (
        <></>
      )}

      {showComments.length > 2 ? (
        <div
          className="display-flex flex-direction-row align-items-center"
          style={{ marginTop: "1rem" }}
        >
          <div style={{ cursor: "pointer" }} onClick={() => setShowModal(true)}>
            {t("common:showAllComments")}
          </div>
        </div>
      ) : (
        <></>
      )}
      {showModal && showComments.length > 0 ? (
        <ShowAllComments
          idPost={data._idPost.id}
          title={data._idPost.title}
          data={showComments}
          showCommentInPost={showCommentInPost}
          setShowCommentInPost={setShowCommentInPost}
          setShowModal={setShowModal}
          isSuperUser={isSuperUser}
        />
      ) : (
        <></>
      )}
      <Toast ref={toast} />
    </div>
  );
};

export default Comments;
