import { useEffect, useRef, useState } from "react";
import { primaryColor } from "../environment";
import blank_profile from "../Assets/profileblank.png";
import { createPosts } from "../../services/Post/Post";
import { createBulletinBoards } from "../../services/BulletinBoard/BulletinBoard";
import { showError } from "../Toast/Toast.functions";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { searchCompanyService } from "../../services/Company/CompanyServices";
import { company } from "../../models/Company/company";
import {
  getGetUrlFile,
  getUrlAndUploadFileMultiple,
} from "../../services/File/File";
import { ImageWithURL } from "../../types/generic.types";
import DialogFileForum from "./DialogFileForum/DialogFileForum";
import { useTranslation } from "react-i18next";
import "./NewPost.css";

const NewPost = ({
  addedPost,
  setAddedPost,
}: {
  addedPost: boolean;
  setAddedPost: any;
}) => {
  const [showNewPost, setShowNewPost] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const toast = useRef<Toast>(null);
  const [images, setImages] = useState<any[]>([]);
  const [imagesURL, setImagesURL] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [company, setCompany] = useState<{
    name: string;
    image: ImageWithURL;
  }>();
  const { t } = useTranslation(["common", "error"]);

  const publishPost = async () => {
    if (title == null || title === "")
      return showError(toast, t("error:titleEmpty"));
    if (description == null || description === "")
      return showError(toast, t("error:descriptionEmpty"));
    if (
      sessionStorage.getItem("userId") == null ||
      sessionStorage.getItem("company") == null
    )
      return showError(toast, t("error:sessionExpired"));

    const amazon = await getUrlAndUploadFileMultiple(images);

    const requestBody = {
      idCompany: sessionStorage.getItem("company"),
      title: title,
      dateTime: new Date(),
      description: description,
      images: amazon,
    };

    createPosts(requestBody)
      .then((res: any) => {
        if (res.status === 201) {
          createBulletinBoards({ _idPost: res.data._id }).then((res: any) => {
            if (res.status === 201) {
              setTitle("");
              setDescription("");
              setAddedPost(!addedPost);
            }
          });
        } else showError(toast, t("error:somethingWrong"));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    searchCompanyService({ _id: window.sessionStorage.getItem("company") })
      .then((res: any) => {
        if (res.status === 200) {
          const companyResponse: company = res.data;
          getGetUrlFile(companyResponse.logo.key).then((result) => {
            setCompany({
              name: companyResponse.name,
              image: {
                key: companyResponse.logo.key,
                url: result,
                filename: companyResponse.logo.filename,
              },
            });
          });
        } else showError(toast, "genericError");
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="new-post-card">
      <div className="wrapper-button-new-post">
        <span>{t("common:newPost")}</span>
        {/*I18N*/}
        <div
          className="button-new-post"
          style={{ backgroundColor: primaryColor }}
          onClick={() => setShowNewPost(!showNewPost)}
        >
          {showNewPost ? (
            <i className="pi pi-minus"></i>
          ) : (
            <i className="pi pi-plus"></i>
          )}
        </div>
      </div>

      {showNewPost ? (
        <>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <img
              src={company ? company.image.url : blank_profile}
              alt="immagine-vuota"
              style={{ width: "10%" }}
            ></img>
            <InputText
              value={title}
              placeholder={t("common:addTitle")}
              className="input-new-post"
              onChange={(e) => setTitle(e.target.value)}
            ></InputText>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <InputTextarea
              value={description}
              rows={5}
              className="input-new-post"
              placeholder={t("common:insertMessage")}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "90%",
              marginTop: "1rem",
            }}
          >
            <Button className="general-button" onClick={() => setVisible(true)}>
              {t("common:addPhoto")}
            </Button>
            <Button className="general-button" onClick={publishPost}>
              {t("common:pubblica")}
            </Button>
          </div>
          {visible ? (
            <DialogFileForum
              visible={visible}
              setVisible={setVisible}
              images={images}
              setImages={setImages}
              imagesURL={imagesURL}
              setImagesURL={setImagesURL}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <Toast ref={toast} position="top-right" />
    </div>
  );
};

export default NewPost;
