import { useTranslation } from "react-i18next";
import WhyBizalpImage from "../Assets/why_bizalp_filosofia.png";
import { primaryColor } from "../environment";

const WhyBizalp = () => {
  const { t } = useTranslation(["philosophy"]);

  return (
    <div className="display-flex flex-direction-row justify-content-space-around">
      <div className="w-30vw text-align-producers">
        <div
          className="big-font why-bizalp-title"
          style={{ color: primaryColor }}
        >
          {t("philosophy:whyBizalpTitle")}
        </div>
        <div
          className="mobile-padding small-font"
          style={{ marginTop: "1rem", fontWeight: "400" }}
        >
          {t("philosophy:wbSentencePhilosophy")}
        </div>
      </div>
      <div className="w-30vw display-flex flex-direction-column align-items-center image-producers-position">
        <img
          src={WhyBizalpImage}
          alt="why BIZalp"
          className="sell-veg-image-size"
        ></img>
      </div>
    </div>
  );
};

export default WhyBizalp;
