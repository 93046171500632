import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { company } from "../../../../../models/Company/company";
import { searchCompanyService } from "../../../../../services/Company/CompanyServices";
import { createNotify } from "../../../../../services/Notify/Notify";
import { getProductsByProductType } from "../../../../../services/Product/ProductServices";
import { showError } from "../../../../Toast/Toast.functions";
import { ProductListType } from "../../ProductsList/ProductsList";
import ProductCardImagesNavigator from "../ProductCardCarousel/ProductCardCarousel";
import { ButtonProducer } from "./ButtonProducer";

interface DialogBuyProps {
  showBuyDialog: boolean;
  setShowBuyDialog: Dispatch<SetStateAction<boolean>>;
  product: ProductListType;
}

export interface CompanyData {
  id: string;
  name: string;
  email: string;
}

export const DialogBuyProduct = (props: DialogBuyProps) => {
  const toast = useRef(null);
  const [text, setText] = useState<string>("");
  const [companies, setCompanies] = useState<CompanyData[]>([]);
  const [choosedCompanies, setChoosedCompanies] = useState<CompanyData[]>([]);
  const { t } = useTranslation(["common", "error"]);

  useEffect(() => {
    getProductsByProductType(props.product.productType).then((res) => {
      setCompanies(
        res.data
          .map((product: any) => {
            return product.company.map((company: any) => {
              return {
                id: company._id,
                name: company.name,
                email: company.email,
              };
            });
          })
          .filter(
            (value: CompanyData, index: any, self: CompanyData[]) =>
              index === self.findIndex((t) => t.id === value.id)
          )[0]
      );
    });
  }, [props.product.productType]);

  const sendRequest = () => {
    if (choosedCompanies.length === 0)
      return showError(toast, t("common:noSuppliers"));
    if (text.trim() === "") return showError(toast, t("common:textEmpty"));

    const producers = {
      producer: choosedCompanies,
      request: text,
    };

    searchCompanyService({ _id: sessionStorage.getItem("company") }).then(
      (res: any) => {
        if (res.status === 200) {
          const company = res.data as company;

          const emailToCustomer = {
            suppliers: producers.producer,
            emailCustomer: company.email,
            customerName: company.name,
            customerPhoneNumber: company.phone,
            alreadySent: false,
            text: producers.request,
            type: 0,
          };
          const emailToSupplier = {
            suppliers: producers.producer,
            emailCustomer: company.email,
            customerName: company.name,
            customerPhoneNumber: company.phone,
            alreadySent: false,
            text: producers.request,
            type: 1,
          };
          const emailToAdministrator = {
            suppliers: producers.producer,
            emailCustomer: company.email,
            customerName: company.name,
            customerPhoneNumber: company.phone,
            alreadySent: false,
            text: producers.request,
            type: 2,
          };

          createNotify(emailToSupplier)
            .then((res: any) => {
              if (res.status === 201) {
                /** se l'email per i fornitori sono stare create allora creo l'email di conferma per il cliente */
                createNotify(emailToCustomer).then((res: any) => {
                  if (res.status === 201) {
                    createNotify(emailToAdministrator).then((res: any) => {
                      if (res.status === 201) {
                        props.setShowBuyDialog(false);
                      } else
                        return showError(
                          toast,
                          t("error:errorEmailNotSentToAdministrator")
                        );
                    });
                  } else return showError(toast, t("error:errorConfirmEmail"));
                });
              } else return showError(toast, t("error:errorEmailNotSent"));
            })
            .catch(() => showError(toast, t("error:errorEmailNotSent")));
        }
      }
    );
  };

  return (
    <Dialog
      visible={props.showBuyDialog}
      onHide={() => props.setShowBuyDialog(false)}
      className="dialog-buy-product"
    >
      <div style={{ width: "100%" }}>
        <ProductCardImagesNavigator images={props.product.images} />
      </div>
      {companies.length > 0 ? (
        <>
          <p className="small-font" style={{ textAlign: "center" }}>
            {t("common:chooseProducers")}
          </p>
          <div
            className="flex flex-row "
            style={{ overflowX: "auto", height: "auto", padding: "1rem" }}
          >
            {companies.map((company) => (
              <ButtonProducer
                id={company.id}
                name={company.name}
                key={company.id}
                email={company.email}
                choosedCompanies={choosedCompanies}
                setChoosedCompanies={setChoosedCompanies}
              />
            ))}
          </div>
        </>
      ) : (
        <></>
      )}

      <div>
        <p className="small-font" style={{ textAlign: "center" }}>
          {t("common:sentenceToProducers")}
        </p>
        <InputTextarea
          value={text}
          rows={10}
          style={{ width: "100%" }}
          onChange={(e) => setText(e.target.value)}
        ></InputTextarea>
      </div>
      <div
        className="flex flex-row justify-content-center"
        style={{ marginTop: "1rem" }}
      >
        <Button
          className="yellow-button"
          style={{ marginRight: "1rem" }}
          onClick={() => {
            sendRequest();
          }}
        >
          {t("common:send")}
        </Button>
        <Button
          className="general-button"
          onClick={() => props.setShowBuyDialog(false)}
        >
          {t("common:undo")}
        </Button>
      </div>
      <Toast ref={toast}></Toast>
    </Dialog>
  );
};
