import MountainCulture from "./MountainCulture";
import JoinsFamily from "./JoinsFamily";
import Producers from "./Producers";
import OurValues from "./OurValues";
import { useTranslation } from "react-i18next";
import "./Home.css";
import "../General.css";
import WhatIsBizalp from "./WhatIsBizalp";

const Home = () => {
  const { t } = useTranslation("common");

  return (
    <div className="flex flex-column gap-6">
      <div className="flex flex-column background-image">
        <div className="position-sentence-bizalp big-font">
          {t("imageTitleBizalp")}
        </div>
        <div className="position-sentences big-font">{t("imageTitle")}</div>
      </div>
      <WhatIsBizalp />
      <MountainCulture />
      <JoinsFamily />
      <Producers />
      <OurValues />
    </div>
  );
};

export default Home;
