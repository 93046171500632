import { axiosWithAuth, axiosWithUserId } from "../../axiosWithAuth";
import { API_BASE_URL } from "../../env";
import {
  EP_CREATE_PRODUCT_TYPE,
  EP_DELETE_PRODUCT_TYPE,
  EP_GET_ALL_PRODUCT_TYPES,
  EP_SEARCH_PRODUCT_TYPE,
  EP_UPDATE_PRODUCT_TYPE,
} from "./api";

export const getAllProductTypesService = async () => {
  let server = API_BASE_URL + EP_GET_ALL_PRODUCT_TYPES.path;
  return await axiosWithAuth(server, {
    method: EP_GET_ALL_PRODUCT_TYPES.method,
  });
};

export const createProductTypeService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_CREATE_PRODUCT_TYPE.path;
  let body = requestBody ? requestBody : EP_CREATE_PRODUCT_TYPE.requestBody;
  return await axiosWithUserId(server, {
    method: EP_CREATE_PRODUCT_TYPE.method,
    data: { ...body },
  });
};

export const deleteProductTypeService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_DELETE_PRODUCT_TYPE.path;
  let body = requestBody ? requestBody : EP_DELETE_PRODUCT_TYPE.requestBody;
  return await axiosWithUserId(server, {
    method: EP_DELETE_PRODUCT_TYPE.method,
    data: { ...body },
  });
};

export const updateProductTypeService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_UPDATE_PRODUCT_TYPE.path;
  let body = requestBody ? requestBody : EP_UPDATE_PRODUCT_TYPE.requestBody;
  return await axiosWithUserId(server, {
    method: EP_UPDATE_PRODUCT_TYPE.method,
    data: { ...body },
  });
};

export const searchProductTypeService = async (params?: any) => {
  let server = API_BASE_URL + EP_SEARCH_PRODUCT_TYPE.path;
  let queryParams = params ? params : EP_SEARCH_PRODUCT_TYPE.params;
  return await axiosWithAuth(server, {
    method: EP_SEARCH_PRODUCT_TYPE.method,
    params: queryParams,
  });
};
