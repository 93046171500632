import { InfoWindow, Marker } from "@react-google-maps/api";
import { primaryColor } from "../../../../environment";
import { useEffect, useState } from "react";
import { getGetUrlFile } from "../../../../../services/File/File";
import { company } from "../../../../../models/Company/company";
import { Button } from "primereact/button";
import { LatLng } from "leaflet";
import pin0 from "./pins/pin-0.png";
import pin1 from "./pins/pin-1.png";
import { useTranslation } from "react-i18next";
import scheda from "../../../../Assets/Scheda_produttore.png";
import { Image } from "primereact/image";

export const MarkerCompany = ({ company }: { company: company }) => {
  const [logo, setLogo] = useState();
  const [info, setInfo] = useState<boolean>(false);
  const { t } = useTranslation("common");

  useEffect(() => {
    getGetUrlFile(company.logo.key).then((image) => setLogo(image));
  }, [company.logo.key]);

  const location: LatLng = new LatLng(
    Number(company.address.lat),
    Number(company.address.lon)
  );

  return (
    <>
      <Marker
        position={location}
        onClick={() => setInfo(!info)}
        icon={company.companyType === 0 ? pin0 : pin1}
      >
        {info && (
          <InfoWindow position={location}>
            <div className="flex flex-column popup-style">
              <div className="image-size flex justify-content-center align-items-center">
                <Image
                  className="flex justify-content-center"
                  preview
                  src={logo}
                  alt="logo"
                  onError={(e: any) => (e.target.src = scheda)}
                  imageStyle={{ width: "50%", height: "100%" }}
                ></Image>
              </div>
              <div>
                <div style={{ marginLeft: "5px" }}>
                  <div
                    className="medium-font my-2"
                    style={{ color: primaryColor }}
                  >
                    {company.name}
                  </div>
                  <div style={{ fontWeight: 800 }}>{company.address.label}</div>
                  <div
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      overflowY: "auto",
                      height: "10vh",
                    }}
                  >
                    {company.description}
                  </div>
                </div>
                <div
                  className="flex justify-content-center"
                  style={{ marginBottom: "1rem" }}
                >
                  <Button
                    className="general-button"
                    onClick={() =>
                      window.open(`/#/producerProfile/${company._id}`, "_self")
                    }
                  >
                    {t("common:gotoProfile")}
                  </Button>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </>
  );
};
