import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { product } from "../../../../../models/Product/product";
import { getAllProductCategoriesService } from "../../../../../services/Product/ProductCategory/ProductServices";
import {
  searchProductService,
  updateProductService,
} from "../../../../../services/Product/ProductServices";
import { getAllProductTypesService } from "../../../../../services/Product/ProductType/ProductServices";
import { ImageWithURL } from "../../../../../types/generic.types";
import { showError } from "../../../../Toast/Toast.functions";
import { ProductListType } from "../../ProductsList/ProductsList";
import ProductCardImagesNavigator from "../ProductCardCarousel/ProductCardCarousel";
import DialogChangePhoto from "./DialogChangePhoto";
import "./DialogModifyProduct.css";

interface ProductCategoryInterface {
  name: string;
  id: string;
  _id: string;
}

interface ProductTypeInterface {
  name: string;
  id: string;
  _id: string;
}

const DialogModifyProduct = ({
  showModifyDialog,
  setShowModifyDialog,
  product,
}: {
  showModifyDialog: boolean;
  setShowModifyDialog: Dispatch<SetStateAction<boolean>>;
  product: ProductListType;
}) => {
  const { t } = useTranslation("common");
  const toast = useRef<Toast>(null);

  /*------------ Variabili per modifica categoria ------------ */
  const [categoryModified, setCategoryModified] =
    useState<ProductCategoryInterface>();
  const [categoryOptions, setCategoryOptions] = useState<
    ProductCategoryInterface[] | undefined
  >();

  /*------------ Variabili per modifica tipo di prodotto ------------ */
  const [typeModified, setTypeModified] = useState<ProductTypeInterface>();
  const [typeOptions, setTypeOptions] = useState<
    ProductTypeInterface[] | undefined
  >();

  /*------------ Variabile per modifica descrizione ------------ */
  const [descriptionModified, setDescriptionModified] = useState<string>(
    product.productDescription
  );

  /*------------ Variabile per modifica link ------------ */
  const [linkModified, setLinkModified] = useState<string | undefined>(
    product.link
  );

  /*------------ Variabile per modifica immagini ------------ */

  const [showDialogChangePhoto, setShowDialogChangePhoto] =
    useState<boolean>(false);
  const [imagesChoosed, setImagesChoosed] = useState<ImageWithURL[]>(
    product.images
  );

  useEffect(() => {
    getAllProductCategoriesService().then((resProductCategory: any) => {
      if (resProductCategory.status === 200 && resProductCategory.data !== "") {
        setCategoryOptions(resProductCategory.data);

        resProductCategory.data.forEach(
          (category: ProductCategoryInterface) => {
            if (category.name === product.productCategory)
              setCategoryModified(category);
          }
        );

        getAllProductTypesService().then((resProductType: any) => {
          if (resProductType.status === 200 && resProductType.data !== "") {
            setTypeOptions(resProductType.data);

            resProductType.data.forEach((productType: ProductTypeInterface) => {
              if (productType.name === product.productType)
                setTypeModified(productType);
            });
          }
        });
      } else showError(toast, t("common:genericError"));
    });
  }, [product.productCategory, product.productType]);

  const modifyProduct = () => {
    if (!categoryModified) return;
    if (!typeModified) return;

    searchProductService({ _id: product.id }).then((res: any) => {
      if (res.data.length > 0 && res.status === 200) {
        const productFound: product = res.data[0];
        const productModified = {
          _id: productFound.id,
          allergens: productFound.allergens,
          available: productFound.available,
          category: categoryModified.id,
          description: descriptionModified,
          discount: productFound.discount,
          link: linkModified,
          name: typeModified.name,
          picture: imagesChoosed,
          price: productFound.price,
          type: typeModified.id,
        };

        updateProductService(productModified)
          .then((res: any) => {
            if (res.data === "" && res.status === 200) {
              setShowModifyDialog(false);
            } else showError(toast, t("common:genericError"));
          })
          .catch(() => showError(toast, t("common:genericError")));
      } else showError(toast, t("common:genericError"));
    });
  };

  return (
    <Dialog
      header={t("common:modifyProduct")}
      visible={showModifyDialog}
      onHide={() => setShowModifyDialog(false)}
      className="dialog-modify-product"
      footer={
        <Button className="general-button" onClick={() => modifyProduct()}>
          {t("common:confirm")}
        </Button>
      }
    >
      <div className="flex flex-column gap-3">
        <div className="flex flex-column align-items-center w-full gap-2">
          <ProductCardImagesNavigator images={product.images} />
          <Button
            className="general-button"
            onClick={() => {
              setShowDialogChangePhoto(true);
            }}
          >
            {t("common:modifyImages")}
          </Button>
        </div>
        <div className="flex flex-column gap-2">
          <label
            htmlFor="category-modified"
            className="product-modified-subtitle"
          >
            {t("common:category")}
          </label>
          <Dropdown
            optionLabel="name"
            value={categoryModified}
            options={categoryOptions}
            onChange={(e: any) => setCategoryModified(e.value)}
            panelClassName="modify-product-dropdown-panel"
          ></Dropdown>
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="type-modified" className="product-modified-subtitle">
            {t("common:type")}
          </label>
          <Dropdown
            panelClassName="modify-product-dropdown-panel"
            optionLabel="name"
            value={typeModified}
            options={typeOptions}
            onChange={(e: any) => setTypeModified(e.value)}
          ></Dropdown>
        </div>
        <div className="flex flex-column gap-2">
          <label
            htmlFor="description-modified"
            className="product-modified-subtitle"
          >
            {t("common:description")}
          </label>
          <InputTextarea
            id="description-modified"
            value={descriptionModified}
            onChange={(e: any) => setDescriptionModified(e.target.value)}
          ></InputTextarea>
          <label>{descriptionModified.length}/500</label>
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="link-modified" className="product-modified-subtitle">
            {"Link"}
          </label>
          <InputText
            id="link-modified"
            value={linkModified}
            onChange={(e: any) => setLinkModified(e.target.value)}
          ></InputText>
        </div>
        <Toast ref={toast}></Toast>
      </div>
      {showDialogChangePhoto ? (
        <DialogChangePhoto
          productImages={imagesChoosed}
          setProductImages={setImagesChoosed}
          setShowDialogChangePhoto={setShowDialogChangePhoto}
          showDialogChangePhoto={showDialogChangePhoto}
        />
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export default DialogModifyProduct;
