import "./Products.css";
import "../General.css";
import ProductsServicesMainContainer from "./ProductsServicesMainContainer";

const Products = () => {
  return (
    <div>
      <ProductsServicesMainContainer />
    </div>
  );
};

export default Products;
