import { AES } from "crypto-js";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { Dispatch, SetStateAction } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { confirmRegistration } from "../../../services/Cognito/CognitoServices";
import { createUserService } from "../../../services/User/UsersServices";
import { BASE_URL, SIGNUP_URL } from "../../environment";
import { showError } from "../../Toast/Toast.functions";

interface AdminDialogProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  verificationCode?: string;
  setVerificationCode: Dispatch<SetStateAction<any>>;
  showAddAdmin: boolean;
  setShowAddAdmin: Dispatch<SetStateAction<boolean>>;
}

const AdminDialog = ({
  visible,
  setVisible,
  verificationCode,
  setVerificationCode,
  showAddAdmin,
  setShowAddAdmin,
}: AdminDialogProps) => {
  const { t } = useTranslation(["common", "signin"]);
  const toast = useRef<Toast>(null);

  interface InitiaValues {
    [index: string]: string | null | boolean | number | undefined;
    name: string;
    surname: string;
    email: string;
    password: string;
    accept: boolean;
  }

  const passwordHeader = <h6>{`${t("signin:choosePassword")}`}</h6>;
  const passwordFooter = (
    <React.Fragment>
      <p className="mt-2">{`${t("signin:tip")}`}</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>{`${t("signin:lowercaseChar")}`}</li>
        <li>{`${t("signin:uppercaseChar")}`}</li>
        <li>{`${t("signin:numericChar")}`}</li>
        <li>{`${t("signin:specialChar")}`}</li>
        <li>{`${t("signin:minimumChar")}`}</li>
      </ul>
    </React.Fragment>
  );

  let errors;
  let specialCharRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  let numberRegex = /['/^\d+$/']/;
  let maiuscRegex = /[A-Z]/;

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      password: "",
      accept: false,
    } as InitiaValues,

    validate: (data: InitiaValues) => {
      errors = {} as Record<string, string>;

      if (!data.name) {
        errors.name = t("signin:nameError");
      }
      if (!data.surname) {
        errors.surname = t("signin:surnameError");
      }
      if (!data.email) {
        errors.email = t("signin:emailError");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = t("signin:emailError");
      }
      if (!data.password) {
        errors.password = t("signin:passwordError");
      } else if (
        !maiuscRegex.test(data.password) ||
        !specialCharRegex.test(data.password) ||
        !numberRegex.test(data.password) ||
        data.password.length < 8
      ) {
        errors.password = "Invalid Password.";
      }
      if (!data.accept) {
        errors.accept = "error";
      }
      return errors;
    },
    onSubmit() {},
  });

  const handleSubmit = (data: InitiaValues) => {
    const passwordEncrypted = AES.encrypt(
      JSON.stringify(data.password),
      "sha256"
    ).toString();

    const signUpObject = {
      name: data.name,
      surname: data.surname,
      email: data.email.toLowerCase(),
      password: data.password,
    };

    fetch(`${BASE_URL}${SIGNUP_URL}`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signUpObject),
    })
      .then(async (res) => {
        if (res.status === 201) {
          sessionStorage.setItem(
            "adminUser",
            data.name.concat(" ").concat(data.surname)
          );
          sessionStorage.setItem("nameAdmin", data.name);
          sessionStorage.setItem("surnameAdmin", data.surname);
          sessionStorage.setItem("emailAdmin", data.email.toLowerCase());
          sessionStorage.setItem("xce4iAdmin", passwordEncrypted);
          formik.resetForm();

          setVisible(true);
        } else {
          const error = await res.json();
          if (error.code === "UsernameExistsException")
            showError(toast, t("signin:usernameExists"));
          else showError(toast, t("signin:signUpError"));
        }
      })
      .catch(() => {
        showError(toast, t("signin:signUpError"));
      });
  };

  const confirmAdmin = () => {
    const verify = {
      email: sessionStorage.getItem("emailAdmin"),
      verifyCode: verificationCode,
    };
    confirmRegistration(verify)
      .then(() => {
        /** Chiamata per salvare lo user nel mongo */
        createUserService({
          name: sessionStorage.getItem("nameAdmin"),
          surname: sessionStorage.getItem("surnameAdmin"),
          email: sessionStorage.getItem("emailAdmin"),
          userType: 3,
          userImage: {
            url: "",
            filename: "",
          },
        })
          .then(() => {
            sessionStorage.removeItem("xce4iAdmin");

            toast.current?.show({
              severity: "success",
              summary: "Aggiungi admin",
              detail: "Operazione effettuata con successo",
              life: 3000,
            });

            setVisible(false);
          })
          .catch(() => showError(toast, t("common:createUserError")));
      })
      .catch(() =>
        showError(toast, "Non e stato possibile confermare la registrazione")
      );
  };

  const dialogFooterContent = (
    <>
      <Button
        name="confirmAdmin"
        className="mt-2 signup-button"
        label="Conferma codice"
        onClick={confirmAdmin}
      ></Button>
    </>
  );

  const isFormFieldValid = (name: string) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: string) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      <Dialog
        visible={showAddAdmin}
        onHide={() => setShowAddAdmin(false)}
        header={t("common:addAdmin")}
      >
        <div
          className="form-card"
          style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}
        >
          <div className="text-center medium-font margin-bottom"></div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(formik.values);
            }}
            className="p-fluid"
          >
            {/* Field Name */}
            <div className="field">
              <span className="p-float-label">
                <InputText
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("name"),
                    "p-inputtext-left input-text-signin": true,
                  })}
                />
                <label
                  htmlFor="name"
                  className={classNames({
                    "p-error": isFormFieldValid("name"),
                  })}
                >
                  {t("common:Name")}*
                </label>
              </span>
              {getFormErrorMessage("name")}
            </div>

            {/* Field Surname */}
            <div className="field">
              <span className="p-float-label">
                <InputText
                  id="surname"
                  name="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("surname"),
                    "p-inputtext-left input-text-signin": true,
                  })}
                />
                <label
                  htmlFor="surname"
                  className={classNames({
                    "p-error": isFormFieldValid("surname"),
                  })}
                >
                  {t("common:Surname")}*
                </label>
              </span>
              {getFormErrorMessage("surname")}
            </div>

            {/* Field Email */}
            <div className="field">
              <span className="p-float-label p-input-icon-right">
                <InputText
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("email"),
                    "p-inputtext-left input-text-signin": true,
                  })}
                />
                <label
                  htmlFor="email"
                  className={classNames({
                    "p-error": isFormFieldValid("email"),
                  })}
                >
                  {t("common:Email")}*
                </label>
              </span>
              {getFormErrorMessage("email")}
            </div>

            {/* Field Password */}
            <div className="field">
              <span className="p-float-label">
                <Password
                  aria-label="password"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("password"),
                    "p-inputtext-left input-text-signin": true,
                  })}
                  header={passwordHeader}
                  footer={passwordFooter}
                  toggleMask
                />
                <label
                  htmlFor="password"
                  className={classNames({
                    "p-error": isFormFieldValid("password"),
                  })}
                >
                  {t("common:Password")}*
                </label>
              </span>
              {getFormErrorMessage("password")}
            </div>

            <div
              className="flex justify-content-center"
              style={{ width: "100%" }}
            >
              <Button
                type="submit"
                className="mt-2 signup-button"
                label="Registra un nuovo utente"
              ></Button>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        header={t("common:codeInsert")}
        visible={visible}
        footer={dialogFooterContent}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <InputText
          id="codiceConferma"
          name="codiceConferma"
          value={verificationCode}
          onChange={(e) => {
            setVerificationCode(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </Dialog>
    </>
  );
};

export default AdminDialog;
