import { classNames } from "primereact/utils";
import Footer from "../../Footer/Footer";
import { UseProducerContext } from "../../Context/ProducersContext";
import { primaryColor } from "../../environment";
import "./CardTemplate.css";

type cardType = {
  value: string;
  label: string;
  type: number;
};
interface iProps {
  values: cardType[];
}

const CardTemplate = (props: iProps) => {
  const { producerType, setProducerType } = UseProducerContext();
  return (
    <div className="h-full overflow-y-auto flex flex-column align-items-center w-full">
      <div className="producer-card-position">
        {props.values.map((el, index) => (
          <div
            key={index}
            className={classNames({
              "producers-type-card": true,
              selected: producerType === el.type,
            })}
            onClick={() => setProducerType(el.type)}
          >
            {el.label}
          </div>
        ))}
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={10}
          generalColor={primaryColor}
          enableNextButton={producerType >= 0}
        />
      </div>
    </div>
  );
};

export default CardTemplate;
