import { axiosWithAuth, axiosWithUserId } from "./../axiosWithAuth";
import { API_BASE_URL } from "../env";
import {
  EP_CREATE_PRODUCT,
  EP_DELETE_PRODUCT,
  EP_GET_ALL_PRODUCTS,
  EP_GET_PRODUCTS_BY_NAME,
  EP_SEARCH_PRODUCT,
  EP_UPDATE_PRODUCT,
} from "./api";
import axios from "axios";

export const getAllproductsService = async () => {
  let server = API_BASE_URL + EP_GET_ALL_PRODUCTS.path;
  return await axios(server, {
    method: EP_GET_ALL_PRODUCTS.method,
  });
};

export const getProductsByProductType = async (categoryParam: string) => {
  let server = API_BASE_URL + EP_GET_PRODUCTS_BY_NAME.path + categoryParam;
  return await axiosWithAuth(server, {
    method: EP_GET_PRODUCTS_BY_NAME.method,
  });
};

export const createProductService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_CREATE_PRODUCT.path;
  let body = requestBody ? requestBody : EP_CREATE_PRODUCT.requestBody;
  return await axiosWithAuth(server, {
    method: EP_CREATE_PRODUCT.method,
    data: { ...body },
  });
};

export const deleteProductService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_DELETE_PRODUCT.path;
  let body = requestBody ? requestBody : EP_DELETE_PRODUCT.requestBody;
  return await axiosWithUserId(server, {
    method: EP_DELETE_PRODUCT.method,
    data: { ...body },
  });
};

export const updateProductService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_UPDATE_PRODUCT.path;
  let body = requestBody ? requestBody : EP_UPDATE_PRODUCT.requestBody;
  return await axiosWithUserId(server, {
    method: EP_UPDATE_PRODUCT.method,
    data: { ...body },
  });
};

export const searchProductService = async (params?: any) => {
  let server = API_BASE_URL + EP_SEARCH_PRODUCT.path;
  let queryParams = params ? params : EP_SEARCH_PRODUCT.params;
  return await axiosWithAuth(server, {
    method: EP_SEARCH_PRODUCT.method,
    params: queryParams,
  });
};
