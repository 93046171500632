import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { primaryColor } from "../environment";
import "../General.css";
import "./Home.css";

const WhatIsBizalp = () => {
  const { t } = useTranslation("common");

  return (
    <div className="flex flex-column justify-content-center mountain-culture-position w-full align-items-center">
      <div className="big-font">{t("whatIsBizalp")}</div>
      <div className="flex flex-column sentence-button-position w-full mt-3 lg:w-8">
        <div
          className="margin-bottom small-font mobile-padding"
          style={{ fontWeight: "400" }}
        >
          {t("whatIsBizalpSentence")}{" "}
          <Link
            to="/philosophy"
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: primaryColor,
            }}
          >
            {t("discoverHere")}
          </Link>
        </div>
        <div
          className="margin-bottom small-font mobile-padding"
          style={{ fontWeight: "400" }}
        >
          {t("wibFirstSentence")}
          <ul>
            <li>{t("wibSecondSentence")}</li>
            <li>{t("wibThirdSentence")}</li>
          </ul>
        </div>
        <div
          style={{ fontWeight: "400" }}
          className="margin-bottom small-font mobile-padding"
        >
          {t("wibLastSentence")}
        </div>
      </div>
    </div>
  );
};

export default WhatIsBizalp;
