import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dispatch, SetStateAction, useState } from "react";
import { secondColor } from "../../../../environment";
import { CompanyData } from "./DialogBuyProduct";
interface ButtonProducerInterface {
  id: string;
  name: string;
  email: string;
  choosedCompanies: CompanyData[];
  setChoosedCompanies: Dispatch<SetStateAction<CompanyData[]>>;
}

export const ButtonProducer = ({
  id,
  name,
  email,
  choosedCompanies,
  setChoosedCompanies,
}: ButtonProducerInterface) => {
  const [checked, setChecked] = useState<boolean>(false);

  const SetCheckedCompany = (value: boolean) => {
    setChecked(value);
    if (value) {
      choosedCompanies.push({ id: id, name: name, email: email });
    } else {
      setChoosedCompanies(
        choosedCompanies.filter((company) => company.id !== id)
      );
    }
  };

  return (
    <Button
      style={{
        backgroundColor: secondColor,
        marginRight: "1rem",
        width: "auto",
        minWidth: "max-content",
      }}
      className="yellow-button"
      onClick={() => SetCheckedCompany(!checked)}
      key={id}
    >
      <Checkbox
        inputId={id}
        className="checbok-buy-product"
        onChange={() => SetCheckedCompany(!checked)}
        checked={checked}
        style={{ borderRadius: "100%" }}
      />
      <span className="ml-2">{name}</span>
    </Button>
  );
};
