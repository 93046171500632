import { Link } from "react-router-dom";
import "../General.css";
import "./Home.css";
import sellVeg from "./Assets/sellVeg.png";
import { useTranslation } from "react-i18next";

const JoinsFamily = () => {
  const { t } = useTranslation("common");

  return (
    <div className="display-flex flex-direction-row justify-content-space-around joins-family-position">
      <div className="w-30vw display-flex flex-direction-column align-items-center">
        <img
          src={sellVeg}
          alt="contadini, verdura e frutta"
          className="sell-veg-image-size"
        ></img>
      </div>
      <div className="w-30vw display-flex flex-direction-column sentence-button-position">
        <div className="big-font title-join-family">{t("joinFamilyTitle")}</div>
        <div
          className="sentence-join-family margin-bottom small-font mobile-padding"
          style={{ fontWeight: "400" }}
        >
          {t("jfSentence")}
        </div>
        {sessionStorage.getItem("idToken") ? (
          <Link
            to="/"
            className="small-font no-decoration general-button join-family-button"
          >
            {t("joinFamily")}
          </Link>
        ) : (
          <Link
            to="/signup"
            className="small-font no-decoration general-button join-family-button"
          >
            {t("joinFamily")}
          </Link>
        )}
      </div>
    </div>
  );
};

export default JoinsFamily;
