import Footer from "../../Footer/Footer";
import { UseProducerContext } from "../../Context/ProducersContext";
import { InputTextarea } from "primereact/inputtextarea";
import { BASE_URL, primaryColor, SIGNUP_URL } from "../../environment";
import { Toast } from "primereact/toast";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createCompanyService } from "../../../services/Company/CompanyServices";
import { createUserService } from "../../../services/User/UsersServices";
import { showError } from "../../Toast/Toast.functions";
import { AES } from "crypto-js";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const DescriptionTemplate = () => {
  const {
    producerType,
    producerEmail,
    producerPassword,
    producerPhone,
    producerVAT,
    producerWebSite,
    producerName,
    producerDescription,
    producerPosition,
    producerImages,
    producerLogo,
    producerValues,
    setProducerValues,
  } = UseProducerContext();

  const [finish, setFinish] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const { t } = useTranslation(["common", "error", "signin"]);
  const [visible, setVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showLoginDialog, setShowLoginDialog] = useState<boolean>(false);

  if (finish) {
    const signUpObject = {
      name: producerName,
      surname: producerName,
      email: producerEmail,
      password: producerPassword,
    };

    fetch(`${BASE_URL}${SIGNUP_URL}`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signUpObject),
    })
      .then(async (res) => {
        if (res.status === 201) {
          const companyObject = {
            name: producerName,
            description: producerDescription,
            vat: producerVAT,
            address: producerPosition,
            logo: producerLogo,
            pictures: producerImages,
            email: producerEmail.toLowerCase(),
            phone: producerPhone,
            companyType: producerType,
            values: producerValues,
            website: producerWebSite,
            users: [],
          };
          /** chiamata per creare la company */
          createCompanyService(companyObject).then((resCompany: any) => {
            if (resCompany.status === 201) {
              const userObject = {
                name: companyObject.name,
                surname: companyObject.name,
                email: companyObject.email,
                userType: 1,
                companies: [resCompany.data.id],
                userImage: { key: "", filename: "" },
              };
              sessionStorage.setItem("company", resCompany.data.id);
              createUserService(userObject).then((resUser: any) => {
                if (resUser.status === 202) {
                  const passwordEncrypted = AES.encrypt(
                    JSON.stringify(producerPassword),
                    "sha256"
                  ).toString();
                  sessionStorage.setItem("email", producerEmail);
                  sessionStorage.setItem("xce4i", passwordEncrypted);
                  sessionStorage.setItem("userId", resUser.data);
                  setVisible(false);
                  window.location.href = "/#/signup/confirmRegistration";
                } else {
                  showError(toast, t("error:userNotCreated"));
                }
              });
            } else {
              showError(toast, t("error:companyNotCreated"));
            }
          });
        } else if (res.status === 409) {
          setShowLoginDialog(true);
        }
      })
      .catch(() => {
        showError(toast, t("signin:signUpError"));
      });
  }
  return (
    <>
      <Dialog
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        header={""}
        closable={false}
      >
        {t("common:creation")}
      </Dialog>
      {showLoginDialog ? (
        <Dialog
          visible={true}
          header={t("common:alreadyRegister")}
          footer={
            <>
              <Button
                className="general-button"
                onClick={() => navigate("/signin")}
              >
                Login
              </Button>
            </>
          }
          onHide={() => {
            setShowLoginDialog(false);
            setVisible(false);
            setFinish(false);
          }}
        >
          {t("common:goToLogin")}
        </Dialog>
      ) : (
        <></>
      )}
      <div className="flex flex-column h-full justify-content-center max-width-description-template">
        <InputTextarea
          rows={5}
          cols={50}
          maxLength={500}
          value={producerValues}
          onChange={(e) => setProducerValues(e.target.value)}
        />
        <span style={{ marginTop: "1rem" }}>{producerValues.length}/500</span>
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={90}
          generalColor={primaryColor}
          enableNextButton={producerValues !== ""}
          setFinish={setFinish}
          setVisible={setVisible}
        ></Footer>
      </div>
    </>
  );
};

export default DescriptionTemplate;
