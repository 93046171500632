import Footer from "../../Footer/Footer";
import { UseProducerContext } from "../../Context/ProducersContext";
import { InputTextarea } from "primereact/inputtextarea";
import { primaryColor } from "../../environment";
import { useTranslation } from "react-i18next";

const DescriptionTemplate = () => {
  const { producerDescription, setProducerDescription } = UseProducerContext();
  const { t } = useTranslation("common");

  return (
    <>
      <div className="flex flex-column h-full justify-content-center max-width-description-template">
        <span style={{ marginBottom: "1rem" }}>{t("common:description2")}</span>
        <InputTextarea
          rows={5}
          cols={50}
          maxLength={500}
          value={producerDescription}
          onChange={(e) => setProducerDescription(e.target.value)}
        />
        <span style={{ marginTop: "1rem" }}>
          {producerDescription.length}/500
        </span>
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={40}
          generalColor={primaryColor}
          enableNextButton={producerDescription !== ""}
        ></Footer>
      </div>
    </>
  );
};

export default DescriptionTemplate;
