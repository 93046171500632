import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageWithURL } from "../../../../../types/generic.types";
import { showError } from "../../../../Toast/Toast.functions";

const ImageCardCheckbox = (props: {
  filename: string;
  publicKey: string;
  url: string;
  choosed: boolean;
  productImages: ImageWithURL[];
  setProductImages: Dispatch<SetStateAction<ImageWithURL[]>>;
}) => {
  const [checked, setChecked] = useState<boolean>(props.choosed);
  const toast = useRef<Toast>(null);
  const { t } = useTranslation("error");

  const handleClickChecked = (e: CheckboxChangeParams) => {
    if (!e.checked && props.productImages.length === 1) {
      showError(toast, t("error:errorPhotoLessOne"));
    } else if (e.checked && props.productImages.length < 3) {
      setChecked(e.checked);
      let imgs = props.productImages;
      imgs.push({
        filename: props.filename,
        key: props.publicKey,
        url: props.url,
      });
      props.setProductImages(imgs);
    } else if (e.checked && props.productImages.length >= 3) {
      showError(toast, t("error:errorPhotoMaxThree"));
    } else if (!e.checked) {
      setChecked(e.checked);
      let imgs = props.productImages;
      let found = imgs.findIndex((img) => img && img.key === props.publicKey);
      if (found !== -1) {
        imgs.splice(found, 1);
        props.setProductImages(imgs);
      }
    }
  };

  return (
    <div
      className="flex justify-center"
      style={{
        margin: "2px",
        marginRight: "1rem",
        marginBottom: " 1rem",
        maxWidth: "8rem",
      }}
    >
      <div className="wrapper-image-checkbox">
        <Image
          src={props.url}
          alt={props.filename}
          key={props.publicKey}
          preview
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            width: "100%",
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            inputId="binary"
            checked={checked}
            onChange={handleClickChecked}
          />
        </div>
      </div>
      <Toast ref={toast}></Toast>
    </div>
  );
};

export default ImageCardCheckbox;
