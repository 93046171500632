import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { SelectItem } from "primereact/selectitem";
import { imageProps } from "../Product/ImageSelectionTemplate/ImageCard";
import { product_category } from "../../models/Product/product_category";
import { ImageWithURL } from "../../types/generic.types";
export interface contextProductInterface {
  productType: SelectItem;
  setProductType: Dispatch<SetStateAction<SelectItem>>;
  productDescription: string;
  setProductDescription: Dispatch<SetStateAction<string>>;
  productLink: string;
  setProductLink: Dispatch<SetStateAction<string>>;
  productCategory: product_category;
  setProductCategory: Dispatch<SetStateAction<product_category>>;
  images: ImageWithURL[];
  setImages: Dispatch<SetStateAction<ImageWithURL[]>>;
}

const ProductContext = createContext<any>({});

const ProductProvider = ({ children }: { children: JSX.Element }) => {
  const [productType, setProductType] = useState<SelectItem>({
    label: "",
    value: "",
  });
  const [productDescription, setProductDescription] = useState<string>("");
  const [productLink, setProductLink] = useState<string>("");
  const [productCategory, setProductCategory] = useState<product_category>({
    id: "",
    name: "",
  });
  const [images, setImages] = useState<imageProps[]>([]);

  return (
    <ProductContext.Provider
      value={{
        productCategory,
        setProductCategory,
        productDescription,
        setProductDescription,
        productLink,
        setProductLink,
        productType,
        setProductType,
        images,
        setImages,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
export const UseProductContext = () =>
  useContext(ProductContext) as contextProductInterface;
