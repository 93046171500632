import axios from "axios";
import { axiosWithAuth } from "../axiosWithAuth";
import { API_BASE_URL } from "../env";
import {
  EP_CREATE_COMPANY,
  EP_DELETE_COMPANY,
  EP_GET_ALL_COMPANIES,
  EP_SEARCH_COMPANY,
  EP_UPDATE_COMPANY,
} from "./api";

export const getAllCompaniesService = async () => {
  let server = API_BASE_URL + EP_GET_ALL_COMPANIES.path;
  return await axios(server, {
    method: EP_GET_ALL_COMPANIES.method,
  });
};

export const createCompanyService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_CREATE_COMPANY.path;
  let body = requestBody ? requestBody : EP_CREATE_COMPANY.requestBody;
  return await axiosWithAuth(server, {
    method: EP_CREATE_COMPANY.method,
    data: { ...body },
  }).catch((error) => console.log(error));
};

export const deleteCompanyService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_DELETE_COMPANY.path;
  let body = requestBody ? requestBody : EP_DELETE_COMPANY.requestBody;
  return await axiosWithAuth(server, {
    method: EP_DELETE_COMPANY.method,
    data: { ...body },
  });
};

export const updateCompanyService = async (requestBody?: any) => {
  let server = API_BASE_URL + EP_UPDATE_COMPANY.path;
  let body = requestBody ? requestBody : EP_UPDATE_COMPANY.requestBody;
  return await axiosWithAuth(server, {
    method: EP_UPDATE_COMPANY.method,
    data: { ...body },
  });
};

export const searchCompanyService = async (params?: any) => {
  let server = API_BASE_URL + EP_SEARCH_COMPANY.path;
  let queryParams = params ? params : EP_SEARCH_COMPANY.params;
  return await axiosWithAuth(server, {
    method: EP_SEARCH_COMPANY.method,
    params: queryParams,
  });
};
