import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { primaryColor } from "../environment";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  const { t } = useTranslation("privacy");

  useEffect(() => {
    document.getElementById("top-page")?.scrollIntoView();
  }, []);

  return (
    <div className="flex flex-column align-items-center gap-6 pb-6">
      <div className="privacy-policy-page">
        <div
          id="top-page"
          className="privacy-policy-title"
          style={{
            color: primaryColor,
          }}
        >
          {t("privacy:title")}
        </div>
        {/* Primo capitoletto */}
        <>
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            {t("privacy:titleFirstChapter")}
          </div>
          <br />
          <div>{t("privacy:firstSentenceFirstChapter")}</div>
          <br />
          <div>{t("privacy:secondSentenceFirstChapter")}</div>
          <div>{t("privacy:thirdSentenceFirstChapter")}</div>
        </>
        <ul>
          {/* lista1 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list1")}
            </div>
            <span>
              <div>{t("privacy:list1Chap1")}</div>
              <br />
              <ul>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list1sub1")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list1sub2")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list1sub3")}</div>
                </li>
              </ul>
            </span>
          </li>
          {/* lista2 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list2")}
            </div>
            <span>
              <div>{t("privacy:list2Chap1")}</div>
              <br />
              <ul>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list2sub1")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list2sub2")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list2sub3")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>
                    {t("privacy:list2sub4")}
                    <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
                      www.bizalp.org
                    </a>
                  </div>
                </li>
              </ul>
            </span>
          </li>
          {/* lista3 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list3")}
            </div>
            <span>
              <div>{t("privacy:list3Chap1")}</div>
              <br />
              <div>{t("privacy:list3Chap2")}</div>
              <br />
              <div>{t("privacy:list3Chap3")}</div>
              <br />
              <ul>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list3sub1")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list3sub2")}</div>
                </li>
              </ul>
              <div>{t("privacy:list3subBreak")}</div>
              <br />
              <ul>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list3sub3")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list3sub4")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list3sub5")}</div>
                </li>
              </ul>
            </span>
          </li>
          {/* lista4 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list4")}
            </div>
            <span>
              <div>
                {t("privacy:list4Chap1")}
                <a href="www.bizalp.org" style={{ textDecoration: "none" }}>
                  www.bizalp.org
                </a>
                {t("privacy:list4Chap2")}
              </div>
            </span>
          </li>
          {/* lista5 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list5")}
            </div>
            <span>
              <div>{t("privacy:list5Chap1")}</div>
              <br />
              <ul>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list5sub1")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list5sub2")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list5sub3")}</div>
                </li>
              </ul>
            </span>
          </li>
          {/* lista6 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list6")}
            </div>
            <span>
              <div>{t("privacy:list6Chap1")}</div>
              <br />
              <div>{t("privacy:list6Chap2")}</div>
              <br />
              <div>{t("privacy:list6Chap3")}</div>
              <br />
              <ul>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list6sub1")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list6sub2")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list6sub3")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list6sub4")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list6sub5")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list6sub6")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list6sub7")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list6sub8")}</div>
                </li>
              </ul>
              <div>{t("privacy:list6Chap4")}</div>
              <div>{t("privacy:list6Chap5")}</div>
              <br />
            </span>
          </li>
          {/* lista7 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list7")}
            </div>
            <span>
              <div>{t("privacy:list7Chap1")}</div>
            </span>
          </li>
          {/* lista8 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list8")}
            </div>
            <span>
              <div>{t("privacy:list8Chap1")}</div>
              <br />
              <div>{t("privacy:list8Chap2")}</div>
              <br />
              <div>{t("privacy:list8Chap3")}</div>
              <br />
              <ul>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list8sub1")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list8sub2")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list8sub3")}</div>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <div>{t("privacy:list8sub4")}</div>
                </li>
              </ul>
              <div>{t("privacy:list8Chap4")}</div>
              <br />
              <div>{t("privacy:list8Chap5")}</div>
              <br />
              <div>{t("privacy:list8Chap6")}</div>
              <br />
              <div>{t("privacy:list8Chap7")}</div>
              <br />
            </span>
          </li>
          {/* lista9 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list9")}
            </div>
            <span>
              <div>{t("privacy:list9Chap1")}</div>
              <div>{t("privacy:list9Chap2")}</div>
              <div>{t("privacy:list9Chap3")}</div>
            </span>
          </li>
          {/* lista10 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list10")}
            </div>
            <span>
              <div>{t("privacy:list10Chap1")}</div>
            </span>
          </li>
          {/* lista11 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list11")}
            </div>
            <span>
              <div>{t("privacy:list11Chap1")}</div>
            </span>
          </li>
          {/* lista12 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list12")}
            </div>
            <span>
              <div>{t("privacy:list12Chap1")}</div>
            </span>
          </li>
          {/* lista13 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list13")}
            </div>
            <span>
              <div>{t("privacy:list13Chap1")}</div>
              <br />
              <div>{t("privacy:list13Chap2")}</div>
              <br />
              <div>{t("privacy:list13Chap3")}</div>
              <br />
              <div>{t("privacy:list13Chap4")}</div>
              <br />
            </span>
          </li>
          {/* lista14 */}
          <li>
            <div
              className="privacy-policy-title"
              style={{
                color: primaryColor,
              }}
            >
              {t("privacy:list14")}
            </div>
            <span>
              <div>{t("privacy:list14Chap1")}</div>
              <br />
              <div>{t("privacy:list14Chap2")}</div>
              <br />
              <div>{t("privacy:list14Chap3")}</div>
              <br />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
