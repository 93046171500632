import axios, { AxiosRequestConfig } from "axios";

export const axiosWithAuth = (url: string, config: AxiosRequestConfig) => {
  let token = sessionStorage.getItem("accessToken");
  if (token !== null) {
    let bearerToken: string = "Bearer " + token;
    const headers = {
      accept: "*/*",
      Authorization: bearerToken,
      "Content-Type": "application/json",
    };
    config.headers = headers;
  } else {
    console.error("Errore, Bearer non trovato!");
  }
  return axios(url, config).catch((err) => {
    if (err && err.request.status === 403) {
      console.log("Token Scaduto!");
      sessionStorage.clear();
      window.open("/#/signin", "_self");
    }
    return { data: [] };
  });
};

export const axiosWithUserId = (url: string, config: AxiosRequestConfig) => {
  let token = sessionStorage.getItem("accessToken");
  let userId = sessionStorage.getItem("userId");

  if (token !== null && userId !== null) {
    let bearerToken: string = "Bearer " + token;
    const headers = {
      accept: "*/*",
      Authorization: bearerToken,
      userid: userId,
      "Content-Type": "application/json",
    };
    config.headers = headers;
  } else {
    console.error("Errore: Bearer o userId non trovati!");
  }

  return axios(url, config).catch((err) => {
    if (err && err.request.status === 403) {
      sessionStorage.clear();
      window.open("/#/signin", "_self");
    }
    return { data: [] };
  });
};
