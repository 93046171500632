import { useTranslation } from "react-i18next";
import { primaryColor } from "../environment";

export default function NotFound() {
  const { t } = useTranslation(["common"]);

  return (
    <div className="flex flex-column justify-content-center w-full align-items-center h-full gap-3">
      <div
        style={{
          color: primaryColor,
          fontSize: "35px",
          fontWeight: 700,
          marginBottom: "2rem",
        }}
      >
        404 - {t("common:notFound")}
      </div>
      <label style={{ fontSize: "18px", fontWeight: "bold" }}>
        {t("common:wrongFSAddress")}
      </label>
      <label style={{ fontSize: "18px", fontWeight: "bold" }}>
        {t("common:wrongSSAddress")}
      </label>
      <label style={{ fontSize: "18px" }}> {t("common:wrongTSAddress")}</label>
    </div>
  );
}
