import { primaryColor } from "../../environment";
import { Image } from "primereact/image";
import omino from "../../Assets/omino.png";
import freccia from "../../Assets/freccia.png";
import { UsePaginatorContext } from "../../Context/PagesContext";
import "./FirstPage.css";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

const FirstPage = () => {
  const { currentPage, setCurrentPage } = UsePaginatorContext();
  const { t } = useTranslation(["common", "error"]);

  return (
    <div
      style={{
        backgroundColor: primaryColor,
        width: "100vw",
        overflowY: "auto",
      }}
      className="flex flex-column h-full align-items-center"
    >
      <Image src={omino} alt="omino" className="omino"></Image>
      <div style={{ position: "absolute", top: "1rem", left: "2rem" }}>
        <Button
          icon="pi pi-arrow-left"
          className="yellow-button"
          style={{ height: "56px" }}
          onClick={() => {
            window.open("/#/signup", "_self");
          }}
        ></Button>
      </div>
      <div className="product-activity-service">
        {t("common:activity")}
        <div> {t("common:prodottooservizio")}</div>
      </div>
      <div className="display-flex justify-content-center flex-direction-column align-items-center">
        <div className="main-title">{t("common:producerPage")}</div>
        <div className="main-description">{t("common:producerDescr")}</div>
        <div onClick={() => setCurrentPage(currentPage + 1)}>
          <img src={freccia} alt="next page" className="next-page-style"></img>
        </div>
      </div>
      <div className="medium-font" style={{ color: "#fff" }}>
        BIZalp
      </div>
    </div>
  );
};

export default FirstPage;
