import { secondColor } from "../../environment";
import Footer from "../../Footer/Footer";
import GalleryNav from "./GalleryNav";

const ImageSelectionTemplate = () => {
  return (
    <div className="flex flex-column justify-content-space-between align-items-center w-11 h-full">
      <div className="h-full flex flex-column justify-content-center">
        <GalleryNav />
      </div>
      <div className="footer-position">
        <Footer
          progressbarValue={75}
          enableNextButton={true}
          generalColor={secondColor}
        ></Footer>
      </div>
    </div>
  );
};

export default ImageSelectionTemplate;
