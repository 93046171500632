import { Galleria } from "primereact/galleria";
import { useEffect, useState } from "react";
import { getGetUrlFile, getMultipleUrl } from "../../../services/File/File";
import { ImageInterface, ImageWithURL } from "../../../types/generic.types";
import { Image } from "primereact/image";
import blank_profile from "../../Assets/profileblank.png";
import "./Post.css";
import { Button } from "primereact/button";

interface PostDataInterface {
  title: string;
  name: string;
  image: ImageInterface;
  dateTime: Date;
  description: string;
  file: any[];
}

const PostData = ({
  title,
  name,
  image,
  dateTime,
  description,
  file,
}: PostDataInterface) => {
  const [logo, setLogo] = useState<ImageWithURL>();
  const [files, setFiles] = useState<any[]>();

  var isImage = (key: any) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(key);
  };

  const itemTemplate = (item: any) => {
    if (isImage(item.key))
      return (
        <Image
          src={item.url}
          alt={item.filename}
          preview
          className="galleria-image"
        />
      );
    else
      return (
        <Button
          icon="pi pi-download"
          label={item.filename}
          onClick={() => {
            window.open(item.url, "_blank");
          }}
          style={{ cursor: "pointer" }}
          className="galleria-image general-button"
        />
      );
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  let day =
    new Date(dateTime).getDate() < 10
      ? "0" + new Date(dateTime).getDate()
      : new Date(dateTime).getDate();

  let month =
    new Date(dateTime).getMonth() < 10
      ? "0" + (new Date(dateTime).getMonth() + 1)
      : new Date(dateTime).getMonth() + 1;

  let time = day + "/" + month + "/" + new Date(dateTime).getFullYear();

  /**prende l'ora del post */
  let hour =
    new Date(dateTime).getHours() < 10
      ? "0" + new Date(dateTime).getHours()
      : new Date(dateTime).getHours();

  /**prende i minuti del post */
  let minutes =
    new Date(dateTime).getMinutes() < 10
      ? "0" + new Date(dateTime).getMinutes()
      : new Date(dateTime).getMinutes();

  useEffect(() => {
    if (image) {
      getGetUrlFile(image.key).then((res) =>
        setLogo({ filename: image.filename, key: image.key, url: res })
      );
    }
    getMultipleUrl(file).then((res) => {
      setFiles(res);
    });
  }, [file, image]);

  return (
    <>
      <span className="post-card-title">{title}</span>
      <div
        className="display-flex align-items-center"
        style={{ marginTop: "1rem" }}
      >
        <img
          src={logo ? logo.url : blank_profile}
          alt="blank profile"
          style={{ width: "100px", marginRight: "1rem" }}
        />
        <div className="display-flex flex-direction-column wrapper-name-hour">
          <span className="name-surname">{name}</span>
          <span>
            {time
              .concat(" ")
              .concat("" + hour)
              .concat(":")
              .concat("" + minutes)}
          </span>
        </div>
      </div>
      <div
        style={{
          marginTop: "1rem",
          marginLeft: "1rem",
          whiteSpace: "pre-wrap",
        }}
      >
        {description}
      </div>
      <div className="pt-4">
        <Galleria
          value={files}
          item={itemTemplate}
          responsiveOptions={responsiveOptions}
          showIndicators
          showThumbnails={false}
          style={{ maxWidth: "300px" }}
        ></Galleria>
      </div>
    </>
  );
};

export default PostData;
