import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { company } from "../../../models/Company/company";
import { createComment } from "../../../services/Comment/Comment";
import { searchCompanyService } from "../../../services/Company/CompanyServices";
import { createNotify } from "../../../services/Notify/Notify";
import { addCommentToPost, searchPost } from "../../../services/Post/Post";
import { showError } from "../../Toast/Toast.functions";

interface ForumEmail {
  email: string;
  name: string;
}

const sendEmails = (
  toast: Toast,
  data: any,
  commentText: string,
  companyReporter: company,
  t: any
) => {
  const ownerPost: ForumEmail = {
    email: data.idCompany.email,
    name: data.idCompany.name,
  };

  const emails: ForumEmail[] = data.comments
    .map((comment: any) => {
      return {
        email: comment.idCompany.email,
        name: comment.idCompany.name,
      };
    })
    .filter(
      (value: ForumEmail, index: any, self: ForumEmail[]) =>
        index === self.findIndex((t) => t.email === value.email)
    )
    .filter((value: ForumEmail) => {
      return (
        ownerPost.email !== value.email && companyReporter.email !== value.email
      );
    });

  if (ownerPost.email !== companyReporter.email) {
    /**email al proprietario del post */
    const emailToOwner = {
      suppliers: [ownerPost],
      emailCustomer: companyReporter.email,
      customerName: companyReporter.name,
      customerPhoneNumber: "0",
      alreadySent: false,
      text: commentText,
      type: 4,
    };

    createNotify(emailToOwner)
      .then((res: any) => {
        if (res.status !== 201) {
          showError(toast, t("error:emailNotSentOwner"));
          /*I18N*/
        }
      })
      .catch(() => showError(toast, t("error:emailNotSentOwner")) /*I18N*/);
  }

  /** email a chi ha commentato il post */
  const emailToReporters = {
    suppliers: emails,
    emailCustomer: companyReporter.email,
    customerName: companyReporter.name,
    customerPhoneNumber: "0",
    alreadySent: false,
    text: commentText,
    type: 5,
  };
  createNotify(emailToReporters)
    .then((res: any) => {
      if (res.status !== 201) {
        showError(toast, t("error:emailNotSentReporter")); /*I18N*/
      }
    })
    .catch(() => showError(toast, t("error:emailNotSentOwner")) /*I18N*/);
};

export const addComment = (
  comment: string,
  toast: any,
  idPost: string,
  setComment: any,
  setShowDialog: any,
  setShowCommentInPost: any,
  showCommentInPost: boolean,
  t: TFunction<("error" | "common")[], undefined>
) => {
  const trim = comment.trim();
  if (trim === "" || trim == null)
    return showError(toast, t("error:emptyText")); /*I18N*/
  if (
    sessionStorage.getItem("userId") == null ||
    sessionStorage.getItem("company") == null
  )
    return showError(toast, t("error:sessionExpired")); /*I18N*/

  const body = {
    idCompany: sessionStorage.getItem("company"),
    comment: trim,
  };

  createComment(body).then((res: any) => {
    if (res.status === 201) {
      const requestBody = {
        _id: idPost,
        idCompany: sessionStorage.getItem("company"),
        comments: res.data.id,
      };
      addCommentToPost(requestBody).then((resAddCommentToPost: any) => {
        if (resAddCommentToPost.status === 200) {
          setComment("");
          setShowDialog(false);
          setShowCommentInPost(!showCommentInPost);
          searchPost(idPost).then((resSearchPost: any) => {
            if (resSearchPost.status === 200) {
              searchCompanyService({
                _id: sessionStorage.getItem("company"),
              }).then((searchCompanyService: any) => {
                if (searchCompanyService.status === 200) {
                  sendEmails(
                    toast,
                    resSearchPost.data,
                    trim,
                    searchCompanyService.data,
                    t
                  );
                }
              });
            }
          });
        }
      });
    } else return showError(toast, t("error:commentNotCreated")); /*I18N*/
  });
};

const NewComment = ({
  idPost,
  showCommentInPost,
  setShowCommentInPost,
}: {
  idPost: string;
  showCommentInPost: boolean;
  setShowCommentInPost: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const toast = useRef<Toast>(null);
  const { t } = useTranslation(["error", "common"]);

  const footerTemplate = () => {
    return (
      <div>
        <Button
          className="general-button"
          onClick={() =>
            addComment(
              comment,
              toast,
              idPost,
              setComment,
              setShowDialog,
              setShowCommentInPost,
              showCommentInPost,
              t
            )
          }
        >
          {t("common:comment")}
        </Button>
      </div>
    );
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <Button className="general-button" onClick={() => setShowDialog(true)}>
        {t("common:comment")}
      </Button>
      <Dialog
        header={t("common:commentPost")} /*I18N*/
        visible={showDialog}
        className="dialog-new-comment"
        footer={footerTemplate}
        onHide={() => setShowDialog(false)}
      >
        <InputTextarea
          className="text-area-new-comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Dialog>
      <Toast ref={toast} position="top-right" />
    </div>
  );
};

export default NewComment;
