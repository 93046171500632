import { t } from "i18next";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import {
  deleteBulletinBoards,
  deleteSelfBulletinBoards,
  searchBulletinBoards,
} from "../../../services/BulletinBoard/BulletinBoard";
import { deleteComment } from "../../../services/Comment/Comment";
import { deletePosts, deleteSelfPosts } from "../../../services/Post/Post";
import { showError, showSuccess } from "../../Toast/Toast.functions";
import { useTranslation } from "react-i18next";

const deletePost = (
  toast: any,
  idPost: string,
  setShowDialog: Dispatch<SetStateAction<boolean>>
) => {
  searchBulletinBoards({ _idPost: idPost }).then((res: any) => {
    if (res.status === 200) {
      deleteBulletinBoards({ _idPost: res.data.id }).then((resDelete: any) => {
        if (resDelete.status === 200) {
          deletePosts({ _id: idPost }).then((postDeleted: any) => {
            if (postDeleted.status === 200) {
              showSuccess(toast, t("common:deletedWithSuccess"));
              setShowDialog(false);
              window.location.reload();
            } else return showError(toast, t("common:genericError"));
          });
        } else return showError(toast, t("common:genericError"));
      });
    } else return showError(toast, t("common:genericError"));
  });
};

const deleteSelfPost = (
  toast: any,
  idPost: string,
  setShowDialog: Dispatch<SetStateAction<boolean>>
) => {
  searchBulletinBoards({ _idPost: idPost }).then((res: any) => {
    if (res.status === 200) {
      deleteSelfBulletinBoards({ _idPost: res.data.id }).then(
        (resDelete: any) => {
          if (resDelete.status === 200) {
            deleteSelfPosts({ _id: idPost }).then((postDeleted: any) => {
              if (postDeleted.status === 200) {
                showSuccess(toast, t("common:deletedWithSuccess"));
                setShowDialog(false);
                window.location.reload();
              } else return showError(toast, t("common:genericError"));
            });
          } else return showError(toast, t("common:genericError"));
        }
      );
    } else return showError(toast, t("common:genericError"));
  });
};

export const removeCommentFromPost = (
  toast: any,
  idPost: any,
  setShowDialog: Dispatch<SetStateAction<boolean>>,
  selfRemove: boolean
) => {
  if (sessionStorage.getItem("userId") == null)
    return showError(toast, t("error:sessionExpired"));
  if (idPost.comments.length > 0) {
    const deletedComments = idPost.comments.map((comment: any) => {
      return deleteComment({ _id: comment.id }).then((res: any) => {
        if (res.status === 200) return true;
        else return false;
      });
    });
    Promise.all(deletedComments).then((comments) => {
      if (comments.includes(false)) return console.log("error");
      else {
        if (selfRemove) {
          deleteSelfPost(toast, idPost._id, setShowDialog);
        } else return deletePost(toast, idPost._id, setShowDialog);
      }
    });
  } else {
    if (selfRemove) deleteSelfPost(toast, idPost._id, setShowDialog);
    else deletePost(toast, idPost._id, setShowDialog);
  }
};

const DeletePost = ({
  idPost,
  selfRemove,
}: {
  idPost: any;
  selfRemove: boolean;
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const { t } = useTranslation(["common", "error"]);

  const footerTemplate = () => {
    return (
      <div>
        <Button
          className="general-button"
          onClick={() =>
            removeCommentFromPost(toast, idPost, setShowDialog, selfRemove)
          }
        >
          {t("common:deleteForever")}
        </Button>
      </div>
    );
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <Button className="general-button" onClick={() => setShowDialog(true)}>
        {t("common:deletePost")}
      </Button>
      <Dialog
        header={t("common:confirmDelete")}
        visible={showDialog}
        className="dialog-new-comment"
        footer={footerTemplate}
        onHide={() => setShowDialog(false)}
      >
        <p>{t("common:confirmDelete")}</p>
      </Dialog>
      <Toast ref={toast} position="top-right" />
    </div>
  );
};

export default DeletePost;
