import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

export interface contextPageInterface {
  pageTitle: string;
  setPageTitle: Dispatch<SetStateAction<string>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const PaginatorContext = createContext<any>({});

const PaginatorProvider = ({ children }: { children: JSX.Element }) => {
  const [pageTitle, setPageTitle] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  // console.log({
  //   pageTitle,
  //   setPageTitle,
  //   currentPage,
  //   setCurrentPage,
  // });

  return (
    <PaginatorContext.Provider
      value={{
        pageTitle,
        setPageTitle,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </PaginatorContext.Provider>
  );
};

export default PaginatorProvider;
export const UsePaginatorContext = () =>
  useContext(PaginatorContext) as contextPageInterface;
