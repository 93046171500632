import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyProfile } from "../../../../models/Company/company";
import { product } from "../../../../models/Product/product";
import { primaryColor } from "../../../environment";
import ProductCard from "../../../ProductsPage/componentsProducts/ProductCard/ProductCard";
import { ProductListType } from "../../../ProductsPage/componentsProducts/ProductsList/ProductsList";
import DialogProducts from "../Dialog/DialogProducts";
import "./ProfileProductsNavigator.css";
interface IProps {
  products: product[];
  company: CompanyProfile;
  setProducts: Dispatch<SetStateAction<product[]>>;
  newProduct: boolean;
}

const ProfileProductsNavigator = (props: IProps) => {
  const [dialogProductsVisible, setDialogProductsVisible] = useState(false);
  const { t } = useTranslation("common");
  const productTemplate = (product: product) => {
    const productObject: ProductListType = {
      id: product.id,
      images: product.picture,
      productCategory: product.category.name,
      productDescription: product.description,
      productType: product.type.name,
    };
    return (
      <div className="product-item" style={{ marginBottom: "1rem" }}>
        <div className="product-item-content">
          <ProductCard product={productObject} />
        </div>
      </div>
    );
  };

  const responsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "960px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "768px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <>
      <div
        className="display-flex justify-content-center align-items-center flex-direction-column"
        style={{ backgroundColor: "#f1f1f0", paddingTop: "1rem" }}
      >
        {props.newProduct ? (
          <Button
            onClick={() => setDialogProductsVisible(true)}
            className="add-product-button"
          >
            <i
              style={{
                color: primaryColor,
              }}
              className="pi pi-plus pi-plus-style"
            ></i>
            {t("common:addProductProducer")}
          </Button>
        ) : (
          <></>
        )}

        <div className="carousel-demo">
          {props.products && props.products.length ? (
            <Carousel
              value={props.products}
              numVisible={props.products.length > 2 ? 3 : props.products.length}
              numScroll={1}
              itemTemplate={productTemplate}
              responsiveOptions={responsiveOptions}
            />
          ) : (
            <div className="empty-products">{t("common:emptyProducts")}</div>
          )}
        </div>
      </div>

      <DialogProducts
        header={""}
        company={props.company}
        visible={dialogProductsVisible}
        setVisible={setDialogProductsVisible}
        products={props.products}
        setProducts={props.setProducts}
      />
    </>
  );
};

export default ProfileProductsNavigator;
