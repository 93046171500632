import { classNames } from "primereact/utils";
import Footer from "../../Footer/Footer";
import { UseProductContext } from "../../Context/ProductContext";
import "./CardTemplate.css";
import { secondColor } from "../../environment";
import { useEffect, useState } from "react";
import { getAllProductCategoriesService } from "../../../services/Product/ProductCategory/ProductServices";
import { product_category } from "../../../models/Product/product_category";
import LoadingComponent from "../../LoadingPage/LoadingComponent";

type cardType = {
  value: string;
  label: string;
};

const CardTemplate = () => {
  const { productCategory, setProductCategory } = UseProductContext();
  const [productCategoriesData, setProductCategoriesData] = useState<
    cardType[]
  >([]);
  const parseData = (data: any) => {
    let catArr: cardType[] = [];
    if (data && data.data) {
      data.data.map((cat: product_category) =>
        catArr.push({ value: cat.id, label: cat.name })
      );
      setProductCategoriesData(catArr);
    }
  };
  useEffect(() => {
    getAllProductCategoriesService()
      .then((data) => {
        parseData(data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      {productCategoriesData.length ? (
        <div className=" flex flex-column justify-content-space-between h-full">
          <div className="wrapper-card-template">
            {productCategoriesData.map((el, x) => (
              <div
                key={x}
                className={classNames({
                  "add-product-cards": true,
                  selected: productCategory.id === el.value,
                })}
                onClick={() =>
                  setProductCategory({ id: el.value, name: el.label })
                }
              >
                {el.label}
              </div>
            ))}
          </div>

          <div className="footer-position">
            <Footer
              backHistory="/admin"
              progressbarValue={20}
              enableNextButton={productCategory.id !== ""}
              generalColor={secondColor}
            />
          </div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
};

export default CardTemplate;
