import { axiosWithAuth } from "../axiosWithAuth";
import { API_BASE_URL } from "../env";
import {
  EP_GET_AVAILABLE_SUPPLIERS,
  EP_GET__SUPPLIERS_BY_COMPANY,
  EP_UPDATE_COMPANY_SUPPLIERS
} from "./api";

export const searchSuppliersService = async (params?: any) => {
  let server = API_BASE_URL + EP_GET__SUPPLIERS_BY_COMPANY.path;
  let queryParams = params ? params : EP_GET__SUPPLIERS_BY_COMPANY.params;
  return await axiosWithAuth(server, {
    method: EP_GET__SUPPLIERS_BY_COMPANY.method,
    params: queryParams
  });
};

export const searchAvailableSuppliersService = async (params?: any) => {
  let server = API_BASE_URL + EP_GET_AVAILABLE_SUPPLIERS.path;
  return await axiosWithAuth(server, {
    method: EP_GET__SUPPLIERS_BY_COMPANY.method
  });
  
}

export const updateCompanySuppliers = async (body?: any, params?:any) => {
  let server = API_BASE_URL + EP_UPDATE_COMPANY_SUPPLIERS.path;
  let queryParams = params ? params : EP_UPDATE_COMPANY_SUPPLIERS.params;
  return await axiosWithAuth(server, {
    method: EP_UPDATE_COMPANY_SUPPLIERS.method,
    params: queryParams,
    data: body
  });
  
}

