import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";
import "./SupplierCard.css";
import ProductCardImagesNavigator from "../ProductCard/ProductCardCarousel/ProductCardCarousel";
import { useTranslation } from "react-i18next";
interface IProps {
  supplier: any;
}
const SupplierCard = (props: IProps) => {
  const toast = useRef<Toast>(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    const classBody: HTMLCollection =
      document.getElementsByClassName("p-card-body");
    if (classBody && classBody.length > 0)
      for (let x = 0; x < classBody.length; x++)
        classBody[x].className = "p-card-body-custom";
  }, []);

  return (
    <>
      <Toast ref={toast} position="top-right"></Toast>
      <Card
        title={props.supplier.name}
        className="supplier-card-style"
        header={<ProductCardImagesNavigator images={props.supplier.images} />}
      >
        <div className="display-flex flex-direction-column justify-content-center align-items-center">
          <div
            className="general-button"
            style={{ marginTop: "1rem" }}
            onClick={() =>
              window.open(
                `/#/producerProfile/${props.supplier?.companyId}`,
                "_self"
              )
            }
          >
            {t("common:goToSupplier")}
          </div>
        </div>
      </Card>
    </>
  );
};

export default SupplierCard;
