export const EP_GET_ALL_USERS = {
  path: "user/getAllUsers",
  method: "GET",
};

export const EP_CREATE_USER = {
  path: "user/createUser",
  method: "POST",
  requestBody: {
    name: "",
    surname: "",
    email: "",
    userType: 1,
    companies: [],
  },
};

export const EP_DELETE_USER = {
  path: "user/deleteUser",
  method: "DELETE",
  requestBody: {
    name: "",
    surname: "",
    email: "",
    userType: 1,
    companies: [],
    _id: "",
  },
};

export const EP_UPDATE_USER = {
  path: "user/updateUser",
  method: "PATCH",
  requestBody: {
    name: "",
    surname: "",
    email: "",
    userType: 1,
    companies: [],
    _id: "",
  },
};

export const EP_SEARCH_USER_EMAIL = {
  path: "user/searchUserEmail",
  method: "GET",
  params: {
    email: "",
  },
};

export const EP_SEARCH_USER_ID = {
  path: "user/searchUserId",
  method: "GET",
  params: {
    _id: "0",
  },
};
