import { ProgressBar } from "primereact/progressbar";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UsePaginatorContext } from "../Context/PagesContext";
import "./Footer.css";

const Footer = ({
  backHistory,
  progressbarValue,
  enableNextButton,
  generalColor,
  setFinish,
  nextPageText,
  setVisible,
}: {
  backHistory?: string;
  progressbarValue: number;
  enableNextButton?: boolean;
  generalColor: string;
  setFinish?: Dispatch<SetStateAction<boolean>>;
  nextPageText?: string;
  setVisible?: Dispatch<SetStateAction<boolean>>;
}) => {
  const { currentPage, setCurrentPage } = UsePaginatorContext();
  let nav = useNavigate();
  const { t } = useTranslation("common");

  return (
    <>
      <ProgressBar
        showValue={false}
        value={progressbarValue}
        color={generalColor}
        className="progressbar-size"
      ></ProgressBar>
      <div className="display-flex flex-direction-row align-items-center justify-content-space-between footer-div">
        <div
          className="back-button"
          onClick={() =>
            currentPage > 0
              ? setCurrentPage(currentPage - 1)
              : backHistory
              ? nav(backHistory)
              : nav("/")
          }
        >
          {t("common:back")}
        </div>

        <div
          className={
            enableNextButton ? "next-page-button" : "next-page-button-disabled"
          }
          style={{ backgroundColor: generalColor }}
          onClick={() => {
            if (setFinish !== undefined) {
              setFinish(true);
            } else {
              setCurrentPage(currentPage + 1);
            }
            if (setFinish !== undefined && setVisible !== undefined) {
              setFinish(true);
              setVisible(true);
            }
          }}
        >
          {nextPageText ? nextPageText : t("common:next2")}
        </div>
      </div>
    </>
  );
};

export default Footer;
