import { ProgressSpinner } from "primereact/progressspinner";

const LoadingComponent = ({ text }: { text?: string }) => {
  return (
    <div
      className="display-flex justify-content-space-evenly align-items-center"
      style={{ height: "100vh", flexDirection: "column" }}
    >
      <ProgressSpinner style={{ width: "100px", height: "100px" }} />
      <span className="medium-font">{text}</span>
    </div>
  );
};

export default LoadingComponent;
